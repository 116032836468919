import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { reactAppExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import AppLoader from "../../dataset/Loader/AppLoader";
import { Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import "./dynamicTable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterComponent from "../../common/filterComponent/FilterComponent";
import { RoutesContext } from "../../../RoutesContext";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { formatCurrency, formatNumber } from "../../../services/utils";

const DynamicTable = ({
  displayData,
  appSessionId,
  objData,
  reloadComponents,
  filterApply,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  let initialFilter = {
    FilterCriteria: [],
    SortCriteria: [],
    PaginationCriteria: {
      limit: 0,
      skip: 0,
    },
  };
  const abortController = new AbortController();
  const signal = abortController.signal;
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const gridRef = useRef();
  const [sysFilter, setSysFilter] = useState(initialFilter);
  const [componentData, setComponentData] = useState({});
  const [componentDataLoading, setComponentDataLoading] = useState(false);
  const [columnDefs, setColumnDefs] = useState([{}]);
  const [rowData, setRowData] = useState([]);
  const [toggleTableView, setToggleTableView] = useState(true);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [pinnedTopRows, setPinnedTopRows] = useState([]);
  const [pinnedBottomRows, setPinnedBottomRows] = useState([]);
  const [hideHeader, setHideHeader] = useState(false);
  const { component_id, isCacheEnabled, autoReloadComponent, autoReloadDelay } =
    displayData;
  const { flowId } = useParams();
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }, []);

  const overlayNoRowsTemplate =
    "<span class='no-data' >No data to display</span>";

  useEffect(() => {
    if (displayData?.showTotalRow && rowData?.length > 0) {
      const total = {};
      rowData?.forEach((obj) => {
        for (const key in obj) {
          if (!isNaN(obj[key])) {
            total[key] = Math.round((total[key] || 0) + parseFloat(obj[key]), 2);
          }
        }
      });
      total[columnDefs[0]?.field] = "Total";
      setPinnedBottomRows([total]);
    }
  }, [rowData]);

  let executeAppObject = {
    data: {
      logic:
        objData?.logic?.length > 0
          ? objData?.logic
          : [displayData?.componentLogic],
    },
    referenceValue: [{ refValue: displayData?.refValue }],
    app_session_id: appSessionId,
    sys_filter: sysFilter,
  };

  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      let { columnDefs, rowData, pinnedTopRows, pinnedBottomRows } =
        cachedData[component_id] || {};
      setColumnDefs(columnDefs);
      setRowData(rowData);
      setPinnedTopRows(pinnedTopRows || []);
      setPinnedBottomRows(pinnedBottomRows || []);
      return;
    }
    appExecutionEngine(executeAppObject);
    return () => {
      abortController.abort();
    };
  }, [flowId]);

  useEffect(() => {
    if (reloadComponents?.includes(displayData?.refValue)) {
      appExecutionEngine(executeAppObject);
    }
  }, [reloadComponents]);

  useEffect(() => {
    if (filterApply) {
      appExecutionEngine(executeAppObject);
    }
  }, [filterApply]);

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(
        () => appExecutionEngine(executeAppObject),
        10000
      );
      return () => clearInterval(intervalId);
    }
  }, []);

  const currencyFormatter = (params, page_field) => {
    if (page_field?.displayCurrencyFormat) {
      return formatCurrency(params.value, page_field?.displayCurrencyFormat)
    } else {
      return formatCellValue(params.value);
    }
  };

  const formatCellValue = (amount) => {
    if (amount && amount.length > 0 && amount.includes("[") && amount.includes("]") && amount.indexOf("[") > 0) {
      // Brackets[] are used to specify colspan values
      amount = amount.slice(0, amount.indexOf("["));
    }
    return formatNumber(amount);
  }

  const appExecutionEngine = (bodyObject) => {
    setComponentDataLoading(true);
    reactAppExecutionVP(bodyObject, signal)
      .then((response) => {
        if(response?.data.length === 0 || Object.keys(response?.data[0]).length === 0){
          setColumnDefs([]);
          setRowData([]);
          setComponentDataLoading(false);
          setPinnedTopRows([]);
          setPinnedBottomRows([]);
          return;
        }
        response?.data?.forEach((refData) => {
          if (refData[displayData?.refValue]) {
            let columns = refData[displayData?.refValue]?.value?.fields?.map(
              (field, index) => {
                const { columnFreezeLeft, columnFreezeRight, columnKey, page_field } =
                  displayData;
                return {
                  headerName: field?.display_name,
                  field: field?.name,
                  pinned: Number(columnFreezeLeft || "0") > index
                    ? "left"
                    : Number(columnFreezeRight || "-999") >= index
                      ? "right"
                      : false,
                  valueGetter: (params) => params?.data[field?.name],
                  tooltipValueGetter: (p) => {
                    return ((p.value && p.value.length > 1) ? p.value : null);
                  },
                  valueFormatter: (params) => {
                    if (page_field) {
                      return currencyFormatter(params, page_field[index]);
                    }
                  },
                  colSpan: (params) => {
                    const value = params.data[field?.name];
                    if (value && value.length > 0) {
                      if (value.includes("[1]")) {
                        return 1;
                      } else if (value.includes("[2]")) {
                        return 2;
                      } else if (value.includes("[3]")) {
                        return 3;
                      } else if (value.includes("[4]")) {
                        return 4;
                      } else if (value.includes("[5]")) {
                        return 5;
                      } else if (value.includes("[6]")) {
                        return 6;
                      } else if (value.includes("[7]")) {
                        return 7;
                      } else if (value.includes("[8]")) {
                        return 8;
                      }
                      return 0;
                    }
                    return 0;
                  },
                  cellClass: (params) =>
                    getCellClass(
                      params,
                      field?.name,
                      columnFreezeLeft,
                      columnFreezeRight,
                      index
                    ),
                };
              }
            );
            setColumnDefs(columns);
            setComponentData(refData[displayData?.refValue]?.value);
            let data = cachedData;
            data[component_id] = {
              columnDefs: columns,
              rowData: refData[displayData?.refValue]?.value?.data,
            };
            setCacheData(data);
            if (displayData?.rowFreezeTop) {
              if (displayData?.rowFreezeTop.length > 0 && Number(displayData?.rowFreezeTop) > 0) {
                setHideHeader(true);
              }
              handleTopPinnedRowChange(
                refData[displayData?.refValue]?.value?.data,
                displayData?.rowFreezeTop
              );
            } else {
              setRowData(refData[displayData?.refValue]?.value?.data);
            }
            if (displayData?.rowFreezeBottom) {
              handleBottomPinnedRowChange(
                refData[displayData?.refValue]?.value?.data,
                displayData?.rowFreezeBottom
              );
            }
            clearFilterApply();
            setTempStorageCall(false);
          }
        });
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setComponentDataLoading(false);
      });
  };

  const setToggleFilterHandle = () => {
    if (toggleTableView) {
      setToggleFilter(!toggleFilter);
    } else {
      setToggleTableView(true);
      setToggleFilter(true);
    }
  };

  let savedFilterListApiBody = {
    FilterCriteria: [
      {
        filter_type: "text",
        type: "equal",
        filter: JSON.parse(localStorage.getItem("userid")),
        filter_field: "user_id",
      },
      {
        filter_type: "text",
        type: "equal",
        filter: objData.page.page_id,
        filter_field: "page_id",
      },
    ],
    export: true,
  };

  const setResetFilter = () => {
    setSysFilter(initialFilter);
    setFilter(initialFilter);
  };

  const getFilterCriteria = (filter_criteria) => {
    let sys_filter = { ...sysFilter };
    sys_filter.FilterCriteria = filter_criteria;
    sys_filter.PaginationCriteria = {
      limit: 10,
      skip: 0,
    };
    setSysFilter(sys_filter);
    setFilter(sys_filter);
  };

  const setFilter = (filter) => {
    setSysFilter(filter);
    let bodyObject = {
      ...executeAppObject,
      sys_filter: filter,
    };
    setComponentDataLoading(true);
    appExecutionEngine(bodyObject);
  };

  let pageFieldsList = [];
  if (displayData?.page_field?.length > 0) {
    pageFieldsList = displayData?.page_field;
  } else {
    pageFieldsList = componentData?.value?.fields;
  }

  const reloadComponentData = () => {
    setComponentDataLoading(true);
    appExecutionEngine(executeAppObject);
  };

  const handleTopPinnedRowChange = (rowData, rows) => {
    const newPinnedTopRows = rowData.splice(0, rows);
    setPinnedTopRows(newPinnedTopRows || []);
    setRowData(rowData);
    //let data = { ...cachedData };
    //data[displayData?.refValue]["pinnedTopRows"] = newPinnedTopRows;
    //setCacheData({ ...cachedData, ...data });
  };

  const handleBottomPinnedRowChange = (rowData, rows) => {
    const newPinnedBottomRows = rowData.splice(-rows);
    setPinnedBottomRows(newPinnedBottomRows || []);
    setRowData(rowData);
    //setPinnedBottomRows([...pinnedBottomRows, newPinnedBottomRows]);
    //let data = { ...cachedData };
    //data[displayData?.refValue]["pinnedBottomRows"] = [
    //  ...pinnedBottomRows,
    //  newPinnedBottomRows,
    //];
    //setCacheData({ ...cachedData, ...data });
  };

  const exportData = () => {
    const params = {
      fileName: displayData?.tableName + ".csv",
    };
    gridRef.current.api.exportDataAsCsv(params);
  };

  const getRowStyle = (params) => {
    const columnKeys = displayData?.columnKey?.split(",") || [];
    if (params.node.rowIndex > 0) {
      for (const columnKey of columnKeys) {
        if (
          params?.data?.[columnKey] !==
          params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1)?.data?.[
          columnKey
          ]
        ) {
          return { borderTop: "2px solid #0d6efd" };
        }
      }
    }
    return null;
  };

  const getCellClass = (
    params,
    name,
    columnFreezeLeft,
    columnFreezeRight,
    index
  ) => {
    const columnKeys = displayData?.rowSpan?.split(",") || [];
    const pinned = Number(columnFreezeLeft || "0") > index
      ? true
      : Number(columnFreezeRight || "0") >= index
        ? true
        : false;
    if (params.node.rowIndex === 0) {
      return pinned ? "pinned-column-border" : "";
    } else {
      const previousRowValue = params.api.getDisplayedRowAtIndex(
        params.node.rowIndex - 1
      )?.data[name];
      const currentValue = params.data[name];
      return previousRowValue === currentValue && columnKeys.includes(name)
        ? "empty-cell"
        : pinned
          ? "pinned-column-border"
          : "";
    }
  };

  return (
    <Col md={displayData?.columnWidthTable || "12"} className={"px-0"}>
      <div className={`h-100 mx-1`}>
        <div className="main-content bg-white py-1 px-1 mb-2">
          <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
            <h5 className="table-heading">{displayData?.tableName}</h5>
            <div className="d-flex align-items-center">
              {rowData.length > 0 && (
                <div className="d-flex align-items-center">
                  <button
                    onClick={exportData}
                    className="header-icon-button icon-hover-effect"
                    title="Export Csv"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-file-csv"
                      size="xl"
                      color="#73879c"
                      cursor="hand"
                    />
                  </button>
                  {displayData?.showFilter === undefined ||
                    displayData?.showFilter ? (
                    <button
                      onClick={setToggleFilterHandle}
                      className="header-icon-button icon-hover-effect"
                      title="Filter"
                    >
                      <FontAwesomeIcon
                        icon="fa fa-filter"
                        size="xl"
                        color="#73879c"
                      />
                    </button>
                  ) : null}
                  <button
                    className="arrowDownContainer header-icon-button icon-hover-effect"
                    title="Refresh"
                    onClick={() => reloadComponentData()}
                  >
                    <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} />
                  </button>
                  <button
                    className="arrowDownContainer header-icon-button icon-hover-effect"
                    title={toggleTableView ? "Collapse Table" : "Expand Table"}
                    onClick={() => setToggleTableView(!toggleTableView)}
                    aria-controls="table-toggle"
                    aria-expanded={toggleTableView}
                  >
                    <FontAwesomeIcon
                      icon={"fa fa-angle-down"}
                      className={`arrow-icon ${toggleTableView ? "arrow-icon-up" : "arrow-icon-down"
                        }`}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>

          <Collapse in={toggleTableView}>
            <div id="table-toggle">
              <FilterComponent
                show={toggleFilter}
                setResetFilter={setResetFilter}
                onChange={getFilterCriteria}
                filterCriteriaList={sysFilter.FilterCriteria}
                columnList={
                  pageFieldsList?.map((field) => {
                    return {
                      label: field.display_name,
                      value: field.name,
                      dataType: field.data_type,
                    };
                  }) || []
                }
                savefilterId={objData?.page?.page_id}
                datasetName="sys_app_data_filter"
                savedFilterApiBody={savedFilterListApiBody}
              />
              <div className="position-relative">
                {componentDataLoading && <AppLoader />}
                <div className={`ag-theme-mytheme ag-table-container ${hideHeader ? "hide-header" : ""}`}>
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    animateRows="true"
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    pinnedTopRowData={pinnedTopRows}
                    pinnedBottomRowData={pinnedBottomRows}
                    getRowStyle={getRowStyle}
                    rowHeight={60}
                    suppressRowTransform={true}
                    suppressDragLeaveHidesColumns={true}
                    domLayout={"autoHeight"}
                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Col>
  );
};

export default DynamicTable;
