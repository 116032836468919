import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import createEngine, { DiagramModel } from "@projectstorm/react-diagrams";
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import VPLinkFactory from "./VPLink/VPLinkFactory";
import CustomNodeModel from "./CustomNode/CustomNodeModel";
import CustomNodeFactory from "./CustomNode/CustomNodeFactory";
import VPPortFactory from "./VPPort/VPPortFactory";
import * as API from "../../../../services/API";
import NodeMenu from "./NodeMenu";
import "../../../../styles/visual_programming/Workspace.css";
import GlobalFlowMenu from "./GlobalFlowMenu";
import { Modal } from "react-bootstrap";
import { saveAppResonseByJson } from "../../../../services/datasetService";
import { toast } from "react-toastify";
import { getFlowRecordDataById } from "../../../../services/API";
import { withRouter } from "react-router-dom";
import { NodeContext } from "../../../../contexts/NodeContext";
import Loader from "../../../dataset/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Workspace extends React.Component {
  static contextType = NodeContext;
  constructor(props) {
    super(props);
    this.engine = createEngine({ registerDefaultZoomCanvasAction: false });
    this.engine.getNodeFactories().registerFactory(new CustomNodeFactory());
    this.engine.getLinkFactories().registerFactory(new VPLinkFactory());
    this.engine.getPortFactories().registerFactory(new VPPortFactory());
    this.model = new DiagramModel();
    this.engine.setModel(this.model);
    this.engine.setMaxNumberPointsPerLink(0);
    this.getAvailableNodes = this.getAvailableNodes.bind(this);
    this.getGlobalVars = this.getGlobalVars.bind(this);
    this.load = this.load.bind(this);
    this.clear = this.clear.bind(this);
    this.handleNodeCreation = this.handleNodeCreation.bind(this);
    this.execute = this.execute.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.saveEntireWorkflowAndReload = this.saveEntireWorkflowAndReload.bind(this);
    this.clearAllLinks = this.clearAllLinks.bind(this);
    this.clearLinksCache = this.clearLinksCache.bind(this);
    this.state = {
      loading: true,
      loadingNodes: false,
      nodes: [],
      globals: [],
      openMenu: "GlobalFlowMenu",
      show: false,
      showExecutionError: "",
      appFlowName: "",
      appFlowDescription: "",
      appId: "",
      errorMessage: "",
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      session_id: JSON.parse(localStorage.getItem("session_id")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      logic_id: "",
      save_after_clear: false,
      isTestingLogic: false,
      executionOrder: [],// list of execution order objects with status. Eg: {'name':'if','status':true}
    };
  }

  componentDidMount() {
    this.callNodeFunction();
    const logic_id = this.props.location.pathname.split("/").slice(-1)[0];
    this.setState({ logic_id });
  }

  componentDidUpdate() {
    if (this.context.flag === true) {
      this.saveJsonData(this.model.serialize(), true);
      // this.callNodes();
      this.context.setFlag(false);
    }
  }

  callNodes() {
    this.setState(
      {
        nodes: [],
        loadingNodes: true,
      },
      () => {
        API.getNodes()

          .then((nodes) =>
            this.setState({ nodes: nodes, loadingNodes: false }, () => {
              this.context.setFlag(false);
            })
          )
          .catch((err) => {
            this.setState(
              {
                loading: false,
                loadingNodes: false,
              },
              () => {
                if (err) {
                  toast.error(err.message);
                }
                this.context.setFlag(false);
              }
            );
          });
      }
    );
  }

  callNodeFunction() {
    this.setState(
      {
        nodes: [],
      },
      () => {
        API.initWorkflow(this.model)
          .then((response) => {
            this.setState(
              {
                nodes: [],
              },
              () => {
                this.getAvailableNodes();
                this.getGlobalVars();
              }
            );
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  }

  /**
   * Retrieve available nodes from server to display in menu
   */
  getAvailableNodes() {
    API.getNodes()
      .then((nodes) =>
        this.setState({ nodes: nodes }, () => {
          this.load();
        })
      )
      .catch((err) => {
        this.setState(
          {
            loading: false,
          },
          () => {
            if (err) {
              toast.error(err.message);
            }
          }
        );
      });
  }

  getGlobalVars() {
    API.getGlobalVars()
      .then((vars) => {
        this.setState({ globals: vars });
      })
      .catch((err) => {});
  }

  handleClose() {
    this.setState({ show: false });
  }

  load() {
    let objData = {
      tenant_id: this.state.tenant_Id,
      dataset_name:
        this.props.app_Data === "/app_flow/flow_builder/:id"
          ? "fc_app_flows"
          : "fc_app_logics",
      id: this.props.objId,
    };
    this.setState({ loading: true });
    getFlowRecordDataById(objData)
      .then((response) => {
        if (response.success === true) {
          this.setState(
            {
              appFlowName: response.data.function_name,
              appFlowDescription: response.data.description,
              appId: response.data.app_id,
              loading: false,
            },
            () => {
              if (response.data.function_json) {
                this.setState(
                  {
                    getJsonValue: JSON.parse(response.data.function_json),
                  },
                  () => {
                    this.loadData(this.state.getJsonValue);
                  }
                );
              }
            }
          );
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  backToApp() {
    if (this.props.app_Data === "/app_flow/flow_builder/:id") {
      this.props.history.push(`/app_flow/${this.state.logic_id}`);
    } else {
      this.props.history.push(`/app_logic/${this.state.logic_id}`);
    }
  }

  loadData(jsonValue) {
    const form = new FormData();
    form.append("file", JSON.stringify(jsonValue));
    API.uploadWorkflow(form)
      .then((json) => {
        this.model.deserializeModel(jsonValue.react, this.engine);
        setTimeout(() => this.engine.repaintCanvas(), 3000);
        this.getGlobalVars();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  /**
   * Remove all nodes from diagram and initialize new workflow on server
   */
  clear() {
    if (window.confirm("Clear diagram? You will lose all work.")) {
      if (window.confirm("Are you sure you want to clear the entire workflow? You will lose all work.")) {
        API.clearFlowVars(this.props.objId)
          .then((response) => {
            this.model.getNodes().forEach((n) => n.remove());
            API.initWorkflow(this.model).then((response) => {
              if (response) {
                this.saveJsonData(this.model.serialize(), true);
              }
            });
            this.engine.repaintCanvas();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  }

  // takes data from node drop and creates node on server and in diagram
  handleNodeCreation(event) {
    const evtData = event.dataTransfer.getData("storm-diagram-node");
    if (!evtData) return;
    const data = JSON.parse(evtData);
    const node = new CustomNodeModel(data.nodeInfo, data.config),
      point = this.engine.getRelativeMousePoint(event);
    node.setPosition(point);
    API.addNode(node)
      .then(() => {
        this.model.addNode(node);
        this.forceUpdate();
      })
      .catch((err) => {
        if (err) {
          toast.error(err.message);
        }
      });
  }

  async execute() {
    let tempExecutionOrder = [];
    this.setState({ isTestingLogic: true, executionOrder: tempExecutionOrder });
    let body = {
      logic_id: this.state.logic_id,
      tenant_id: this.state.tenant_Id,
      session_id: this.state.session_id,
      user_id: this.state.user_id,
    };
    let order = {"order": []}
    await API.executionOrder(body).then((resp) => {
      order = resp;
    }).catch((err) => {
      this.setState({ isTestingLogic: false });
      toast.error("Please check network connection and reload the page");
    });
    const backendPyWorkflow = this.state.getJsonValue || this.state.jsonStringData;
    const workflowNodes = backendPyWorkflow.pyworkflow.graph.nodes;
    let nodeName = "";
    for (let i = 0; i < order.order.length; ++i) {
      let node = this.model.getNode(order.order[i]);
      let body = {
        node: node,
        test_session_id: order.test_session_id,
      };
      // find node name
      const filteredNodes = workflowNodes.filter((d) => {
        return d.node_id === order.order[i];
      });
      if (filteredNodes && filteredNodes.length > 0) {
        nodeName = filteredNodes[0].name;
      }
      try {
        await API.execute(body).then((response) => {
          if (response)
            this.setState({
              show: true,
              showExecutionError: response.message,
              isTestingLogic: false,
            });
        });
        node.setStatus("complete");
        // repainting canvas didn't update status light, so
        // this is hack to re-render the node widget
        node.setSelected(true);
        node.setSelected(false);
        if (node.options.download_result) {
          // TODO: make this work for non-WriteCsvNode nodes
          API.downloadDataFile(node).catch();
        }
        tempExecutionOrder.push({"name": nodeName,"status": true});
      } catch (err) {
        tempExecutionOrder.push({"name": nodeName,"status": false});
        if (err){
            this.setState({
              show: true,
              showExecutionError: "Error in node: " + err.node_name + " , Msg: " + err.error,
              loading: false,
              isTestingLogic: false,
            });
          }
        break;
      }
    }
    this.setState({ executionOrder: tempExecutionOrder });
  }

  // this function saves only the frontend model
  async saveJsonData(diagramData, reloadLinks) {
    API.getGlobalVars()
      .then((vars) => {
        this.setState({ globals: vars });
      })
      .catch((err) => {});
    Object.assign(diagramData, { tenant_id: this.state.tenant_Id });
    //console.info("Saving Diagram..");
    //console.info(diagramData);
    const payload = JSON.stringify(diagramData);
    const options = {
      method: "POST",
      body: payload,
    };
    this.setState({ loading: true });
    await API.saveNodeData("/workflow/save", options)
      .then((resp) => {
        this.setState(
          {
            jsonStringData: resp,
          },
          () => {
            this.saveLogicToDB(diagramData, this.state.jsonStringData, reloadLinks);
          }
        );
      })
      .catch((err) => {
        this.setState({ errorMessage: err.message, loading: false });
        if (this.state.errorMessage) {
          toast.error(this.state.errorMessage);
        }
      });
  }

  // this function saves logic object to db
  async saveLogicToDB(diagramData, logicObject, reloadLinks) {
    //console.info("Saving Logic to DB..");
    if (reloadLinks) {
      // re-evaluating links before saving
      let diagramLinks = undefined;
      if (logicObject.react.layers.length > 0) {
        diagramLinks = logicObject.react.layers[0].models;
      }
      if (diagramLinks) {
        const modelLinkIds = Object.keys(diagramLinks);
        logicObject.pyworkflow.graph.links = [];
        for (let i = 0; i < modelLinkIds.length;i++) {
          const diagramSource = diagramLinks[modelLinkIds[i]]["source"];
          const diagramTarget = diagramLinks[modelLinkIds[i]]["target"];
          logicObject.pyworkflow.graph.links.push({
            source: diagramSource,
            target: diagramTarget
          });
        }
      }
    }

    //console.info(logicObject);
    let obj = {
      tenant_id: this.state.tenant_Id,
      dataset_name:
        this.props.app_Data === "/app_flow/flow_builder/:id"
          ? "fc_app_flows"
          : "fc_app_logics",
      object_id: this.props.objId,
      fields: {
        function_name: this.state.appFlowName,
        description: this.state.appFlowDescription,
        function_json: JSON.stringify(logicObject),
        function_json_list: logicObject.function_json_list
          ? logicObject.function_json_list
          : [],
        app_id: this.state.app_id,
      },
    };
    await saveAppResonseByJson(obj).then((response) => {
      if (response.success === true) {
        this.setState(
          {
            loading: false,
          }
        );
        toast.success("Workflow saved successfully");
      }
    }).catch((err) => {
      this.setState({ errorMessage: err.message, loading: false });
      if (this.state.errorMessage) {
        toast.error("ERROR! "+this.state.errorMessage);
      }
    });
  }

  // Clears all links/edges in the in-memory pyworkflow
  async clearAllLinks() {
    const graphEdges = (await API.getAllWorkflowEdges()).edges;
    for (let i = 0; i < graphEdges.length; i++){
      const source = graphEdges[i][0];
      const target = graphEdges[i][1];
          await this.deleteEdgeCustom(source, target);
          await this.deleteEdgeCustom(target, source);
    }
  }

  // Clears all links/edges in the in-memory pyworkflow
  async clearLinksCache() {
    if (window.confirm("Please confirm you want to clear all links cache. They will be recreated when the page reloads.")) {
      const graphEdges = (await API.getAllWorkflowEdges()).edges;
      for (let i = 0; i < graphEdges.length; i++){
        const source = graphEdges[i][0];
        const target = graphEdges[i][1];
            await this.deleteEdgeCustom(source, target);
            await this.deleteEdgeCustom(target, source);
      }
      setTimeout(() => {this.saveEntireWorkflowAndReload()}, 1000);
    }
  }

  // function to save workflow + reset all edges + reload page
  async saveEntireWorkflowAndReload() {
    await this.validateAndSave();
    window.location.reload();
  }

  // function to validate nodes between the UI-Diagram, In-Memory Pyworkflow and Database Pyworkflow. Finally save the workflow.
  async validateAndSave() {
    const currentModel = this.engine.getModel();
    let diagramNodes = undefined;

    // fetch diagram nodes
    if (currentModel.layers.length > 1) {
      diagramNodes = currentModel.layers[1].models;
    } else if (currentModel.layers.length > 0) {
      diagramNodes = currentModel.layers[0].models;
    } else {
      await this.saveJsonData(this.model.serialize(), true);
      return;
    }

    // Step1: fetch in-memory pyworkflow nodes
    const graphNodes = (await API.getAllWorkflowNodes()).nodes;

    // Step2: make graph nodes consistent with UI model
    if(graphNodes && diagramNodes){
      for (let i = 0; i < graphNodes.length; i++){
        const nodeId = graphNodes[i];
        if (!diagramNodes[nodeId]) {
          console.info("[RP#01] Error with node in graph: " + nodeId);
          await this.deleteNodeCustom(nodeId);
        }
      }
    }

    // Step3: fetch database pyworkflow nodes and edges
    const backendPyWorkflow = this.state.getJsonValue || this.state.jsonStringData;
    if (!backendPyWorkflow || !backendPyWorkflow.pyworkflow) {
      await this.saveJsonData(this.model.serialize(), true);
      return;
    }
    const workflowNodes = backendPyWorkflow.pyworkflow.graph.nodes;
    let nodesDict = {};

    // Step3: make database pyworkflow nodes consistent with UI model
    if (workflowNodes && diagramNodes) {
      for (let i = 0; i < workflowNodes.length; i++) {
        // just an added protection since we are altering the size of the array in this loop
        if (i >= workflowNodes.length) {
          break;
        }
        let nodeId = workflowNodes[i].node_id;
        nodesDict[nodeId] = workflowNodes[i].name;
        if (!diagramNodes[nodeId]) {
          console.info("[RP#01] Extra node in database: " + nodeId + " ,Name: " + workflowNodes[i].name);
          backendPyWorkflow.pyworkflow.graph.nodes.splice(i, 1);
        }
      }
    }

    // Step3: Save the workflow
    await this.saveJsonData(this.model.serialize(), true);
    return;
  }

  // function to validate nodes and edges between the UI-Diagram, In-Memory Pyworkflow and Database Pyworkflow
  async validateAndCorrectWorkflow(performLogicCorrections) {
    const currentModel = this.engine.getModel();
    let diagramLinks = undefined;
    let diagramNodes = undefined;
    let modelConsistent = true;

    // fetch diagram nodes and edges
    if (currentModel.layers.length > 1) {
      diagramLinks = currentModel.layers[0].models;
      diagramNodes = currentModel.layers[1].models;
    } else if (currentModel.layers.length > 0) {
      diagramNodes = currentModel.layers[0].models;
    } else {
      return modelConsistent;
    }

    // fetch in-memory pyworkflow nodes and edges
    const graphNodes = (await API.getAllWorkflowNodes()).nodes;
    const graphEdges = (await API.getAllWorkflowEdges()).edges;

    // Step1: make graph nodes consistent with UI model
    if(graphNodes && diagramNodes){
      for (let i = 0; i < graphNodes.length; i++){
        const nodeId = graphNodes[i];
        if (!diagramNodes[nodeId]) {
          console.error("[RP#01] Error with node in graph: " + nodeId);
          modelConsistent = false;
          if (performLogicCorrections) {
            this.deleteNodeCustom(nodeId);
          }
        }
      }
    }

    // Step2: make graph edges consistent with UI model
    if (graphEdges && diagramLinks) {
      for (let i = 0; i < graphEdges.length; i++){
        const source = graphEdges[i][0];
        const target = graphEdges[i][1];
        const modelLinkIds = Object.keys(diagramLinks);
        let linkFound = false;
        for (let j = 0; j < modelLinkIds.length;j++) {
          let diagramSource = diagramLinks[modelLinkIds[j]]["source"];
          let diagramTarget = diagramLinks[modelLinkIds[j]]["target"];

          if (!diagramSource || !diagramTarget) {
            const linkParents = Object.keys(diagramLinks[modelLinkIds[j]].sourcePort.parent.parent.models);
            if (linkParents.length > 0) {
              diagramSource = linkParents[0];
            }
            if (linkParents.length > 1) {
              diagramTarget = linkParents[1];
            }
          }

          if (source === diagramSource && target === diagramTarget) {
            linkFound = true;
            break;
          }
        }
        if (!linkFound) {
          console.error("[RP#01] Extra edge in graph source: " + source + " , target: " + target);
          if (performLogicCorrections) {
            await this.deleteEdgeCustom(source, target);
            await this.deleteEdgeCustom(target, source);
          }
          modelConsistent = false;
        }
      }
    }

    // fetch database pyworkflow nodes and edges
    const backendPyWorkflow = this.state.getJsonValue || this.state.jsonStringData;
    if (!backendPyWorkflow || !backendPyWorkflow.pyworkflow) {
      return modelConsistent;
    }
    const workflowNodes = backendPyWorkflow.pyworkflow.graph.nodes;
    const workflowLinks = backendPyWorkflow.pyworkflow.graph.links;
    let nodesDict = {};

    // Step3: make database pyworkflow nodes consistent with UI model
    if (workflowNodes && diagramNodes) {
      for (let i = 0; i < workflowNodes.length; i++) {
        // just an added protection since we are altering the size of the array in this loop
        if (i >= workflowNodes.length) {
          break;
        }
        let nodeId = workflowNodes[i].node_id;
        nodesDict[nodeId] = workflowNodes[i].name;
        if (!diagramNodes[nodeId]) {
          console.error("[RP#01] Extra node in database: " + nodeId + " ,Name: " + workflowNodes[i].name);
          modelConsistent = false;
          if (performLogicCorrections) {
            backendPyWorkflow.pyworkflow.graph.nodes.splice(i, 1);
          }
        }
      }
    }

    // Step4: make database pyworkflow edges consistent with UI model
    if (workflowLinks && diagramLinks) {
      // validate links
      for (let i = 0; i < workflowLinks.length; i++) {
        // just an added protection since we are altering the size of the array in this loop
        if (i >= workflowLinks.length) {
          break;
        }
        let source = workflowLinks[i].source;
        let target = workflowLinks[i].target;
        let linkFound = false;

        const modelLinkIds = Object.keys(diagramLinks);
        for (let j = 0; j < modelLinkIds.length;j++) {
          let diagramSource = diagramLinks[modelLinkIds[j]]["source"];
          let diagramTarget = diagramLinks[modelLinkIds[j]]["target"];

          if (!diagramSource || !diagramTarget) {
            const linkParents = Object.keys(diagramLinks[modelLinkIds[j]].sourcePort.parent.parent.models);
            if (linkParents.length > 0) {
              diagramSource = linkParents[0];
            }
            if (linkParents.length > 1) {
              diagramTarget = linkParents[1];
            }
          }

          if (source === diagramSource && target === diagramTarget) {
            linkFound = true;
            break;
          }
        }

        if (!linkFound) {
          console.error("[RP#01] Extra edge in database, source: " + nodesDict[source] + " (" + source + ") , target: " + nodesDict[target] + " (" + target + ")");
          if (performLogicCorrections) {
            backendPyWorkflow.pyworkflow.graph.links.splice(i, 1);
          }
          modelConsistent = false;
        }
      }
    }

    if (performLogicCorrections && !modelConsistent) {
        await this.saveJsonData(this.model.serialize(), true);
        toast.success("Success: Logic corrections done. You may try again to confirm.");
    } else if(performLogicCorrections) {
        toast.success("Success: Logic is valid");
    }

    return modelConsistent;
  }

  //RP#01: Custom function to delete node if graph becomes inconsistent
  async deleteNodeCustom(nodeId) {
    const node = {
      options: {
        id: nodeId,
        is_global: false
      }
    };
    await API.deleteNode(node)
      .then((response) => {})
      .catch((err) => {});
  }

  //RP#01: Custom function to delete edge if graph becomes inconsistent
  async deleteEdgeCustom(sourceId, targetId) {
    await API.handleEdgeDelete(sourceId, targetId).then((response) => {}).catch((err) => {});
  }

  //RP#01: Custom function to delete edge if graph becomes inconsistent
  creadeEdgeCustom(sourceId, targetId) {
    API.handleEdgeAdd(sourceId, targetId).then((response) => { }).catch ((err) => { });
  }

  handleToggleMenu = (menuName) => {
    this.setState((prevState) => ({
        openMenu: prevState.openMenu === menuName ? null : menuName, // Toggle or close
    }));
};
  
  
  render() {
    return (
      <>
        {!this.state.loading ? (
          <>
            <Row className="mb-1 workspace">
              <Col md={12}>
                <div className="d-help py-2">
                  {this.props.match.path === "/add_app_logic/:appId" ||
                  this.props.match.path === "/app_logic/:id" ||
                  this.props.match.path === "/app_logic/logic_editor/:id" ? (
                    <div className="d-flex align-items-center">
                      <h4 className="me-2 mb-0">Logic Editor</h4>
                      <span className="fs-5">({this.state.appFlowName})</span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <h4 className="me-2 mb-0">Flow Builder</h4>
                      <span className="fs-5">({this.state.appFlowName})</span>
                    </div>
                  )}
                  <div>
                    {this.props.app_Data !== "/app_flow/flow_builder/:id" ? (
                      <Button className="testLogic-btn" title="Test the logic/flow on dummy data set in the flow variables" onClick={this.execute}>
                        <div className="vpFormSubmitSpinner">
                          {this.state.isTestingLogic && !this.state.loading && (
                            <div className="spinner-border text-light me-1" />
                          )}
                          <span>&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-check" />&nbsp;&nbsp;Test Logic&nbsp;&nbsp;</span>
                        </div>
                      </Button>
                    ) : null}
                    <Button title="Save the logic/workflow" className="custom-btn" size="sm" onClick={this.saveEntireWorkflowAndReload}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-floppy-disk" />&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                    {/*
                      <Button title="Recreate all the links in the workflow. Use this option only when execution order seems incorrect." variant="danger" size="sm" onClick={this.clearLinksCache}>
                      &nbsp;<FontAwesomeIcon icon="fa fa-solid fa-link" />&nbsp;&nbsp;Clear Links Cache&nbsp;
                      </Button>
                      */
                    }
                    <Button title="Clears the entire logic/flow including flow variables and gives you an empty workspace to start fresh. Please click with caution!" className="cancel-btn" size="sm" onClick={this.clear}>
                      &nbsp;<FontAwesomeIcon icon="fa fa-solid fa-eraser" />&nbsp;&nbsp;Reset Workspace!&nbsp;
                    </Button>
                    <Button
                      className="back-btn"
                      size="sm"
                      onClick={this.props.history?.goBack}
                    >
                      <FontAwesomeIcon icon="fa fa-arrow-left" />
                      <span className="ms-1">&nbsp;&nbsp;Back&nbsp;&nbsp;</span>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="Workspace bg-white">
            <Col xs={3}>
                <GlobalFlowMenu
                    menuItems={this.state.nodes["Flow Control"] || []}
                    nodes={this.state.globals}
                    onUpdate={this.getGlobalVars}
                    diagramModel={this.model}
                    isOpen={this.state.openMenu === "GlobalFlowMenu"}
                    onToggle={() => this.handleToggleMenu("GlobalFlowMenu")}
                />
                {this.state.loadingNodes ? (
                    <Loader />
                ) : (
                    <NodeMenu
                        nodes={this.state.nodes}
                        onUpload={this.getAvailableNodes}
                        isOpen={this.state.openMenu === "NodeMenu"}
                        onToggle={() => this.handleToggleMenu("NodeMenu")}
                    />
                )}
            </Col>
              <Col xs={9} style={{ paddingLeft: 0 }}>
                {this.state.loading ? (
                  <Loader />
                ) : (
                  <div
                    style={{ position: "relative", flexGrow: 1 }}
                    onDrop={(event) => this.handleNodeCreation(event)}
                    onDragOver={(event) => event.preventDefault()}
                  >
                    <CanvasWidget
                      className="diagram-canvas"
                      engine={this.engine}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <Loader />
        )}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Logic Test</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="m-0 pt-4 pb-4 border ps-2">
              {this.state.showExecutionError}
            </p>
            <br/>
            <h5>Execution Order:</h5>
            <ol>{this.state.executionOrder.map((node) => (<li>&nbsp;&nbsp;&nbsp;&nbsp;{node.status ? (<FontAwesomeIcon icon="fa-solid fa-check" className="highlight-success"/>): (<FontAwesomeIcon icon="fa-solid fa-xmark" className="highlight-error"/>)}&nbsp;&nbsp;&nbsp;&nbsp;{node.name}</li>))}</ol>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(Workspace);
