import React, { useEffect, useState } from "react";
import { executeLogicByName, executeAppForActions } from "../../../services/API";
import AppLoader from "../../dataset/Loader/AppLoader";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./printableView.scss";
import Handlebars from "handlebars";

const PrintableView = ({
  template,
  filterApply,
  columnWidth,
  displayData,
  appSessionId,
}) => {
  const [printData, setPrintData] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({});

  useEffect(() => {
    getPrintData();
  }, [appSessionId, displayData]);

  useEffect(() => {
    if (filterApply) {
      getPrintData();
    }
  }, [filterApply]);

  useEffect(() => {
    if (template && printData) {
      try {
        const source = template;
        const compiledTemplate = Handlebars.compile(source);
        const renderedInvoice = compiledTemplate({
          ...printData,
          ...inputFields,
        });
        document.getElementById("invoice-container").innerHTML = renderedInvoice;
      } catch (error) {
        toast.error("Error rendering the template.");
        console.error(error);
      }
    }
  }, [template, printData, inputFields]);

  const getPrintData = async () => {
    setLoading(true);
    const obj = {
      app_session_id: appSessionId,
      logic_name: displayData?.componentLogic,
      reference_name: displayData?.refValue,
    };
    try {
      const response = await executeLogicByName(obj);
      if (response?.data?.value?.data) {
        const [data] = response.data.value.data;
        setPrintData(data || {});
      } else {
        toast.warn("No data returned from the logic execution.");
      }
    } catch (error) {
      toast.error(error?.message || "Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const printDocument = () => {
    window.print();
  };

  const updatedexecuteAppAction = async () => {
    const { updatedRefValue, updatedComponentLogic } = displayData?.updatedFields || {};

    if (!updatedRefValue || !updatedComponentLogic) {
      toast.error("Updated logic and reference values are required.");
      return;
    }

    // Format date fields in inputFields to dd-mm-yyyy format
    const formattedInputFields = { ...inputFields };
    Object.keys(formattedInputFields).forEach((key) => {
      const value = formattedInputFields[key];
      if (/^\d{4}-\d{2}-\d{2}$/.test(value)) { // Check if the value is in yyyy-mm-dd format
        const [year, month, day] = value.split("-");
        formattedInputFields[key] = `${day}-${month}-${year}`;
      }
    });

    const finalPayload = {
      app_session_id: appSessionId,
      logic_name: updatedComponentLogic,
      reference_name: updatedRefValue,
      data: {
        ...printData,
        ...formattedInputFields, // Includes formatted date and other inputs
      }
    };

    try {
      const response = await executeAppForActions(finalPayload);
      if (response?.message) {
        toast.success(response.message);

        if (response.transitionAppId && response.transitionPageId) {
          window.location.href = `/app_ui/${response.transitionAppId}/${response.transitionPageId}`;
        }
      }
    } catch (error) {
      toast.error(error?.message || "Error executing action.");
    }
  };

  useEffect(() => {
    // Registering custom helpers for Handlebars
    Handlebars.registerHelper("input", function (options) {
      const { name, placeholder = "", value = "" } = options.hash;
      return new Handlebars.SafeString(
        `<input 
           type="text" 
           name="${name}" 
           placeholder="${placeholder}" 
           value="${value}" 
           onChange="window.updateInputField(this.name, this.value)"
           class="form-control dynamic-input"
         />`
      );
    });

    Handlebars.registerHelper("dropdown", function (options) {
      const { name, selected = "" } = options.hash;
      const contextOptions = options.fn(this).trim();
      const optionsHtml = contextOptions
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line)
        .join("");

      return new Handlebars.SafeString(
        `<select 
           name="${name}" 
           onchange="window.updateInputField(this.name, this.value)"
           class="form-control dynamic-dropdown"
         >
           <option value="${selected}">${selected}</option>
           ${optionsHtml}
         </select>`
      );
    });

    Handlebars.registerHelper("date", function (options) {
      const { name, value = "" } = options.hash;
      return new Handlebars.SafeString(
        `<input 
           type="date"
           name="${name}" 
           value="${value}" 
           oninput="window.updateInputField(this.name, this.value)" 
           onchange="window.updateInputField(this.name, this.value)" 
           class="form-control dynamic-date"
         />`
      );
    });

    window.updateInputField = (name, value) => {
      setInputFields((prevFields) => ({
        ...prevFields,
        [name]: value, // Store the raw input value, whether typed or selected
      }));
    };
  }, [printData]);

  useEffect(() => {
    Object.keys(inputFields).forEach((key) => {
      const input = document.querySelector(`input[name="${key}"]`);
      if (input) {
        input.value = inputFields[key];
      }
    });
  }, [inputFields]);

  return (
    <div className={`col-md-${columnWidth} pb-3 position-relative`}>
      {loading && <AppLoader />}
      <div className="bg-white main-content h-100">
        <div className="col-md-12 text-end mt-2">
        {displayData?.updatedFields?.updatedRefValue &&
          displayData?.updatedFields?.updatedComponentLogic && (
            <button
              className="btn btn-md btn-success mb-2"
              onClick={updatedexecuteAppAction}
              title="Click to Submit"
            >
              Submit
            </button>
          )}

          <button
            className="btn btn-md btn-success mb-2"
            onClick={printDocument}
            title="Click to Print"
          >
            <FontAwesomeIcon icon="fa fa-print" />
          </button>
        </div>
        <div id="invoice-container" className="content-to-print"></div>
      </div>
    </div>
  );
};

export default PrintableView;
