import React, { useEffect, useState } from "react";
import "./ListView.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import {
  executeAppForActions,
  reactAppExecutionVP,
} from "../../../services/API";
import { actionTypes, inputTypeKey } from "../../common/model/Model";
import LinkingWrap from "../SubTable/inLineEdit/LinkingWrap";
import { useHistory, useParams } from "react-router-dom";
import ListActions from "./ListActions";
import LineItemModal from "../SubTable/LineItemModal";
import TableHeaderButton from "../SubTable/TableHeaderButton";
import Loader from "../../dataset/Loader/Loader";
import { formatCurrency } from "../../../services/utils";

const ListView = ({
  displayData,
  setReloadComponents,
  objData,
  appSessionId,
}) => {
  let initialFilter = {
    FilterCriteria: [],
    SortCriteria: [],
    PaginationCriteria: {
      limit: 5,
      skip: 0,
    },
  };
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [sysFilter, setSysFilter] = useState(initialFilter);
  const [componentData, setComponentData] = useState([]);
  const [privilegeButtons, setPrivilegeButtons] = useState([]);
  const [multipleActions, setMultipleActions] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [lineItemsModalState, setLineItemsModalState] = useState(false);
  const [lineItemHeaderFieldData, setLineItemHeaderFieldData] = useState("");
  const [lineItemData, setLineItemData] = useState([]);
  const history = useHistory();
  const params = useParams();
  const userInfo = JSON.parse(localStorage.getItem("user-info"));

  useEffect(() => {
    setLoadMoreLoader(true);
    appExecutionEngine(executeAppObject);
    setActivePage(1);
    getPrivileges();
  }, []);

  useEffect(() => {
    paginationData(activePage);
  }, [activePage]);

  useEffect(() => {
    appExecutionEngine(executeAppObject);
  }, [sysFilter]);

  useEffect(() => {
    const { autoReloadComponent, autoReloadDelay } = displayData;
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(
        () => appExecutionEngine(executeAppObject),
        autoReloadDelay
      );
      return () => clearInterval(intervalId);
    }
  }, []);

  let executeAppObject = {
    data: {
      logic:
        objData?.logic?.length > 0
          ? objData?.logic
          : [displayData?.componentLogic],
    },
    referenceValue: [{ refValue: displayData?.refValue }],
    app_session_id: appSessionId,
    sys_filter: sysFilter,
  };
  const appExecutionEngine = (bodyObject) => {
    reactAppExecutionVP(bodyObject, signal)
      .then((response) => {
        response?.data?.map((refData) => {
          setTotalRecord(refData[displayData?.refValue].total_count);
          if (refData[displayData?.refValue]) {
            let listData = refData[displayData?.refValue].value?.data;
            setComponentData([...componentData, ...listData]);
          }
        });
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setShowLoader(false);
        setLoadMoreLoader(false);
      });
  };

  const paginationData = (pageNumber) => {
    let sys_filter = { ...sysFilter };
    sys_filter.PaginationCriteria = {
      limit: 5,
      skip: (pageNumber - 1) * 5,
    };
    setSysFilter(sys_filter);
    setActivePage(pageNumber);
  };

  const [data] = objData?.page?.page_fields;
  const labels = data?.map((field) => field);
  const noOfSections = displayData?.sectionNames?.length
    ? displayData?.sectionNames?.length
    : 3;
  const labelsPerSection = Math.ceil(labels?.length / noOfSections);
  const sections = [];

  for (let i = 0; i < noOfSections; i++) {
    let obj = {
      nameOfSection: displayData?.sectionNames
        ? displayData?.sectionNames[i]
        : "",
      fields: labels?.slice(i * labelsPerSection, (i + 1) * labelsPerSection),
    };
    sections.push(obj);
  }

  let pageFieldsList = [];
  if (displayData?.page_field?.length > 0) {
    pageFieldsList = displayData?.page_field;
  } else {
    pageFieldsList = componentData?.value?.fields;
  }

  let formFieldList = pageFieldsList;
  pageFieldsList = pageFieldsList?.filter(
    (field) => !field?.show_key_to_table || field?.show_key_to_table !== "no"
  );

  const newData = componentData;

  const getPrivileges = () => {
    if (userInfo?.privileges) {
      const { app_previleges } = userInfo?.privileges;
      app_previleges?.forEach((data) => {
        const { _id, displayName, ...restData } = data;
        let app_id = "";
        Object.keys(restData).map((key) => {
          app_id = key;
        });
        if (app_id === params?.id) {
          data?.[app_id]?.pageList?.forEach((page) => {
            let buttonLists = [];
            if (page?.page_id === objData?.page?.page_id) {
              page.componentList.map((component) => {
                component.componentButtonList.map((buttonList) => {
                  buttonLists.push(buttonList);
                });
              });
              setPrivilegeButtons(buttonLists);
            }
          });
        }
      });
    }
  };
  const routePageToOneStepBack = () => {
    if (componentData?.value?.data.length < 2 && activePage > 1) {
      paginationData(activePage - 1);
    } else {
      reloadComponentData();
    }
  };

  const addComponentToReloadArray = (onActionReloadComponent) => {
    let components = onActionReloadComponent?.split(",") || [];
    if (components?.length) {
      setReloadComponents(components);
    }
  };

  const executeAppAction = (actionData, rowData) => {
    let objBody = {
      app_session_id: appSessionId,
      logic_name: actionData.logicNameValue || "",
      reference_name: actionData.referenceValue || "",
      data: [rowData],
    };

    executeAppForActions(objBody)
      .then((response) => {
        if (response) {
          if (response?.message) {
            toast.success(response?.message);
            routePageToOneStepBack();
            addComponentToReloadArray(actionData?.onActionReloadComponent);
            let link;
            if (actionData?.transitionAppId && actionData?.transitionPageId) {
              link = `/app_ui/${actionData?.transitionAppId}/${actionData?.transitionPageId}`;
              history.push(link);
            }
          }
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {});
  };
  
  const reloadComponentData = () => {
    appExecutionEngine(executeAppObject);
  };
  const onButtonActionClickHandler = (actionData, rowData) => {
    if (actionData?.actionType === actionTypes.button) {
      executeAppAction(actionData, rowData);
    }
  };
  const handlePagination = () => {
    setShowLoader(true);
    setActivePage((prevPage) => prevPage + 1);
  };
  const openModelData = (fieldData, data) => {
    setLineItemsModalState(true);
    setLineItemData(data);
    setLineItemHeaderFieldData(fieldData);
  };
  const getInlineStyle = (name, field) => {
    const matchedTag = field?.tags?.find((data) => data.name === name);
    const textColor =
      field?.textColor === "Dark" ? "text-secondary" : "text-white";
    if (matchedTag) {
      return (
        <span
          className={textColor}
          style={{
            backgroundColor: matchedTag.color,
            textShadow: "1px 0px 2px #000000",
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          {name}
        </span>
      );
    } else {
      return name;
    }
  };
  return loadMoreLoader ? (
    <div className="col-md-12">
      <Loader />
    </div>
  ) : (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
        <h5 className="table-heading">{displayData?.tableName}</h5>
        <div className="d-flex align-items-center">
          <TableHeaderButton
            executeAppObject={executeAppObject}
            objData={objData}
            formConfig={objData?.page?.form_config}
            formFieldsList={formFieldList}
            pageFieldsList={pageFieldsList}
            actionButtonList={displayData?.actionDetails}
            appSessionId={appSessionId}
            tableName={displayData?.listName}
            sysFilter={sysFilter}
            currentTableData={componentData?.value?.data}
            routePageToOneStepBack={routePageToOneStepBack}
            multipleActions={multipleActions}
            setMultipleActions={setMultipleActions}
            reloadComponentData={reloadComponentData}
            addComponentToReloadArray={addComponentToReloadArray}
          />
        </div>
      </div>
      {newData.length > 0 ? (
        newData?.map((dataItem, rowIndex) => (
          <div
            className={`list-view mb-2 ${
              rowIndex % 2 === 0 ? "bg-white" : "alternate-row"
            }`}
          >
            <div className="section-row">
              {sections.map((section, index) => (
                <div key={index} className="section">
                  <h4>{section.nameOfSection}</h4>
                  <ul className="unorder-list ps-2">
                    {section.fields.map((label, labelIndex) => {
                      if (label.data_type === inputTypeKey.numberTextBox) {
                        return (
                          <li className="label-value-pair">
                            <span className="label">
                              {label.icons ? (
                                <b className="boldTag">
                                  <FontAwesomeIcon icon={label?.icons?.value} />
                                </b>
                              ) : (
                                <b className="boldTag">{label.display_name}:</b>
                              )}
                            </span>
                            <span className="value">
                              {dataItem?.[label.name] ? (
                                <div>
                                  {label?.displayCurrencyFormat
                                    ? formatCurrency(
                                        dataItem?.[label.name],
                                        label?.displayCurrencyFormat
                                      )
                                    : dataItem?.[label.name] || "-"}
                                </div>
                              ) : (
                                0
                              )}
                            </span>
                          </li>
                        );
                      } else if (
                        label.data_type === "arr_obj" ||
                        label.data_type === "lineItems"
                      ) {
                        return (
                          <li className="label-value-pair">
                            <span className="label">
                              {label.icons ? (
                                <b className="boldTag">
                                  <FontAwesomeIcon icon={label?.icons?.value} />
                                </b>
                              ) : (
                                <b className="boldTag">{label.display_name}:</b>
                              )}
                            </span>
                            <span className="value">
                              {dataItem?.[label.name]?.length ? (
                                <div
                                  title="Click For More Details"
                                  role="button"
                                  onClick={() => {
                                    openModelData(
                                      label,
                                      dataItem?.[label.name]
                                    );
                                  }}
                                  className="fw-bold"
                                >
                                  {dataItem?.[label.name]?.length}
                                </div>
                              ) : (
                                0
                              )}
                            </span>
                          </li>
                        );
                      } else if (label.data_type === inputTypeKey.fileUpload) {
                        return (
                          <li className="label-value-pair">
                            <span className="label">
                              {label.icons ? (
                                <b className="boldTag">
                                  <FontAwesomeIcon icon={label?.icons?.value} />
                                </b>
                              ) : (
                                <b className="boldTag">{label.display_name}:</b>
                              )}
                            </span>
                            <span className="value">
                              {dataItem?.[label.name]?.length > 0
                                ? dataItem?.[label?.name]?.map(
                                    (fileList, i) => {
                                      return (
                                        <a
                                          href={fileList}
                                          target="_blank"
                                          download="true"
                                          key={i}
                                        >
                                          <FontAwesomeIcon
                                            icon={"fa fa-download"}
                                            className="me-2"
                                            aria-hidden="true"
                                          />
                                        </a>
                                      );
                                    }
                                  )
                                : "-"}
                            </span>
                          </li>
                        );
                      } else if (label.data_type === inputTypeKey.largeText) {
                        return (
                          <li key={labelIndex} className="label-value-pair">
                            <span className="label">
                              {label.icons ? (
                                <b className="boldTag">
                                  <FontAwesomeIcon icon={label?.icons?.value} />
                                </b>
                              ) : (
                                <b className="boldTag">{label.display_name}:</b>
                              )}
                            </span>
                            <span className="value">
                              <LinkingWrap
                                rowKeyData={dataItem}
                                linkData={label}
                                appSessionId={appSessionId}
                              >
                                {dataItem?.[label.name]?.length > 0 &&
                                  dataItem?.[label.name]?.replace(
                                    /(<([^>]+)>)/gi,
                                    ""
                                  )}
                              </LinkingWrap>
                            </span>
                          </li>
                        );
                      } else if (
                        label.data_type === inputTypeKey.searchableDropdown
                      ) {
                        return (
                          <li className="label-value-pair">
                            <span className="label">
                              {label.icons ? (
                                <b className="boldTag">
                                  <FontAwesomeIcon icon={label?.icons?.value} />
                                </b>
                              ) : (
                                <b className="boldTag">{label.display_name}:</b>
                              )}
                            </span>
                            <span className="label">
                              <b className="boldTag">{label.display_name}:</b>
                            </span>
                            <span>
                              <LinkingWrap
                                rowKeyData={dataItem}
                                linkData={label}
                                appSessionId={appSessionId}
                              >
                                {Array.isArray(dataItem?.[label.name]) &&
                                  dataItem?.[label.name]?.join(",")?.length &&
                                  dataItem?.[label.name]?.join(",")}
                              </LinkingWrap>
                            </span>
                          </li>
                        );
                      } else {
                        return (
                          <li key={labelIndex} className="label-value-pair">
                            <span className="label">
                              {label.icons ? (
                                <b className="boldTag">
                                  <FontAwesomeIcon icon={label?.icons?.value} />
                                </b>
                              ) : (
                                <b className="boldTag">{label.display_name}:</b>
                              )}
                            </span>
                            {dataItem?.[label.name] === "" ? (
                              <span className="value">-</span>
                            ) : (
                              <span className="value">
                                {getInlineStyle(dataItem[label?.name], label)}
                              </span>
                            )}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              ))}

              <ListActions
                actionDetails={displayData?.actionDetails}
                rowData={dataItem}
                onButtonActionClickHandler={onButtonActionClickHandler}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="text-center zeroData">"No data to display"</div>
      )}

      {newData && (
        <div className="text-center">
          {totalRecord !== componentData.length && (
            <button
              className="btn btn-outline-primary mb-2"
              type="button"
              onClick={() => {
                handlePagination();
              }}
            >
              {showLoader ? (
                <>
                  <div className="spinner-border spinner-border-sm me-1" />
                  Loading...
                </>
              ) : (
                "Load More"
              )}{" "}
            </button>
          )}
        </div>
      )}
      <LineItemModal
        lineItemsModalState={lineItemsModalState}
        setLineItemsModalState={setLineItemsModalState}
        lineItemHeaderFieldData={lineItemHeaderFieldData}
        showLineItemData={lineItemData}
      />
      
    </div>
  );
};

export default ListView;