import React, { useMemo, useState } from "react";
import Charts from "../../charts/Charts";
import SubTable from "../SubTable/subTable";
import CustomSection from "../CustomSection";
import CardsRender from "../Cards And Summary/CardRender";
import SummaryCard from "../Cards And Summary/SummaryCard";
import BigCalender from "../bigCalender/Bigcalender";
import { displayComponentType } from "../../common/model/Model";
import FilterForm from "../FilterForm/FilterForm";
import ScheduleListView from "../scheduleList/ScheduleListView";
import Kanban from "../kanban/Kanban";
import StepperForm from "./StepperForm/StepperForm";
import ProgressList from "../progressList/ProgressList";
import ScheduleGroupList from "../shedulegrouplist/ScheduleGroupList";
import HierarchyTable from "../hierarchyTable/HierarchyTable";
import { v4 as uuId } from "uuid";
import PrintableView from "../printableView/PrintableView";
import DynamicTable from "../DynamicTable/DynamicTable";

import ListView from "../listView/ListView";
import ViewPageComponent from "../viewPage/ViewPageComponent";
import GoogleMapComponent from "../GoogleMap/GoogleMapComponent";
import StackedBarGraph from "../../Echarts/StackedBarGraph";
import StackedLineGraph from "../../Echarts/StackedLineGraph";
import StackedHorizontalBarGraph from "../../Echarts/StackedHorizontalBarGraph";
import BarWithNegative from "../../Echarts/BarWithNegative";
import PolarChart from "../../Echarts/PolarChart";
import BasicEcharts from "../../Echarts/BasicEcharts";
import LogAxis from "../../Echarts/LogAxis";
import LineStyle from "../../Echarts/LineStyle";

const DisplayComponent = ({ appSessionId, displayComponentData, props }) => {
  const [submitFormPayload, setSubmitFormPayload] = useState({});
  const [filterApply, setFilterApply] = useState("");
  const objData = displayComponentData?.data;
  const displayFieldArray = objData?.page?.display_fields;
  const [reloadComponents, setReloadComponents] = useState([]);
  const [tempStorageCall, setTempStorageCall] = useState(false);

  useMemo(() => {
    const ref = objData?.page?.display_fields
      .filter(
        (data) =>
          ![
            displayComponentType.filterForm,
            displayComponentType.customSection,
          ]?.includes(data.componentType)
      )
      .map((data) => {
        return {
          refValue: data.refValue,
        };
      });

    let formPayload = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      query: objData?.data?.page?.generated_query || "",
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      page_type: objData?.page?.page_type,
      flow_id: objData?.flow_id,
      data: {
        formData: [
          { user_id: parseInt(JSON.parse(localStorage.getItem("userid"))) },
        ],
        logic: objData?.logic || [],
        logic_to_send: objData?.logic_to_send || [],
        page_fields: objData?.page?.page_fields,
      },
      app_session_id: appSessionId,
      store_output_key: objData?.page?.data?.form_output_key || [],
      referenceValue: ref,
    };

    setSubmitFormPayload(formPayload);
  }, []);

  const filterFormObj = displayFieldArray?.find(
    (disField) => disField?.componentType === displayComponentType.filterForm
  );

  const stepperfilterFormObj = displayFieldArray?.find(
    (disField) => disField?.componentType === displayComponentType.stepperForm
  );

  let isFilterFormOnTop = true;
  let isFilterFormOnRight = false;
  let isFilterFormOnLeft = false;

  if (filterFormObj) {
    isFilterFormOnTop =
      filterFormObj?.formPosition === "top" || !filterFormObj?.formPosition;
    isFilterFormOnLeft = filterFormObj?.formPosition === "left";
    isFilterFormOnRight = filterFormObj?.formPosition === "right";
  }

  const clearFilterApply = () => {
    setFilterApply("");
  };

  return (
    <div
      className={`col-12 ${isFilterFormOnTop ? "" : "filterFormContainer"} p-0`}
    >
      {filterFormObj && (
        <div
          className={`${isFilterFormOnLeft ? "col-sm-2 me-2" : ""} ${
            isFilterFormOnRight ? "col-sm-2 ms-2" : ""
          }`}
        >
          <div
            className={`mb-2 ${
              isFilterFormOnTop ? "col-12" : "filterFormHeight"
            }`}
          >
            <FilterForm
              formFields={filterFormObj}
              isFilterFormOnTop={isFilterFormOnTop}
              objData={objData}
              appSessionId={appSessionId}
              filterFormExecution={() => {
                setFilterApply(uuId());
              }}
            />
          </div>
        </div>
      )}

      <div
        className={`m-0 ${
          isFilterFormOnTop ? "row p-0" : "col-md-10 p-0 row overflowYAuto"
        } ${isFilterFormOnRight ? "rightFilterForm" : ""} ${
          isFilterFormOnLeft ? "leftFilterForm" : ""
        }`}
      >
        <div className={"col-md-12"}>
          <div className="row">
            {displayFieldArray?.map((displayField) => {
              const { componentType, component_id } = displayField;
              if (componentType === displayComponentType.graph) {
                return (
                  <Charts
                    displayData={displayField}
                    key={component_id}
                    objData={objData}
                    appSessionId={appSessionId}
                    filterApply={filterApply}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }
              if (componentType === displayComponentType.echart) {
                if (displayField?.echartType?.value === "stackedLine") {
                  return (
                    <StackedLineGraph
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                } else if (displayField?.echartType?.label === "Polar") {
                  return (
                    <PolarChart
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                } else if (displayField?.echartType?.label === "Stacked Bar") {
                  return (
                    <StackedBarGraph
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                } else if (
                  displayField?.echartType?.label === "Stacked Horizontal Bar"
                ) {
                  return (
                    <StackedHorizontalBarGraph
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                } else if (
                  displayField?.echartType?.label ===
                  "Horizontal Bar with Negative"
                ) {
                  return (
                    <BarWithNegative
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                } else if (displayField?.echartType?.value === "logAxis") {
                  return (
                    <LogAxis
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                } else if (displayField?.echartType?.value === "lineStyle") {
                  return (
                    <LineStyle
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                } else {
                  return (
                    <BasicEcharts
                      displayData={displayField}
                      key={component_id}
                      objData={objData}
                      appSessionId={appSessionId}
                      filterApply={filterApply}
                      clearFilterApply={clearFilterApply}
                      tempStorageCall={tempStorageCall}
                      setTempStorageCall={setTempStorageCall}
                    />
                  );
                }
              }
              if (componentType === displayComponentType.table) {
                return (
                  <SubTable
                    appSessionId={appSessionId}
                    key={component_id}
                    objData={objData}
                    filterApply={filterApply}
                    displayData={displayField}
                    reloadComponents={reloadComponents}
                    setReloadComponents={setReloadComponents}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.listView) {
                return (
                  <ListView
                    appSessionId={appSessionId}
                    key={component_id}
                    objData={objData}
                    filterApply={filterApply}
                    displayData={displayField}
                    reloadComponents={reloadComponents}
                    setReloadComponents={setReloadComponents}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.customSection) {
                return (
                  <CustomSection
                    key={component_id}
                    htmlCode={displayField.htmlCode}
                    columenWidth={displayField.columenWidth}
                  />
                );
              }

              if (componentType === displayComponentType.cards) {
                return (
                  <CardsRender
                    displayField={displayField}
                    appSessionId={appSessionId}
                    key={component_id}
                    filterApply={filterApply}
                    reloadComponents={reloadComponents}
                    objData={objData}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.calendar) {
                return (
                  <BigCalender
                    key={component_id}
                    displayData={displayField}
                    calenderData={[]}
                    submitFormFromDisplayComponent={
                      props?.submitFormFromDisplayComponent
                    }
                    componentDataLength={props?.componentData.length}
                  />
                );
              }

              if (componentType === displayComponentType.summary) {
                let pageFields = displayField?.page_field?.length
                  ? displayField?.page_field
                  : [];
                return (
                  <SummaryCard
                    key={component_id}
                    summaryPageFields={pageFields}
                    appSessionId={appSessionId}
                    displayData={displayField}
                    objData={objData}
                    filterApply={filterApply}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.scheduleList) {
                return (
                  <ScheduleListView
                    displayData={displayField}
                    objData={objData}
                    key={component_id}
                    appSessionId={appSessionId}
                    filterApply={filterApply}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.stepperForm) {
                return (
                  <StepperForm
                    key={component_id}
                    displayData={displayField}
                    objData={objData}
                    appSessionId={appSessionId}
                    submitFormFromDisplayComponent={
                      props?.submitFormFromDisplayComponent
                    }
                    submitFormPayload={submitFormPayload}
                  />
                );
              }

              if (componentType === displayComponentType.kanban) {
                return (
                  <Kanban
                    key={component_id}
                    displayData={displayField}
                    objData={objData}
                    appSessionId={appSessionId}
                    submitFormFromDisplayComponent={
                      props?.submitFormFromDisplayComponent
                    }
                    submitFormPayload={submitFormPayload}
                  />
                );
              }

              if (componentType === displayComponentType.progressList) {
                return (
                  <ProgressList
                    key={component_id}
                    displayData={displayField}
                    appSessionId={appSessionId}
                    filterApply={filterApply}
                    objData={objData}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }
              if (componentType === displayComponentType.scheduleGroupList) {
                return (
                  <ScheduleGroupList
                    key={component_id}
                    displayField={displayField}
                    appSessionId={appSessionId}
                  />
                );
              }

              if (componentType === displayComponentType.hierarchyTable) {
                return (
                  <HierarchyTable
                    objData={objData}
                    filterApply={filterApply}
                    key={component_id}
                    displayField={displayField}
                    appSessionId={appSessionId}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.printableView) {
                return (
                  <PrintableView
                    key={component_id}
                    filterApply={filterApply}
                    template={displayField.printTemplate}
                    columnWidth={displayField.columenWidth}
                    displayData={displayField}
                    appSessionId={appSessionId}
                    objData={objData}
                    clearFilterApply={clearFilterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.dynamicTable) {
                return (
                  <DynamicTable
                    key={component_id}
                    displayData={displayField}
                    appSessionId={appSessionId}
                    objData={objData}
                    reloadComponents={reloadComponents}
                    setReloadComponents={setReloadComponents}
                    clearFilterApply={clearFilterApply}
                    filterApply={filterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.viewPage) {
                return (
                  <ViewPageComponent
                    key={component_id}
                    columnWidth={displayField.columenWidthTable}
                    displayData={displayField}
                    appSessionId={appSessionId}
                    objData={objData}
                    reloadComponents={reloadComponents}
                    setReloadComponents={setReloadComponents}
                    clearFilterApply={clearFilterApply}
                    filterApply={filterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }

              if (componentType === displayComponentType.googleMap) {
                return (
                  <GoogleMapComponent
                    key={component_id}
                    columnWidth={displayField.columenWidth}
                    displayData={displayField}
                    appSessionId={appSessionId}
                    objData={objData}
                    reloadComponents={reloadComponents}
                    setReloadComponents={setReloadComponents}
                    clearFilterApply={clearFilterApply}
                    filterApply={filterApply}
                    tempStorageCall={tempStorageCall}
                    setTempStorageCall={setTempStorageCall}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayComponent;
