import React, { Component } from "react";
import { toast } from "react-toastify";
import { createNewApp } from "../../../../services/appService";
import { getRecordDataById } from "../../../../services/tableConfigService";
import { getLogicsByAppId } from "../../../../services/appService";
import ReactFileReader from "react-file-reader";
import { data } from "jquery";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class AppDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {
        name: "",
        description: "",
        version: "",
        selectedOption: { label: "", value: "" },
        app_group_id: "",
      },
      showAppInMenu: true,
      fileImage: "",
      errors: {},
      appId: "",
      appLogicArr: [],
      UserAdmin: JSON.parse(localStorage.getItem("role")),
    };
  }

  componentWillMount() {
    if (this.props.sendUrlValue.path === "/create_app/:app_id") {
      this.setState(
        {
          appId: this.props.sendUrlValue.params.app_id,
        },
        () => {
          this.getAppData();
          this.getAppLogicData();
        }
      );
    }
  }

  getAppLogicData() {
    getLogicsByAppId(this.state.appId)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            appLogicArr: response.data.map((actionName) => {
              return {
                label: actionName.function_name,
                value: actionName?._id?.$oid,
              };
            }),
          });
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  ifNull(data){
    if(!data || data == null || data.length === 0 || data === "undefined"){
      return "";
    }
    return data;
  }

  getAppData() {
    var obj = { id: this.state.appId, dataset_name: "sys_apps" };
    getRecordDataById(obj)
      .then((response) => {
        if (response.success === true && data.length > 0) {
          this.setState({
            file: response.data.data_file,
            field: {
              name: this.ifNull(response.data.app_name),
              description: this.ifNull(response.data.app_description),
              version: this.ifNull(response.data.app_version),
              logo: response.data.data_file,
              app_group_id: this.ifNull(response.data.app_group_id),
              selectedOption: {
                label: response?.data?.badge_logic_name && response?.data?.badge_logic_name !== "undefined" ? response?.data?.badge_logic_name : "",
                value: response?.data?.badge_logic_id && response?.data?.badge_logic_id !== "undefined" ? response?.data?.badge_logic_id : "",
              },
            },
            showAppInMenu:
              response.data.show_app_in_menu === "true" ? true : false,
          });
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  // store form data
  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }

  handleChange = (selectedOption) => {
    this.setState({ field: { ...this.state.field, selectedOption } });
  };

  // validate form date
  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter name.";
    }
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "*Please enter description";
    }
    if (!fields["version"]) {
      formIsValid = false;
      errors["version"] = "*Please enter version";
    }

    if (!this.state?.file) {
      formIsValid = false;
      errors["logo"] = "*Please upload a logo";
    }

    if (this.state.fileImage.size >= 1048576) {
      formIsValid = false;
      errors["logo"] = "*Logo size must be less than 1MB.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  // get files from button
  handleFiles = (files) => {
    this.setState({
      fileImage: files[0],
      file: URL.createObjectURL(files[0]),
    });
  };

  submitAddDetails(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      const formData = new FormData();
      if (this.state.file) {
        if (this.state.fileImage) {
          formData.append("file", this.state.fileImage);
        }
      }
      formData.append("app_name", this.state.field.name);
      formData.append("app_version", this.state.field.version);
      formData.append("app_description", this.state.field.description);
      formData.append("app_group_id", this.state.field.app_group_id);
      formData.append("badge_logic_id", this.state.field.selectedOption.value);
      formData.append(
        "badge_logic_name",
        this.state.field.selectedOption.label
      );
      formData.append(
        "dataset_object_id",
        this.state.appId === "" ? "" : this.state.appId
      );
      formData.append("show_app_in_menu", this.state.showAppInMenu);
      createNewApp(formData)
        .then((response) => {
          if (response.success === true) {
            this.setState(
              {
                appId: response.app_id,
              },
              () => {
                toast.success("App Created Successfully");
                this.props.returnAppIdName(
                  response.app_id,
                  this.state.field.name
                );
              }
            );
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }

  handleShowAppInMenuOrNot = () => {
    this.setState({
      showAppInMenu: !this.state.showAppInMenu,
    });
  };

  render() {
    return (
      <div className="create-app">
        <div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              App Name
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="name"
                value={this.state.field.name}
                onChange={(e) => {
                  this.changeValue(e);
                }}
              />
              <div className="errorMsg text-danger">
                {this.state.errors.name}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              App Description
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="description"
                value={this.state.field.description}
                onChange={(e) => {
                  this.changeValue(e);
                }}
              />
              <div className="errorMsg text-danger">
                {this.state.errors.description}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              App Version
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Version"
                name="version"
                value={this.state.field.version}
                min={0}
                onChange={(e) => {
                  this.changeValue(e);
                }}
              />
              <div className="errorMsg text-danger">
                {this.state.errors.version}
              </div>
            </div>
          </div>
          {this.state.UserAdmin === "Admin" ? (
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3">
                App Group ID
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter App Group ID"
                  name="app_group_id"
                  value={this.state.field.app_group_id || ""}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
              </div>
            </div>
          ) : null}
          {window.location.pathname !== "/create-app" ? (
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3">
                Select logic for badge
              </label>
              <div className="col-md-9 col-sm-9">
                <Select
                  options={this.state.appLogicArr}
                  value={this.state.field.selectedOption}
                  onChange={this.handleChange}
                  isClearable
                  menuPosition="fixed"
                  classNames={{
                    control: (state) => 'searchableDropdownControl',
                    menuPortal: () => 'searchableDropdownMenuPortal',
                    singleValue: () => 'searchableDropdownValue',
                    multiValue: () => 'searchableDropdownValue',
                    menuList: () => 'searchableDropdownMenuList'
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              App Logo
            </label>
            <div className="col-md-9 col-sm-9 col-9">
              <ReactFileReader
                // fileTypes={[".jpg", ".png", ""]}
                handleFiles={this.handleFiles}
                multipleFiles={false}
              >
                <button className="chooseFile-custom-btn">
                  <span className="me-2">Upload Logo</span>
                  <FontAwesomeIcon icon="fa-solid fa-upload" />
                </button>
                {this.state.file ? (
                  <span>
                    <img
                      src={this.state.file}
                      alt="logo-img"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginLeft: "20px",
                      }}
                    />
                  </span>
                ) : null}
              </ReactFileReader>
              <div className="errorMsg text-danger">
                {this.state.errors.logo}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">
              Show App in Menu?
            </label>
            <div className="col-md-9 col-sm-9 col-6 px-1">
              <FontAwesomeIcon
                className={` togglebtn pointer toggleFormBtnSize ${
                  this.state.showAppInMenu ? "text-success" : "text-danger"
                }`}
                icon={
                  this.state.showAppInMenu
                    ? "fa fa-toggle-on"
                    : "fa fa-toggle-off"
                }
                onClick={this.handleShowAppInMenuOrNot}
              />
            </div>
          </div>
          <div className="text-end">
            <button
              onClick={(event) => this.submitAddDetails(event)}
              type="submit"
              className="custom-btn m-0"
            >&nbsp;&nbsp;
              Next&nbsp;&nbsp;
              <FontAwesomeIcon icon="fa fa-arrow-right" className="ms-1" />&nbsp;&nbsp;
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AppDetails;
