import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

const AddFormGroup = ({ fieldsList, funtionToCall, sectionInFormData }) => {
  const [groupList, setGroupList] = useState(sectionInFormData);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isOptionsAvailable, setIsOptionsAvailable] = useState(false);

  useEffect(() => {
    if (groupList?.length) {
      funtionToCall(groupList);
    }
  }, [groupList]);

  const options = fieldsList?.map((item) => {
    return { label: item.display_name, value: item.name };
  });

  const deleteField = (filterId, index) => {
    const newList = groupList.filter((item) => item.id !== filterId);
    clearGroupFields(filterId);
    setGroupList(newList);
  };

  const clearGroupFields = (groupId) => {
    let change = [...groupList];
    const groupIndex = change.findIndex((group) => group.id === groupId);
    if (groupIndex > -1) {
      change[groupIndex].groupFields = [];
      change[groupIndex].groupName = "";
      setGroupList(change);
    }
  };

  const groupFieldsChanges = (groupId, value, key) => {
    let change = [...groupList];
    const groupIndex = change.findIndex((group) => group.id === groupId);
    if (groupIndex > -1) {
      change[groupIndex][key] = value;
      setGroupList(change);
    }
  };

  const handleNewField = () => {
    const groupId = uuidv4();

    const updatedOptions = updateOptions(groupId);
    if (updatedOptions?.length === 0) {
      toast.error("No fields remain");
      return;
    }
    setGroupList([
      ...groupList,
      { id: groupId, groupName: "", groupFields: [] },
    ]);
    setSelectedOptions((prevOptions) => ({ ...prevOptions, [groupId]: [] }));
  };

  const updateOptions = (groupId) => {
    let updatedOptions = [...options];

    const otherGroupFields = groupList
      ?.filter((group) => group.id !== groupId)
      .map((group) => group.groupFields)
      .flat()
      .map((field) => field.value);

    updatedOptions = updatedOptions?.filter(
      (option) => !otherGroupFields?.includes(option.value)
    );

    return updatedOptions;
  };

  const handleGroupFieldChange = (groupId, value) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [groupId]: value.map((option) => option.value),
    }));
    groupFieldsChanges(groupId, value, "groupFields");
  };
  return (
    <div className="bg-white border p-2 mb-3">
      <div className="d-flex justify-content-between mb-2">
        <h5>Form group</h5>
        <button
          className={`add-btn ms-2 ${
            isOptionsAvailable && "cursor-not-allowed"
          }`}
          onClick={() => {
            handleNewField();
          }}
          disabled={isOptionsAvailable}
        >
          {" "}
          Add New Group
        </button>
      </div>
      {groupList?.length > 0 ? (
        groupList?.map((group, index) => {
          return (
            <div
              className="d-flex justify-content-center align-items-center border p-2 mb-2 formFieldBg"
              key={group.id}
            >
              <div className="row flex-grow-1">
                <div className="col-md-4 col-sm-12 mb-2">
                  <label className="mb-1">Group {index + 1}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="groupName"
                    placeholder="Enter Group Name"
                    required
                    value={group?.groupName}
                    onChange={(e) => {
                      groupFieldsChanges(
                        group.id,
                        e.target.value || "",
                        "groupName"
                      );
                    }}
                  />
                </div>
                <div className="col-md-8 col-sm-12 mb-2">
                  <label className="mb-1">Group Fields</label>
                  <Select
                    closeMenuOnSelect={false}
                    menuPosition="fixed"
                    classNames={{
                      control: (state) => 'searchableDropdownControl',
                      menuPortal: () => 'searchableDropdownMenuPortal',
                      singleValue: () => 'searchableDropdownValue',
                      multiValue: () => 'searchableDropdownValue',
                      menuList: () => 'searchableDropdownMenuList'
                    }}
                    placeholder="Select.."
                    options={updateOptions(selectedOptions)}
                    value={group.groupFields}
                    onChange={(e) => {
                      handleGroupFieldChange(group.id, e || []);
                    }}
                    isClearable
                    isMulti
                  />
                </div>
              </div>
              <div className="p-2">
                <FontAwesomeIcon
                  icon={"fa fa-minus-circle"}
                  className="text-danger ms-lg-3 ms-md-3 ms-2 copy-div cursor-pointer"
                  aria-hidden="true"
                  onClick={() => deleteField(group.id, index)}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="row">
          <p className="col-md-12 text-center">No groups created yet</p>
        </div>
      )}
    </div>
  );
};

export default AddFormGroup;
