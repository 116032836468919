import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const DisplayBuilderPrintableView = ({
  logicList,
  displayBuilder,
  columnWidthList,
  onChange,
  updatedexecuteAppAction,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);

  const [updatedFields, setUpdatedFields] = useState({
    updatedRefValue: displayBuilder?.updatedFields?.updatedRefValue || "",
    updatedComponentLogic: displayBuilder?.updatedFields?.updatedComponentLogic || "",
  });

  useEffect(() => {
    setDisplayChanges(displayBuilder);
  }, [displayBuilder]);

  useEffect(() => {
    onChange({
      ...displayChanges,
      updatedFields: {
        ...updatedFields,
        printTemplate: displayChanges?.printTemplate,
      },
    });
  }, [updatedFields]);

  const changeValue = (e) => {
    const { name, value } = e.target;
    const updatedChanges = { ...displayChanges, [name]: value };
    setDisplayChanges(updatedChanges);
  };

  const editonChangeHandler = (e) => {
    const updatedChanges = { ...displayChanges, printTemplate: e.target.value };
    setDisplayChanges(updatedChanges);
    onChange({
      ...updatedChanges,
      updatedFields: {
        ...updatedFields,
        printTemplate: updatedChanges.printTemplate,
      },
    });
  };

  const searchableValueChange = (event, key) => {
    const value = event?.label || "";
    const updatedChanges = { ...displayChanges, [key]: value };
    setDisplayChanges(updatedChanges);
  };

  const handleUpdatedFieldChange = (key, value) => {
    const updatedFieldsData = { ...updatedFields, [key]: value };
    setUpdatedFields(updatedFieldsData);
    onChange({
      ...displayChanges,
      updatedFields: {
        ...updatedFieldsData,
        printTemplate: displayChanges?.printTemplate,
      },
    });
  };

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columenWidth}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columenWidth"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      {/* Updated Logic Variable Name */}
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Update Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder={updatedFields?.updatedRefValue || "Update Logic Variable Name"}
            name="updatedRefValue"
            value={updatedFields?.updatedRefValue || ""}
            onChange={(e) =>
              handleUpdatedFieldChange("updatedRefValue", e.target.value)
            }
          />
        </div>
      </div>

      {/* Updated Component Logic */}
      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Update Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder={updatedFields?.updatedComponentLogic || "Select Update Logic"}
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === updatedFields?.updatedComponentLogic
            )}
            onChange={(e) =>
              handleUpdatedFieldChange("updatedComponentLogic", e?.label || "")
            }
            isClearable
          />
        </div>
      </div>

      <div className="col-md-12 mt-2">
        <div className="form-group">
          <h6 htmlFor="printTemplate">Printable View</h6>
          <small>
            * For template design help check out this link{" "}
            <a href="https://handlebarsjs.com/" target="_blank">
              https://handlebarsjs.com/
            </a>{" "}
          </small>
          <textarea
            id="printTemplate"
            name="printTemplate"
            className="form-control"
            rows="15"
            value={displayChanges?.printTemplate}
            onChange={editonChangeHandler}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderPrintableView;