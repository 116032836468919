//Form fields data types list
export const inputTypeKey = {
  textBox: "string",
  searchableDropdown: "searchable_dropdown",
  dynamicDependentDropdown: "dynamic_dependent_dropdown",
  inlineTableForm: "inlineTableForm",
  largeText: "largetext",
  checkBox: "boolean", // for checkBox field
  radioButton: "radio_button",
  singleSelectStaticDropdownlist: "string[]", // for single Select static dropdown
  numberTextBox: "number",
  dateTime: "dateTime",
  time: "time",
  fileUpload: "file",
  lineItems: "lineItems",
  customSection: "custom_section",
  toggleButton: "toggle_button",
  checkList: "checkList",
  calculatedField: "calculatedField",
  hyperlink: "hyperlink"
};

export const fieldDataTyeList = [
  { name: "Checkbox", value: inputTypeKey.checkBox },
  { name: "Custom Section", value: inputTypeKey.customSection },
  { name: "Check List", value: inputTypeKey.checkList },
  { name: "Date", value: inputTypeKey.dateTime },
  {
    name: "Dynamic Dependent Dropdown",
    value: inputTypeKey.dynamicDependentDropdown,
  },
  { name: "File Upload", value: inputTypeKey.fileUpload },
  { name: "InLine Table Form", value: inputTypeKey.inlineTableForm },
  //  { name: "Line Items", value: inputTypeKey.lineItems },
  { name: "Number TextBox", value: inputTypeKey.numberTextBox },
  { name: "Radio Button", value: inputTypeKey.radioButton },
  { name: "Searchable Dropdown", value: inputTypeKey.searchableDropdown },
  {
    name: "Single Select List",
    value: inputTypeKey.singleSelectStaticDropdownlist,
  },
  { name: "TextArea", value: inputTypeKey.largeText },
  { name: "TextBox", value: inputTypeKey.textBox },
  { name: "Time", value: inputTypeKey.time },
  { name: "Toggle Button", value: inputTypeKey.toggleButton },
  { name: "Calculated Field", value: inputTypeKey.calculatedField },
  { name: "File Link", value: inputTypeKey.hyperlink },
];

//Component type list in page configuration
export const componentType = {
  displayComponent: "display_component",
  formComponent: "form_component",
  storeComponent: "store_component",
};

export const componentTypeList = [
  {
    label: "Display Component",
    value: componentType.displayComponent,
  },
  {
    label: "Form Component",
    value: componentType.formComponent,
  },
  {
    label: "Store Component",
    value: componentType.storeComponent,
  },
];

// Display Transition type list
export const displayTransition = {
  goToPage: "go_to_page",
  goToAnotherApp: "go_to_another_app",
};

// all component transition type list and table linking
export const transition = {
  anotherPage: "anotherPage",
  anotherApp: "anotherApp",
  popUp: "popUp",
  sideBar: "sideBar",
};

export const transitionList = [
  { name: "Another Page", value: transition.anotherPage },
  { name: "Another App", value: transition.anotherApp },
  { name: "Pop Up", value: transition.popUp },
  { name: "Sidebar", value: transition.sideBar },
];

// Action button type list for Table
export const actionTypes = {
  popUp: "Pop Up",
  button: "Button",
  fileUpload: "File Upload",
  downloadXml: "Download XML",
  downloadLink: "Download Link",
  addForm: "Add Form",
  export: "Export",
  import: "Import",
  importDataset: "Import via Dataset",
  link: "Link",
  timeLineView: "TimeLineView",
};

export const actionTypesList = [
  {
    label: actionTypes.popUp,
    value: actionTypes.popUp,
  },
  {
    label: actionTypes.button,
    value: actionTypes.button,
  },
  {
    label: actionTypes.fileUpload,
    value: actionTypes.fileUpload,
  },
  {
    label: actionTypes.downloadXml,
    value: actionTypes.downloadXml,
  },
  {
    label: actionTypes.downloadLink,
    value: actionTypes.downloadLink,
  },
  {
    label: actionTypes.addForm,
    value: actionTypes.addForm,
  },
  {
    label: actionTypes.export,
    value: actionTypes.export,
  },
  {
    label: actionTypes.import,
    value: actionTypes.import,
  },
  {
    label: actionTypes.importDataset,
    value: actionTypes.importDataset,
  },
  {
    label: actionTypes.link,
    value: actionTypes.link,
  },
  {
    label: "Time Line View",
    value: actionTypes.timeLineView,
  },
];

// Display type list for display component
export const displayComponentType = {
  table: "Table",
  listView: "List View",
  graph: "Graph",
  echart: "Echart",
  customSection: "Custom Section",
  cards: "Cards",
  calendar: "Calendar",
  summary: "Summary",
  filterForm: "Filter Form",
  header: "Header",
  scheduleList: "Schedule List",
  kanban: "Kanban",
  progressList: "progressList",
  scheduleGroupList: "scheduleGroupList",
  hierarchyTable: "hierarchyTable",
  printableView: "printableView",
  dynamicTable: "dynamicTable",
  viewPage: "ViewPage",
  googleMap: "Google Map",
  stepperForm: "Stepper Form",
};

export const displayComponentTypeList = [
  // {
  //   label: displayComponentType.graph,
  //   value: displayComponentType.graph,
  // },
  {
    label: displayComponentType.table,
    value: displayComponentType.table,
  },
  {
    label: displayComponentType.echart,
    value: displayComponentType.echart,
  },
  {
    label: displayComponentType.listView,
    value: displayComponentType.listView,
  },
  {
    label: displayComponentType.header,
    value: displayComponentType.header,
  },
  {
    label: displayComponentType.customSection,
    value: displayComponentType.customSection,
  },
  {
    label: displayComponentType.cards,
    value: displayComponentType.cards,
  },
  {
    label: displayComponentType.summary,
    value: displayComponentType.summary,
  },
  {
    label: displayComponentType.calendar,
    value: displayComponentType.calendar,
  },
  {
    label: displayComponentType.filterForm,
    value: displayComponentType.filterForm,
  },
  {
    label: displayComponentType.scheduleList,
    value: displayComponentType.scheduleList,
  },
  {
    label: displayComponentType.kanban,
    value: displayComponentType.kanban,
  },
  {
    label: "Progress List",
    value: displayComponentType.progressList,
  },
  {
    label: "Schedule Group List",
    value: displayComponentType.scheduleGroupList,
  },
  {
    label: "Hierarchy Table",
    value: displayComponentType.hierarchyTable,
  },
  {
    label: "Printable View",
    value: displayComponentType.printableView,
  },
  {
    label: "Dynamic Table",
    value: displayComponentType.dynamicTable,
  },
  {
    label: "ViewPage",
    value: displayComponentType.viewPage,
  },
  {
    label: "Google Map",
    value: displayComponentType.googleMap,
  },
  {
    label: "Stepper Form",
    value: displayComponentType.stepperForm,
  },
];

//file type list for user selection
export const fileType = {
  csv: ".csv",
  xls: ".xls",
  xlsx: ".xlsx",
};

export const fileTypeList = [
  {
    label: "CSV",
    value: fileType.csv,
  },
  {
    label: "XLS",
    value: fileType.xls,
  },
  {
    label: "XLSX",
    value: fileType.xlsx,
  },
];

// display format for number data types
export const numberDisplayFormat = {
  india: "en-IN",
};

export const displayFormatList = [
  {
    label: "India",
    value: numberDisplayFormat.india,
  },
];

// value conversion for textBox when user change
export const conversionType = {
  upperCase: "upperCase",
  lowerCase: "lowerCase",
};

export const conversionTypeList = [
  {
    label: "Upper Case",
    value: conversionType.upperCase,
  },
  {
    label: "Lower Case",
    value: conversionType.lowerCase,
  },
];

export const autoReloadComponentDelaysList = [
  { "label": "5 sec", "value": 5000 },
  { "label": "10 sec", "value": 10000 },
  { "label": "15 sec", "value": 15000 },
  { "label": "20 sec", "value": 20000 },
  { "label": "25 sec", "value": 25000 },
  { "label": "30 sec", "value": 30000 },
  { "label": "35 sec", "value": 35000 },
  { "label": "40 sec", "value": 40000 },
  { "label": "45 sec", "value": 45000 },
  { "label": "50 sec", "value": 50000 },
  { "label": "55 sec", "value": 55000 },
  { "label": "60 sec", "value": 60000 },
  { "label": "65 sec", "value": 65000 },
  { "label": "70 sec", "value": 70000 },
  { "label": "75 sec", "value": 75000 },
  { "label": "80 sec", "value": 80000 },
  { "label": "85 sec", "value": 85000 },
  { "label": "90 sec", "value": 90000 },
  { "label": "95 sec", "value": 95000 },
  { "label": "100 sec", "value": 100000 },
  { "label": "105 sec", "value": 105000 },
  { "label": "110 sec", "value": 110000 },
  { "label": "115 sec", "value": 115000 },
  { "label": "120 sec", "value": 120000 },
  { "label": "125 sec", "value": 125000 },
  { "label": "130 sec", "value": 130000 },
  { "label": "135 sec", "value": 135000 },
  { "label": "140 sec", "value": 140000 },
  { "label": "145 sec", "value": 145000 },
  { "label": "150 sec", "value": 150000 },
  { "label": "155 sec", "value": 155000 },
  { "label": "160 sec", "value": 160000 },
  { "label": "165 sec", "value": 165000 },
  { "label": "170 sec", "value": 170000 },
  { "label": "175 sec", "value": 175000 },
  { "label": "180 sec", "value": 180000 }
]
