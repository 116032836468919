import React, { Component } from "react";
import { toast } from "react-toastify";
import { fetchAllDataSets } from "../../services/datasetService";
import {
  deletePrivilegeData,
  fetchInstallAppWithPages,
  getPrivilegeData,
  updatePrivileges,
} from "../../services/appService";
import { Modal, Button, Form } from "react-bootstrap";
import { createPrivilege } from "../../services/appService";
import Pagination from "react-js-pagination";
import Loader from "../dataset/Loader/Loader";
import StorePrivileges from "./StorePrivileges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CustomizePrivileges";
import CustomModal from "../application/customModal/CustomModal";
import Select from "react-select";
import * as _ from "lodash";

export class CustomizePrivileges extends Component {
  constructor() {
    super();
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      isWrite: false,
      getRolesHeader: ["Id", "Role", "Actions"],
      getCustomPrivilegeTableHeader: ["", "Read", "Write", "Select Pages"],
      allCustomAppPrivileges: [],
      allCustomDataPrivileges: [],
      allCustomStorePrivileges: [],
      appTypeCustomPrivilegeData: [],
      dataTypeCustomPrivilegeData: [],
      getRolesData: [
        {
          role: "Admin",
        },
        {
          role: "User",
        },
      ],
      field: {
        privilegeName: "",
        priviledgeTypeName: "App Privileges",
      },

      errors: {},
      getDataSetRecord: [],
      getAllApps: [],
      errorMsg: "",
      privilegeType: ["App Privileges", "Data Privileges", "Store Privilege"],
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      currentPage: 1,
      dataPerPage: 10,
      privilegeTableData: [],
      totalDataCount: 0,
      showDeleteModal: false,
      editState: false,
      objectId: "",
      isLoading: true,
      subTableIndex: -1,
      subTableIndexToggle: false,
      selectedOption: "",
      selectedOptionName: "",
      selectedPageIndex: -1,
      storePrivilegeData: {
        manage_all_product: { read: [], write: [] },
        manage_own_product: { read: [], write: [] },
      },
      isPrivilegeChanging: false,
      deleteLoader: false,
      savePrivilegeLoader: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchAllData();
    this.getAppsData();
    this.getPrivilages();
    this.getPrivilegeTableData();
  }

  getPrivilegeTableData = () => {
    this.setState({ isLoading: true });
    getPrivilegeData()
      .then((response) => {
        if (response.success === true) {
          this.setState({
            privilegeTableData: this.customPrivilege(response.data),
            totalDataCount: this.customPrivilege(response.data).length,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true, isLoading: false });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.getAllApps !== this.state.getAllApps ||
      prevState.getDataSetRecord !== this.state.getDataSetRecord
    ) {
      this.setCustomPrivilegeData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["Privileges"]) {
          this.setState({ isWrite: data["Privileges"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true });
    }
  };
  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["privilegeName"]) {
      formIsValid = false;
      errors["privilegeName"] = "*Please enter Privilege Name.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;

    if (
      event.target.value !== "Store Privilege" &&
      event.target.name !== "privilegeName"
    ) {
      this.setState({
        isPrivilegeChanging: true,
      });
    }

    if (event.target.value === "Data Privileges") {
      this.setState({
        fields,
        subTableIndexToggle: false,
      });
    } else {
      this.setState({
        fields,
        subTableIndexToggle: true,
      });
    }
    if (
      this.state.appTypeCustomPrivilegeData.length === 0 ||
      this.state.dataTypeCustomPrivilegeData.length === 0
    ) {
      this.setCustomPrivilegeData();
    }
  }

  getAppsWithPages(appsdata) {
    let appsDataWithOptions = appsdata.map((appdata, index) => {
      let formComponent = [];
      let displayComponent = [];
      let options = appdata.page_list.map((displayPage) => {
        if (displayPage.page_type === "display_component") {
          let insertData = true;
          displayPage.display_fields
            .map((display_field) => {
              if (insertData) {
                insertData = false;
                displayComponent.push({
                  label: displayPage.function_name,
                  value: displayPage.function_name.toLowerCase(),
                });
              }
            });
        } else {
          formComponent.push({
            label: displayPage.function_name,
            value: displayPage.function_name.toLowerCase(),
          });
        }
        return {
          label: displayPage.function_name,
          value: displayPage.function_name.toLowerCase(),
        };
      });

      return {
        ...appdata,
        pageOptions: displayComponent,
      };
    });
    return appsDataWithOptions;
  }

  getAppsData() {
    fetchInstallAppWithPages()
      .then((response) => {
        if (response.success === true && this.mounted) {
          this.setState({
            getAllApps: this.getAppsWithPages(response.data),
          });
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true });
      });
  }

  fetchAllData() {
    let obj = {
      SortCriteria: [
        {
          sort_field: "name",
          sort_type: "asc",
        },
      ],
    };
    fetchAllDataSets(obj)
      .then((response) => {
        if (response.success === true && this.mounted) {
          this.setState({
            getDataSetRecord: response.data,
          });
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true });
      });
  }

  submitPrivileges(event) {
    event.preventDefault();
    this.setState({
      savePrivilegeLoader: true,
    });
    if (this.validateFieldForm()) {
      if (this.state.editState) {
        let customPrivileges = {
          tenant_id: this.state.tenant_Id,
          is_primary: true,
          fields: {
            privilegeName: this.state.field.privilegeName,
            privilegeType: "custom_privileges",
            app_previleges: this.state.appTypeCustomPrivilegeData,
            data_previleges: this.state.dataTypeCustomPrivilegeData,
            store_previleges: this.state.storePrivilegeData,
          },
          object_id: this.state.objectId,
        };

        updatePrivileges(customPrivileges)
          .then((response) => {
            if (response.success === true) {
              toast.success(response.message);
              this.setState({
                field: {
                  privilegeName: "",
                  priviledgeTypeName: "App Privileges",
                },
                editState: false,
                selectedOption: null,
                selectedOptionName: "",
                selectedPageIndex: -1,
                subTableIndex: -1,
                subTableIndexToggle: false,
                savePrivilegeLoader: false,
              });
              this.setCustomPrivilegeData();
              this.getPrivilegeTableData();
            }
          })
          .catch((errors) => {
            if (errors.response) {
              toast.error(errors.response.data.message);
            }
            this.setState({
              savePrivilegeLoader: false,
            });
          });
      } else {
        let customPrivileges = {
          tenant_id: this.state.tenant_Id,
          is_primary: true,
          fields: {
            privilegeName: this.state.field.privilegeName,
            privilegeType: "custom_privileges",
            app_previleges: this.state.appTypeCustomPrivilegeData,
            data_previleges: this.state.dataTypeCustomPrivilegeData,
            store_previleges: this.state.storePrivilegeData,
          },
          object_id: this.state.objectId,
        };

        createPrivilege(customPrivileges)
          .then((response) => {
            if (response.success === true) {
              toast.success(response.message);
              this.setState({
                field: {
                  privilegeName: "",
                  priviledgeTypeName: "App Privileges",
                },
                editState: false,
                selectedOption: null,
                selectedOptionName: "",
                selectedPageIndex: -1,
                subTableIndex: -1,
                subTableIndexToggle: false,
                savePrivilegeLoader: false,
              });
            }
            this.setCustomPrivilegeData();
            this.getPrivilegeTableData();
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
              this.setState({
                field: {
                  privilegeName: "",
                  priviledgeTypeName: "App Privileges",
                },
                selectedOption: null,
                selectedOptionName: "",
                selectedPageIndex: -1,
                subTableIndex: -1,
                subTableIndexToggle: false,
                savePrivilegeLoader: false,
              });
            }
            this.setCustomPrivilegeData();
          });
      }
    } else {
      this.setState({
        savePrivilegeLoader: false,
      });
    }
  }

  updatechildCustomPrivilegeTableData = (
    childEvent,
    parentTableIndex,
    componentIndex,
    actionIndex,
    actionId,
    componentId,
    val
  ) => {
    let checkboxVal = [];
    checkboxVal = this.state.appTypeCustomPrivilegeData;
    if (
      childEvent.target.checked &&
      checkboxVal[parentTableIndex][val]["pageList"][
        this.state.selectedPageIndex
      ]["componentList"][componentIndex]
    ) {
      if (
        !checkboxVal[parentTableIndex][val]["pageList"][
          this.state.selectedPageIndex
        ]["componentList"][componentIndex]["componentButtonList"].includes(
          actionId
        )
      ) {
        checkboxVal[parentTableIndex][val]["pageList"][
          this.state.selectedPageIndex
        ]["componentList"][componentIndex]["componentButtonList"].push(
          actionId
        );
      }
    } else if (
      checkboxVal[parentTableIndex][val]["pageList"][
        this.state.selectedPageIndex
      ]["componentList"][componentIndex]
    ) {
      checkboxVal[parentTableIndex][val]["pageList"][
        this.state.selectedPageIndex
      ]["componentList"][componentIndex]["componentButtonList"] = checkboxVal[
        parentTableIndex
      ][val]["pageList"][this.state.selectedPageIndex]["componentList"][
        componentIndex
      ]["componentButtonList"].filter((button) => {
        if (button !== actionId) {
          return button;
        }
      });
    }

    this.setState({ appTypeCustomPrivilegeData: checkboxVal }, () => {});
  };

  updateCustomPrivilegeTableData = (parentEvent, parentTableIndex, val) => {
    let checkboxVal = [];
    if (this.state.field.priviledgeTypeName === "Data Privileges") {
      checkboxVal = this.state.dataTypeCustomPrivilegeData;
      checkboxVal[parentTableIndex][val][parentEvent.target.value] =
        parentEvent.target.checked;
      this.setState({ dataTypeCustomPrivilegeData: checkboxVal }, () => {});
    } else {
      checkboxVal = this.state.appTypeCustomPrivilegeData;
      checkboxVal[parentTableIndex][val][parentEvent.target.value] =
        parentEvent.target.checked;
      this.setState({ appTypeCustomPrivilegeData: checkboxVal }, () => {});
    }
  };

  setCustomPrivilegeData = () => {
    let customAppPrivilegeTableData = [];
    let customDataSetPrivilegeTableData = [];
    this.state.getAllApps.forEach((data, index) => {
      let allInitialButtonPrevilege = data?.page_list?.map(
        (displayPage, pageIndex) => {
            let initialButtonPrivileges = displayPage?.display_fields
            ?.map((subDisplayField, idx) => {
              return {
                component_id: subDisplayField.component_id,
                componentButtonList: [],
              };
            });
          return {
            page_id: displayPage._id.$oid,
            function_name: displayPage.function_name,
            componentList: initialButtonPrivileges,
          };
        }
      );
      customAppPrivilegeTableData.push({
        [data._id.$oid]: {
          read: false,
          write: false,
          pageList: allInitialButtonPrevilege,
        },
        displayName: data.app_name,
        _id: data._id.$oid,
      });
    });
    this.state.getDataSetRecord.forEach((data, idx1) =>
      customDataSetPrivilegeTableData.push({
        [data._id.$oid]: {
          read: false,
          write: false,
        },
        displayName: data.name,
        _id: data._id.$oid,
      })
    );

    this.setState({
      allCustomAppPrivileges: customAppPrivilegeTableData,
      allCustomDataPrivileges: customDataSetPrivilegeTableData,
      allCustomStorePrivileges: {
        manage_all_product: { read: [], write: [] },
        manage_own_product: { read: [], write: [] },
      },
      dataTypeCustomPrivilegeData: customDataSetPrivilegeTableData,
      appTypeCustomPrivilegeData: customAppPrivilegeTableData,
      storePrivilegeData: {
        manage_all_product: { read: [], write: [] },
        manage_own_product: { read: [], write: [] },
      },
      isPrivilegeChanging: false,
    });
  };

  mergeOldAndNewAppPrivileges = (oldPrivileges) => {
    let newPrivileges = this.state.allCustomAppPrivileges;
    if(!oldPrivileges || oldPrivileges.length === 0){
      return newPrivileges;
    }
    return _.merge([],newPrivileges,oldPrivileges);
  }

  mergeOldAndNewDataPrivileges = (oldPrivileges) => {
    let newPrivileges = this.state.allCustomDataPrivileges;
    if(!oldPrivileges || oldPrivileges.length === 0){
      return newPrivileges;
    }
    return _.merge([],newPrivileges,oldPrivileges);
  }

  mergeOldAndNewStorePrivileges = (oldPrivileges) => {
    let newPrivileges = this.state.allCustomStorePrivileges;
    if(!oldPrivileges || oldPrivileges.length === 0){
      return newPrivileges;
    }
    return _.merge({},newPrivileges,oldPrivileges);
  }

  editPrivileges = (data) => {
    window.scrollTo({
      top: 50,
      behavior: "smooth",
    });

    const mergedAppPrivileges = this.mergeOldAndNewAppPrivileges(data.app_previleges);
    const mergedDataPrivileges = this.mergeOldAndNewDataPrivileges(data.data_previleges);
    const mergedStorePrivileges = this.mergeOldAndNewStorePrivileges(data.store_previleges);

    this.setState({
      errors: {},
      editState: true,
      storePrivilegeData: mergedStorePrivileges,
      appTypeCustomPrivilegeData: mergedAppPrivileges,
      dataTypeCustomPrivilegeData: mergedDataPrivileges,
      field: {
        privilegeName: data.privilegeName,
        priviledgeTypeName: "App Privileges",
      },
      privilageId: data.id,
      objectId: data._id.$oid,
      subTableIndex: -1,
      subTableIndexToggle: false,
      selectedOption: "",
      selectedOptionName: "",
      selectedPageIndex: -1,
      isPrivilegeChanging: true,
    });
  };

  handleClose = () => {
    this.setState({ showDeleteModal: false });
  };
  deletePrivileges = () => {
    this.setState({
      deleteLoader: true,
    });
    let body = {
      data: {
        data: [this.state.objectId],
      },
    };
    if (this.state.currentPage * 10 - this.state.totalDataCount === 9) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
    deletePrivilegeData(body)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          this.setState({ editState: false, isLoading: true });
          this.getPrivilegeTableData();
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ showDeleteModal: false, deleteLoader: false });
      });
  };

  receiveCurrentPageNumber = (langValue) => {
    this.setState({ currentPage: langValue });
  };

  customPrivilege(alldata) {
    return alldata?.filter((data) => {
      if (data.privilegeType === "custom_privileges") {
        return data;
      }
    });
  }

  handleChange = (selectedOption, index, appname) => {
    this.state.appTypeCustomPrivilegeData[index][appname].pageList.forEach(
      (page, pageindex) => {
        if (page.function_name.toLowerCase() === selectedOption.value) {
          this.setState({
            selectedPageIndex: pageindex,
            subTableIndex: index,
            subTableIndexToggle: true,
            selectedOption: page.page_id,
            selectedOptionName: selectedOption,
          });
        }
      }
    );
  };

  setStorePrivilegeData = (storePrivilege) => {
    this.setState({ storePrivilegeData: storePrivilege });
  };

  render() {
    let privilegeTypeValue = this.state.privilegeType.map((data, index) => (
      <option key={index} value={data}>
        {data}
      </option>
    ));

    const setCustomPrivilegeHeaderData =
      this.state.getCustomPrivilegeTableHeader.map((header, index) => {
        if (this.state.field.priviledgeTypeName === "Data Privileges") {
          if (header !== "Select Pages") {
            return (
              <th className="table_head" key={index}>
                {header}
              </th>
            );
          }
        } else {
          return (
            <th className="table_head" key={index}>
              {header}
            </th>
          );
        }
      });

    const setCustomPrivilegeTableData = [];
    let priviledgeTypeNameData = this.state.appTypeCustomPrivilegeData;
    if (this.state.field.priviledgeTypeName === "Data Privileges") {
      priviledgeTypeNameData = this.state.dataTypeCustomPrivilegeData;
    }
    priviledgeTypeNameData.forEach((data, index) => {
      let val = data._id;
      setCustomPrivilegeTableData.push(
        <React.Fragment key={index}>
          <tr key={index}>
            <td>{data.displayName}</td>

            {this.state.isWrite ? (
              <td>
                <input
                  className="table-checkbox pointer"
                  type="checkbox"
                  onChange={(e) => {
                    this.updateCustomPrivilegeTableData(e, index, val);
                  }}
                  checked={priviledgeTypeNameData[index][val]["read"]}
                  value={"read"}
                />
              </td>
            ) : (
              <td>
                <input className="table-checkbox" type="checkbox" disabled />
              </td>
            )}

            {this.state.isWrite ? (
              <td>
                <input
                  className="table-checkbox pointer"
                  type="checkbox"
                  onChange={(e) => {
                    this.updateCustomPrivilegeTableData(e, index, val);
                  }}
                  checked={priviledgeTypeNameData[index][val]["write"]}
                  value={"write"}
                />
              </td>
            ) : (
              <td>
                <input className="table-checkbox" type="checkbox" disabled />
              </td>
            )}
            {this.state.field.priviledgeTypeName !== "Data Privileges" ? (
              <td>
                <Select
                  menuPosition="fixed"
                  classNamePrefix={"react-select"}
                  placeholder="Select.."
                  options={this.state.getAllApps[index]?.pageOptions}
                  value={
                    this.state.subTableIndex === index
                      ? this.state.selectedOptionName
                      : null
                  }
                  onChange={(e) => {
                    if (e) {
                      this.handleChange(e, index, val);
                    } else {
                      this.setState({
                        selectedOption: null,
                        selectedOptionName: "",
                        selectedPageIndex: -1,
                        subTableIndex: -1,
                        subTableIndexToggle: false,
                      });
                    }
                  }}
                  isClearable
                />
              </td>
            ) : null}
          </tr>
          {index === this.state.subTableIndex &&
          this.state.subTableIndexToggle ? (
            <tr className="noHover">
              <td colSpan={4}>
                <table className="table table-bordered bulk_action no-footer w-100 mb-0 tableNoHover">
                  <thead>
                    <tr className="head-row">
                      <th>Component</th>
                      <th>Action Name</th>
                      <th>Show</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.getAllApps[index].page_list
                      .filter(
                        (pagedata) =>
                          pagedata._id.$oid === this.state.selectedOption
                      )
                      .map((dataa) => {
                        return dataa?.display_fields.map(
                          (data, componentIndex) => {
                            return (
                              <React.Fragment key={componentIndex}>
                                <tr className="noHoverTd">
                                  <td rowSpan={data?.actionDetails?.length + 1}>
                                    {data?.componentType}
                                    {componentIndex + 1}
                                  </td>
                                </tr>
                                {data?.actionDetails?.map(
                                  (actiondata, actionIndex) => {
                                    return (
                                      <tr key={actionIndex}>
                                        <td>{actiondata.actionName || "Dynamic Action"}</td>
                                        <td>
                                          <input
                                            className="table-checkbox"
                                            type="checkbox"
                                            value={"show"}
                                            onChange={(childEvent) => {
                                              this.updatechildCustomPrivilegeTableData(
                                                childEvent,
                                                index,
                                                componentIndex,
                                                actionIndex,
                                                actiondata.action_id,
                                                data.component_id,
                                                val
                                              );
                                            }}
                                            checked={
                                              priviledgeTypeNameData[index][
                                                val
                                              ]["pageList"][
                                                this.state.selectedPageIndex
                                              ]["componentList"][
                                                componentIndex
                                              ] &&
                                              priviledgeTypeNameData[index][
                                                val
                                              ]["pageList"][
                                                this.state.selectedPageIndex
                                              ]["componentList"][
                                                componentIndex
                                              ][
                                                "componentButtonList"
                                              ]?.includes(actiondata.action_id)
                                            }
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            );
                          }
                        );
                      })}
                  </tbody>
                </table>
              </td>
            </tr>
          ) : null}
        </React.Fragment>
      );
    });

    const { currentPage, dataPerPage } = this.state;

    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const currentData = this.state.privilegeTableData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    const priviledgeTableBodyData = currentData?.map((data, index) => {
      if (data.privilegeType === "custom_privileges") {
        return (
          <tr key={index}>
            <td>{data.privilegeName}</td>
            {this.state.isWrite ? (
              <td className="text-nowrap">
                <div className="d-flex text-center m-0">
                  <div className="action-icons">
                    <FontAwesomeIcon
                      role="button"
                      icon={"fa fa-pen-to-square"}
                      className="me-2"
                      size="lg"
                      onClick={() => this.editPrivileges(data)}
                    />
                    <FontAwesomeIcon
                      role="button"
                      icon={"fa fa-trash"}
                      className="me-2"
                      onClick={() =>
                        this.setState({
                          showDeleteModal: true,
                          objectId: data._id.$oid,
                          errors: {},
                        })
                      }
                      size="lg"
                    />
                  </div>
                </div>
              </td>
            ) : null}
          </tr>
        );
      }
    });
    return (
      <div>
        <div className="d-help py-2">
          <h5>Custom Privileges</h5>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={(e) => {
              e.preventDefault();
              this.props?.history?.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>

        {this.state.isWrite ? (
          <React.Fragment>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Privilege Name
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Privilege Name"
                  name="privilegeName"
                  value={this.state.field.privilegeName}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.privilegeName}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Privilege Type
              </label>
              <div className="col-md-9 col-sm-9 ">
                <Form.Select
                  className="form-control"
                  name="priviledgeTypeName"
                  value={this.state.field.priviledgeTypeName}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                >
                  {privilegeTypeValue}
                </Form.Select>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <CustomModal
          modalState={this.state.showDeleteModal}
          modalTitle={"Are you sure?"}
          modalDiscription={"Do you want to delete privilege"}
          hideModal={() => this.setState({ showDeleteModal: false })}
          onDeleteClick={() => this.deletePrivileges()}
          isLoading={this.state.deleteLoader}
        />
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="table-responsive">
              {this.state.field.priviledgeTypeName === "Store Privilege" ? (
                <StorePrivileges
                  setStorePrivilegeData={this.setStorePrivilegeData}
                  isPrivilegeChanging={this.state.isPrivilegeChanging}
                  storePrivilegeData={this.state.storePrivilegeData}
                ></StorePrivileges>
              ) : (
                <>
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr className="head-row">
                        {setCustomPrivilegeHeaderData}
                      </tr>
                    </thead>
                    <tbody>{setCustomPrivilegeTableData}</tbody>
                  </table>
                  {this.state.dataTypeCustomPrivilegeData.length === 0 &&
                  this.state.field.priviledgeTypeName === "Data Privileges" ? (
                    <p className="no_table_records">No Records Found</p>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
        {this.state.isWrite ? (
          <div className="d-flex justify-content-end">
            {this.state.editState ? (
              <button
                onClick={() => {
                  this.setState({
                    field: {
                      privilegeName: "",
                      priviledgeTypeName: "App Privileges",
                    },
                    editState: false,
                    selectedOption: null,
                    selectedOptionName: "",
                    selectedPageIndex: -1,
                    subTableIndex: -1,
                    subTableIndexToggle: false,
                    errors: {},
                  });
                  this.setCustomPrivilegeData();
                }}
                type="button"
                className="btn btn-danger btn-md mt-2 me-1"
              >
                &nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                <span className="ms-1">&nbsp;&nbsp;Cancel&nbsp;&nbsp;</span>
              </button>
            ) : null}

            <button
              onClick={(event) => this.submitPrivileges(event)}
              type="submit"
              className="custom-btn mt-2 me-0"
            >
              {this.state.editState ? (
                <>
                  &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-retweet" />
                  <span className="ms-1">&nbsp;&nbsp;Update Privileges&nbsp;&nbsp;</span>
                </>
              ) : (
                <>
                  {this.state.savePrivilegeLoader ? (
                    <div className="spinner-border spinner-border-sm text-light" />
                  ) : (
                    <FontAwesomeIcon icon="fa-regular fa-circle-check" />
                  )}
                  <span className="ms-1">&nbsp;&nbsp;Save Privileges&nbsp;&nbsp;</span>
                </>
              )}
            </button>
          </div>
        ) : null}

        <div className="d-help pb-2">
          <h5>Saved Custom Privileges</h5>
        </div>

        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr className="head-row">
                  <th className="text-nowrap">Privilege Name</th>
                  {this.state.isWrite ? (
                    <th className="text-nowrap">Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>{priviledgeTableBodyData}</tbody>
            </table>
          </div>
        )}

        {this.state.totalDataCount === 0 && !this.state.isLoading ? (
          <p className="no_table_records">No Records Found</p>
        ) : null}

        {this.state.totalDataCount > 10 ? (
          <div className="dynamic_pagination my-2">
            <Pagination
              pageRangeDisplayed={3}
              activePage={this.state.currentPage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalDataCount}
              onChange={this.receiveCurrentPageNumber}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default CustomizePrivileges;
