import React, { Component } from "react";
import { filterQueryRecords } from "./../../../services/tableConfigService";
import { CSVLink } from "react-csv";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ExportExcel extends Component {
  tempData = [];
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      exportedData: [],
      loading: false,
      tenantData: JSON.parse(localStorage.getItem("user-info")).tenant_id,
    };

    this.csvLinkEl = React.createRef();
  }

  getUserList = () => {
    let data = {};
    data = {
      FilterCriteria: [],
      SortCriteria: [],
      PaginationCriteria: {
        limit: 0,
        skip: 0,
      },
    };
    this.setState({ loading: true });
    filterQueryRecords(data)
      .then((response) => {
        this.setState(
          {
            loading: false,
            data: response.data,
          },
          () => {
            this.downloadReport();
          }
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  downloadReport = async () => {
    if (this.state.data) {
      this.tempData = [];
      //if not record found
      if(this.state.data.length === 0){
        let ObjValue = {};
        this.props.headerData.forEach((header) => {
          //ObjValue["_id"] = "";
          ObjValue[header.name] = "";
        });
        this.tempData.push(ObjValue);
      }
      this.state.data.forEach((dataDummy) => {
        let ObjValue = {};
        this.props.headerData.forEach((header) => {
          if (header.data_type === "dateTime") {
            ObjValue[header.name] = moment(dataDummy[header.name]).format(
              "YYYY-MM-DD"
            );
          } else {
            ObjValue["_id"] = dataDummy._id.$oid;
            ObjValue[header.name] = dataDummy[header.name];
          }
        });
        this.tempData.push(ObjValue);
      });
      this.setState(
        {
          exportedData: this.tempData,
          loading: false,
        },
        () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        }
      );
    }
  };

  render() {
    return (
      <div>
        <button
          className="export-btn mx-1"
          onClick={this.getUserList}
          disabled={this.state.loading}
        >
          &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-file-arrow-down" />
          <span className="ms-1">&nbsp;&nbsp;Export&nbsp;&nbsp;</span>
        </button>
        <CSVLink
          data={this.state.exportedData}
          filename={this.state.tenantData}
          ref={this.csvLinkEl}
        ></CSVLink>
      </div>
    );
  }
}

export default ExportExcel;
