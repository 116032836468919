import React, { useContext, useEffect, useState } from "react";
import { getRecordByIdForStepper } from "../../../../services/appService";
import CustomForm from "../../components/CustomForm";
import { FilterFormContext } from "../../contexts/FilterFormContext";
import { useParams } from "react-router";
import { RoutesContext } from "../../../../RoutesContext";
import "./stepperForm.scss";
import { toast } from "react-toastify";
import { componentType } from "../../../common/model/Model";
import { reactAppFormExecutionVP } from "../../../../services/API";


const StepperForm = ({ appSessionId, objData }) => {
  const { filterFormData, setFilterFormData } = useContext(FilterFormContext);
  const [stepperFormName, setStepperFormName] = useState([]);
  const [stepperFormMap, setStepperFormMap] = useState({});
  const [columenWidth, setcolumenWidth] = useState({});
  const [activeStepperForm, setActiveStepperForm] = useState(null);
  const [configObj, setConfigObj] = useState();
  const [stepStatus, setStepStatus] = useState({}); // Tracks step status ('saved', 'submitted', 'drafted')
  const { flowId } = useParams();
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);

  useEffect(() => {
    const component = objData?.page?.display_fields.filter(
      (field) => field?.componentType === "Stepper Form"
    );
    if (component?.length) {
      const columenWidth = component[0]?.columenWidth || 12;
      const names = component[0]?.stepperFormName || [];
      const formMap = {};
      names.forEach((name, index) => {
        formMap[name] = component[0]?.stepperForm[index];
      });
      setStepperFormName(names);
      setStepperFormMap(formMap);
      setcolumenWidth(columenWidth);

      if (names.length > 0) {
        const defaultName = names[0];
        handleStepperFormClick(defaultName, formMap[defaultName]);
      }
    }

    if (cachedData?.[flowId] && component[0]?.isCacheEnabled) {
      setFilterFormData({ ...cachedData[flowId]});
    } else {
      setFilterFormData(); // Default initialization
    }
  }, [flowId, objData]);

  const handleStepperFormClick = (name, objectId) => {
    setActiveStepperForm(name);
    getRecordByIdForStepper({ object_id: objectId }).then((response) => {
      setConfigObj(response);
    });
  };

  const addSubmitForm = async (isDraft) => {
    let objectBody = {
      app_session_id: appSessionId,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      data: {
        page_fields: [
          configObj?.data?.page_fields.map((field) => ({
            is_required: field.is_required,
            display_name: field?.display_name,
            name: field?.name,
          })),
        ],
        formData: [{ ...filterFormData, srno: 0, isDraft }], // Include isDraft
        logic_to_send: [configObj?.data?.logic_name_value],
      },
      page_type: componentType.formComponent,
      store_output_key: configObj?.data?.form_output_key,
    };
  
    try {
      setSubmitResponseLoading(true);
      if (isDraft === "Yes") {
        toast.warning("Saved as draft");
        setStepStatus((prevStatus) => ({
          ...prevStatus,
          [activeStepperForm]: "drafted",
        }));
      } else {
        const response = await reactAppFormExecutionVP(objectBody);
        toast.success(response?.message);
        setStepStatus((prevStatus) => ({
          ...prevStatus,
          [activeStepperForm]: "submitted",
        }));
      }
      const response = await reactAppFormExecutionVP(objectBody);
      return response;
    } catch (error) {
      if (isDraft !== "Yes") {
        toast.error(error?.message);
      }
      throw error;
    } finally {
      setSubmitResponseLoading(false);
    }
  };
  
  const handleSaveDraftClick = async () => {
    try {
      await addSubmitForm("Yes");
    } catch (error) {
      console.error("Error while saving draft:", error);
    }
  };
  
  
  const handleNextButtonClick = async () => {
    const currentStatus = stepStatus[activeStepperForm];
    if (currentStatus === "drafted") {
      const currentIndex = stepperFormName.indexOf(activeStepperForm);
      if (currentIndex !== -1 && currentIndex < stepperFormName.length - 1) {
        const nextName = stepperFormName[currentIndex + 1];
        handleStepperFormClick(nextName, stepperFormMap[nextName]);
      }
    } else {
      try {
        const response = await addSubmitForm("No");
        if (response && response.message === "Success") {
          const currentIndex = stepperFormName.indexOf(activeStepperForm);
  
          if (currentIndex !== -1 && currentIndex < stepperFormName.length - 1) {
            const nextName = stepperFormName[currentIndex + 1];
            handleStepperFormClick(nextName, stepperFormMap[nextName]);
          }
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    }
  };
  

  const isLastStep = stepperFormName.indexOf(activeStepperForm) === stepperFormName.length - 1;

  return (
    <div className={`col-md-${columenWidth ? columenWidth : 12} px-0`}>
      <div className="row main-content h-100 mx-1 bg-white">
        <div className="col-md-3">
          <div className="border p-3">
            {stepperFormName?.map((name, index) => {
              const isActive = activeStepperForm === name;
              const status = stepStatus[name];
              const currentIndex = stepperFormName.indexOf(activeStepperForm);
              const isPreviousStep = index < currentIndex;
              
              return (
                <div
                  key={name}
                  onClick={() => {
                    if (isPreviousStep) {
                      handleStepperFormClick(name, stepperFormMap[name]);
                    }
                  }}
                  className="stepper-name"
                >
                  <div
                    className={`stepper-circle ${
                      status === "submitted"
                        ? "completed"
                        : status === "saved" || status === "drafted"
                        ? "saved"
                        : isActive
                        ? "active"
                        : "inactive"
                    }`}
                  ></div>
                  <span
                    className={`stepper-label ${
                      isActive || status || isPreviousStep ? "" : "inactive"
                    }`}
                  >
                    {name}
                  </span>
                </div>
              );
            })}
            <div className="button-container">
              <button className="buttons save-draft" onClick={handleSaveDraftClick}>
                Save Draft
              </button>
              <button className="buttons nextb" onClick={handleNextButtonClick}>
                {isLastStep ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-9 border">
          <div className="main-content bg-white py-4 px-4 mb-2 col-md-12">
            <CustomForm
              pageFields={configObj?.data?.page_fields}
              formGroupData={configObj?.data?.formGroupData || []}
              appSessionId={appSessionId}
              formData={filterFormData}
              setFormData={(data) => setFilterFormData({ ...data})} // Ensure `srno` is preserved
              onSubmitClick={addSubmitForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepperForm;