import React from "react";
import Seach from "../search/Search";
import "./Table.scss";
import ImportExcel from "../importexcel/ImportExcel";
import Outside from "./Outside";
import Loader from "../Loader/Loader";
import {
  filterQueryRecords,
  deleteSingleMultipleRecords,
  clearAllDataFromDataset,
} from "./../../../services/tableConfigService";
import { ExportExcel } from "../exportexcel/ExportExcel";
import ModalForm from "../modalform/ModalForm";
import Footer from "../../common/footer/Footer";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getCheckedData: [],
      isActive: null,
      getTableData: [],
      tableHeader: [],
      updatedData: [],
      SortCriteria: [],
      FilterCriteria: [],
      headerValue: "",
      ascSort: true,
      errorMsg: false,
      limit: 20,
      skip: 0,
      pageNumbers: [],
      totalRecordCount: 0,
      currentPage: 0,
      saveIndex: null,
      childValue: "",
      childOption: "contains",
      loading: true,
      showDeleteButton: false,
      singleDelete: false,
      sendIdToModal: "new_modal",
      datasetName: "",
      clearValue: 0,
      searchDataType: "",
      sendDatasetId: "",
      activePage: 1,
      showLineItemsData: false,
      showLineItemData: [],
      showLineItemHeaderData: "",
    };
  }

  componentWillMount() {
    this.setState(
      {
        sendDatasetId: this.props.sedatasetId,
      },
      () => {
        this.getTableData();
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.sedatasetId !== nextProps.sedatasetId) {
      this.setState(
        {
          sendDatasetId: nextProps.sedatasetId,
          limit: 10,
          skip: 0,
          currentPage: 0,
        },
        () => {
          this.getTableData();
        }
      );
    }
  }

  getTableData() {
    this.setState({
      getTableData: [],
    });
    let data = {};
    data = {
      FilterCriteria: this.state.FilterCriteria.filter(
        (data) => data.filter !== ""
      ),
      SortCriteria: this.state.SortCriteria,
      PaginationCriteria: {
        limit: this.state.limit,
        skip: this.state.skip,
      },
    };
    filterQueryRecords(data, this.state.sendDatasetId)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            tableHeader: response.headers,
            getTableData: response.data,
            totalRecordCount: response.totalcount,
            datasetName: response.table_name,
            loading: false,
          });
        } else {
          this.setState(
            { errorMsg: true, loading: false, totalRecordCount: 0 },
            () => {
              toast.error(response.message);
            }
          );
        }
      })
      .catch((err) => {
        this.setState(
          { errorMsg: true, loading: false, totalRecordCount: 0 },
          () => {
            toast.error(err.message);
          }
        );
      });
  }

  // DELETE FUNCTIONS

  // get multiple record object value
  updateStateList(e, value, index) {
    let checked_id = value._id.$oid;
    if (e.target.checked) {
      this.setState({
        getCheckedData: [...this.state.getCheckedData, checked_id],
      });
    } else {
      let uncheckedValue = this.state.getCheckedData.filter(function (item) {
        return item !== checked_id;
      });
      this.setState({
        getCheckedData: uncheckedValue,
      });
    }
  }

  // delete Single Record
  deletePopupModal(data) {
    confirmAlert({
      message: "Are you sure you want to delete record(s) ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.singleMultipleData(data),
        },
        {
          label: "No",
        },
      ],
    });
  }

  clearDataset(data) {
    confirmAlert({
      message: "Are you sure you want to clear the entire dataset permanently?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteAllDataFromDataset(data),
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteAllDataFromDataset(data) {
    clearAllDataFromDataset(data)
      .then((response) => {
        if (response.success === true) {
          this.setState(
            {
              getCheckedData: [],
              skip: 0,
              limit: 10,
              currentPage: 0,
              activePage: 1,
            },
            () => {
              if (response.success === true) {
                toast.success("All Data Deleted Successfully");
                this.getTableData();
              }
            }
          );
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }


  singleMultipleData(data) {
    if (data) {
      this.deleteCurrentRecord(data);
    }
    if (data === undefined || this.state.getCheckedData.length > 1) {
      this.deleteFunction();
    }
  }

  deleteCurrentRecord(data) {
    let currentRecord = data._id.$oid;
    let singleObjData = {};
    singleObjData = {
      data: [currentRecord],
    };
    this.setState(
      {
        singleDelete: true,
      },
      () => {
        this.deleteFunction(singleObjData);
      }
    );
  }

  // api for deleting records + multiple records
  deleteFunction(data) {
    let singleObjData;
    if (this.state.singleDelete) {
      singleObjData = data;
      this.setState({
        singleDelete: false,
      });
    } else {
      singleObjData = {
        data: this.state.getCheckedData,
      };
    }
    deleteSingleMultipleRecords(singleObjData)
      .then((response) => {
        if (response.success === true) {
          this.setState(
            {
              getCheckedData: [],
              skip: 0,
              limit: 10,
              currentPage: 0,
              activePage: 1,
            },
            () => {
              if (response.success === true) {
                toast.success("Data Deleted Successfully");
                this.getTableData();
              }
            }
          );
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  // SORT AND PAGINATION

  // sort functionality on columns
  sortTableData = (header, index, ascSort) => {
    let header_lowCase = header;
    let sortObj = {
      sort_field: header_lowCase,
      sort_type: "asc",
    };
    let sortFlag = true;
    if (this.state.SortCriteria.length > 0) {
      this.state.SortCriteria.filter((data) => {
        if (data.sort_field === header_lowCase) {
          sortFlag = false;
          let order = data.sort_type;
          data.sort_type = order === "asc" ? (data.sort_type = "desc") : "asc";
        }
      });
      if (sortFlag) {
        this.state.SortCriteria.push(sortObj);
      }
    } else {
      this.state.SortCriteria.push(sortObj);
    }
    this.getTableData();
  };

  // pagination
  handlePageChange(pageNumber) {
    let currentSkip = (pageNumber - 1) * this.state.limit;
    this.setState(
      {
        skip: currentSkip,
        activePage: pageNumber,
        loading: true,
      },
      () => {
        this.getTableData();
      }
    );
  }

  // open grid div
  openDiv = (header, index) => {
    let header_lowCase = header.name;
    if (header_lowCase)
      this.setState({
        saveIndex: index,
        headerValue: header_lowCase,
        searchDataType: header.data_type,
      });
  };

  // close grid div
  closeCurrentDiv = () => {
    this.setState({
      saveIndex: null,
    });
  };

  // GRID FILTER

  // grid filter function
  setFilterObjValue = () => {
    let filterObjectValue = {
      filter_type: this.state.searchDataType === "number" ? "number" : "text",
      type:
        this.state.searchDataType === "number" &&
          this.state.childOption === "contains"
          ? "greater than"
          : this.state.childOption,
      filter: this.state.childValue,
      filter_field: this.state.headerValue,
    };

    let filterFlag = true;
    if (this.state.FilterCriteria.length > 0) {
      this.state.FilterCriteria.filter((data) => {
        if (data.filter_field === this.state.headerValue) {
          filterFlag = false;
          data.filter = this.state.childValue;
          data.type = this.state.childOption;
        }
      });
      if (filterFlag) {
        this.state.FilterCriteria.push(filterObjectValue);
      }
    } else {
      this.state.FilterCriteria.push(filterObjectValue);
    }
    if (this.state.FilterCriteria.length === 1) {
      if (this.state.FilterCriteria[0].filter === "") {
        // this.state.FilterCriteria = [];
        this.setState({ FilterCriteria: [] });
      }
    }
    let isDataEmpty = this.state.FilterCriteria.filter((x) => x.filter !== "");
    this.state.FilterCriteria = isDataEmpty;
    this.getTableData();
  };

  // input value from Seach child
  changedChildValue(getChildData) {
    if (getChildData.length >= 1 || this.state.FilterCriteria.length >= 1) {
      this.setState(
        {
          childValue: getChildData,
        },
        () => {
          this.setFilterObjValue();
        }
      );
    } else {
      if (this.state.FilterCriteria.length >= 1) {
        let index = this.state.FilterCriteria.findIndex(
          (rank) => rank.filter_field === this.state.headerValue
        );
        this.state.FilterCriteria[index].filter = "";
        this.setState(
          {
            FilterCriteria: this.state.FilterCriteria.splice(index, 1),
          },
          () => { }
        );
        this.state.childValue = "";
        this.setFilterObjValue();
      }
    }
  }

  // input dropdown value from child
  changedChildOption(getOptionValue) {
    this.setState({
      childOption: getOptionValue,
    });
  }

  loginModalRef = (obj) => {
    this.showModal = obj && obj.handleShow;
  };

  handleLanguage = () => {
    this.setState(
      {
        getCheckedData: [],
        skip: 0,
        limit: 10,
        currentPage: 0,
        activePage: 1,
        loading: true,
      },
      () => {
        this.getTableData();
      }
    );
  };

  editModalData(data) {
    this.setState({
      sendIdToModal: data,
    });
    this.showModal();
  }

  openNewModal() {
    this.setState({
      sendIdToModal: "new_modal",
    });
    this.showModal();
  }

  // clear filter
  clearFilterData() {
    this.setState(
      {
        SortCriteria: [],
        FilterCriteria: [],
        skip: 0,
        limit: 10,
      },
      () => {
        this.clearInput();
        this.getTableData();
      }
    );
  }

  clearInput = () => {
    this.setState((prev) => ({ clearValue: prev.clearValue + 1 }));
  };

  openModelData = (header, data) => {
    this.setState({
      showLineItemsData: true,
      showLineItemData: data,
      showLineItemHeaderData: header.display_name,
    });
  };
  handleModalLineItemsClose = () => {
    this.setState({
      showLineItemsData: false,
    });
  };

  render() {
    // render table column name
    const tableRenderHeaderData = this.state.tableHeader?.map(
      (header, index) => (
        <th className="text-nowrap" key={index}>
          <div className="d-flex">
            <div
              onClick={() => {
                this.closeCurrentDiv();
              }}
            >
              {header.display_name}
            </div>
            <div className="d-flex align-items-center ms-4">
              <div
                className="icon mx-2"
                type="button"
                onClick={(e) => this.openDiv(header, index)}
              >
                <FontAwesomeIcon icon="fa fa-bars" />
                <div
                  className={`${this.state.saveIndex === index ? "showDiv" : "hideDiv"
                    }`}
                >
                  <Seach
                    inpuData={{
                      childvalue: this.state.childValue,
                      changedChildValue: this.changedChildValue.bind(this),
                    }}
                    optionData={{
                      childOption: this.state.childOption,
                      changedChildOption: this.changedChildOption.bind(this),
                    }}
                    clearValue={this.state.clearValue}
                    datatype={this.state.searchDataType}
                  ></Seach>
                </div>
              </div>
              <FontAwesomeIcon
                icon={"fa fa-sort"}
                onClick={(e) =>
                  this.sortTableData(header.name, index, this.state.ascSort)
                }
              />
            </div>
          </div>
        </th>
      )
    );

    // render table data
    if (this.state.getTableData.length >= 1) {
      this.state.updatedData = this.state.getTableData.map((data, index) => (
        <tr
          role="row"
          key={index}
          className={`${this.state.getCheckedData.includes(data._id.$oid)
            ? "checkedRow"
            : "odd"
            }`}
        >
          <td>
            <input
              className="table-checkbox"
              role="button"
              type="checkbox"
              onChange={(e) => this.updateStateList(e, data, index)}
              checked={this.state.getCheckedData.includes(data._id.$oid)}
              value={data}
            />
          </td>
          <td>{index + 1 + (this.state.activePage - 1) * this.state.limit}</td>
          {this.state.tableHeader.map((headerData, index) => {
            if (headerData.data_type === "file") {
              return (
                <td key={index}>
                  {data[headerData.name]?.map((file, fileIndex) => (
                    <p>
                      <a
                        key={fileIndex}
                        href={file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={"fa fa-download"}
                          className="mb-0 ms-2"
                          aria-hidden="true"
                        />&nbsp;{file.split("/").pop()}
                      </a>
                    </p>
                  ))}
                </td>
              );
            } else if (
              headerData.data_type === "arr_obj" ||
              headerData.data_type === "lineItems"
            ) {
              return (
                <td key={index} className="fw-bold">
                  {data[headerData.name]?.length ? (
                    <span
                      role="button"
                      onClick={(e) => {
                        this.openModelData(headerData, data[headerData.name]);
                      }}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" />&nbsp;{data[headerData.name].length} item/s
                    </span>
                  ) : (
                    0
                  )}
                </td>
              );
            } else {
              if (typeof data[headerData.name] === "object") {
                if (Array.isArray(data[headerData.name])) {
                  return (
                    <td key={index}>{data[headerData.name]?.join(",")}</td>
                  );
                } else {
                  return (
                    <td key={index}>{JSON.stringify(data[headerData.name])}</td>
                  );
                }
              } else {
                return <td key={index}>{data[headerData.name]}</td>;
              }
            }
          })}

          <td action="true">
            <div className="action-icons text-center">
              <FontAwesomeIcon
                icon={"fa fa-pen-to-square"}
                className="me-2"
                title="Edit"
                size="lg"
                role="button"
                onClick={(e) => this.editModalData(data._id.$oid)}
              />

              {this.state.getCheckedData.length < 1 && (
                <FontAwesomeIcon
                  icon={"fa fa-trash"}
                  className="me-2"
                  title="Delete"
                  size="lg"
                  role="button"
                  onClick={(e) => {
                    this.deletePopupModal(data);
                  }}
                />
              )}

            </div>
          </td>
        </tr>
      ));
    } else {
      this.state.updatedData = [];
    }

    // render pagination

    // render import data
    const getTableDataAfterImport = () => {
      this.setState(
        {
          skip: 0,
          limit: 10,
          currentPage: 0,
        },
        () => {
          this.getTableData();
        }
      );
    };

    const receiveLoaderData = (condition) => {
      this.setState({
        loading: condition,
      });
    };

    // render export data
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className="d-help py-2 flex-wrap">
          <h5>{this.state.datasetName}</h5>
          <div className="d-flex flex-wrap">
            {this.state.getCheckedData.length === 1 ? (
              <button
                className="btn btn-md text-nowrap btn-info me-1"
                onClick={(e) =>
                  this.editModalData(this.state.getCheckedData[0])
                }
              >
                <FontAwesomeIcon
                  icon="fa fa-pen-to-square"
                  className="text-white"
                />
                <span className="ms-1 text-white">Edit</span>
              </button>
            ) : this.state.getCheckedData.length < 1 ? (
              <button
                className="add-btn me-1 mb-2"
                onClick={(e) => this.openNewModal()}
              >
                <FontAwesomeIcon icon="fa fa-plus" />
                <span className="ms-1">Add</span>
              </button>
            ) : null}

            <ImportExcel
              getTableDataImport={getTableDataAfterImport}
              sendLoaderData={receiveLoaderData}
            ></ImportExcel>

            <ExportExcel headerData={this.state.tableHeader}></ExportExcel>

            {this.state.FilterCriteria.length >= 1 ? (
              <button
                className="btn btn-md text-nowrap btn-warning me-1"
                onClick={() => this.clearFilterData()}
              >
                <FontAwesomeIcon
                  icon="far fa-times-circle"
                  className="text-white"
                />
                <span className="ms-1 text-white">Clear Filter</span>
              </button>
            ) : null}

            {this.state.getCheckedData.length > 0 ? (
              <button
                className="btn btn-md text-nowrap btn-danger me-1"
                onClick={() => this.deletePopupModal()}
              >
                <FontAwesomeIcon icon="fa fa-trash" className="text-white" />
                <span className="ms-1 text-white">
                  Delete
                  {this.state.getCheckedData.length > 1 ? " Selected" : ""}
                </span>
              </button>
            ) : null}

            {this.state.getCheckedData.length > 0 ? (
              <button
                className="btn btn-md text-nowrap btn-danger me-1"
                onClick={() => this.clearDataset()}
              >
                <FontAwesomeIcon icon="fa fa-trash" className="text-white" />
                <span className="ms-1 text-white">
                  Clear Dataset
                </span>
              </button>
            ) : null}

            <button
              className="back-btn mb-2 me-0"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <FontAwesomeIcon icon="fa fa-arrow-left" />
              <span className="ms-1">Back</span>
            </button>
          </div>
        </div>

        <div className={`table-responsive table-bordered tableTheme1`}>
          <table className="table mb-0">
            <Outside setIndex={() => this.closeCurrentDiv()} isTable={true}>
              <tr role="row" className="head-row">
                <th className="text-nowrap checkBoxColumn"></th>
                <th className="width-auto text-nowrap">
                  Srno{" "}
                  {this.state.totalRecordCount >= 1 ? (
                    <FontAwesomeIcon
                      icon={"fa fa-sort"}
                      className="ms-3 cur-pointer"
                      onClick={(e) => this.sortTableData("srno")}
                    />
                  ) : null}
                </th>
                {tableRenderHeaderData}
                <th className="text-nowrap actionsColumn" action="true">
                  Actions
                </th>
              </tr>
            </Outside>

            <tbody>{this.state.updatedData}</tbody>
          </table>
          {this.state.totalRecordCount < 1 ? (
            <p className="no_table_records">No matching records found</p>
          ) : null}
        </div>

        {this.state.totalRecordCount >= 1 ? (
          <div className="row align-items-center">
            <div className="col-sm-9">
              <div id="datatable_info">
                Showing {this.state.skip + 1} to{" "}
                {this.state.totalRecordCount > this.state.skip + 20
                  ? this.state.skip + 20
                  : this.state.totalRecordCount}{" "}
                of {this.state.totalRecordCount} entries{" "}
              </div>
            </div>
            {this.state.totalRecordCount <= 10 ? null : (
              <div className="col-sm-3 dynamic_pagination">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={20}
                  totalItemsCount={this.state.totalRecordCount}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            )}
          </div>
        ) : null}

        <ModalForm
          ref={this.loginModalRef}
          onSelectLanguage={this.handleLanguage}
          itemId={this.state.sendIdToModal}
          sendHeaderData={this.state.tableHeader}
          modalDatasetId={this.state.sendDatasetId}
        ></ModalForm>
        <Modal
          show={this.state.showLineItemsData}
          onHide={this.handleModalLineItemsClose}
        >
          <Modal.Header closeButton>
            <h4>{this.state.showLineItemHeaderData}</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="scroll_height">
              <table className="table table-bordered table-hover line_item_popup">
                <thead>
                  <tr className="head-row">
                    {this.state.showLineItemData &&
                      this.state.showLineItemData.length
                      ? Object.keys(this.state.showLineItemData[0]).map(
                        (header, subIndex) => (
                          <th className="header" key={subIndex}>
                            {header.replace(/_/g, " ")}
                          </th>
                        )
                      )
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.showLineItemData &&
                    this.state.showLineItemData.length
                    ? this.state.showLineItemData.map((data, index) => {
                      return (
                        <tr key={index}>
                          {Object.keys(this.state.showLineItemData[0]).map(
                            (header, tbodyIndex) =>
                              typeof data[header] === "object" &&
                                data[header].length ? (
                                data[header].map((subData, index) => (
                                  <td key={index}>
                                    <a
                                      href={subData}
                                      target="_blank"
                                      download="true"
                                    >
                                      <FontAwesomeIcon
                                        icon={"fa fa-download"}
                                        className="me-2"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </td>
                                ))
                              ) : (
                                <td key={tbodyIndex}>{data[header]}</td>
                              )
                          )}
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Table);
