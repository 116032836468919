import React, { Component } from "react";
import { toast } from "react-toastify";
import Loader from "../../dataset/Loader/Loader";
import { fetchAllScheduleshistory } from "../../../services/appService";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Schedulehistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      isWrite: false,
      schedulehistoryData: [],
      loading: true,
      currentPage: 1,
      totalRecordCount: 0,
      dataPerPage: 20,
      searchText: "",
      headers: [
        "Schedule Name",
        "Schedule",
        "Activation Status",
        "App",
        "Logic",
        "Parameter (JSON)",
        "No. Of Retries",
        "Max Runtime",
        "Last Run Date",
        "Next Run Date",
        "Schedule Status",
        "Action",
      ],
      showDetailLog: false,
      searchQuery: {
        searchText: "",
        from_date: "",
        to_date: "",
      },
      modalDetail: {},
      filterCriteriaData: [],
    };
  }

  getScheduleHistoryListData() {
    // Check if page number is greater than 0 and then proceed to fetch the data
    if (this.state.currentPage <= 0) return;
  
    this.setState({ loading: true });
  
    const requestBody = {
      FilterCriteria: this.state.filterCriteriaData,
      SortCriteria: [],
      PaginationCriteria: {
        limit: this.state.dataPerPage,
        skip: (this.state.currentPage - 1) * this.state.dataPerPage,
      },
    };
  
    fetchAllScheduleshistory(requestBody)
      .then((response) => {
        if (this.mounted) {
          if (response.success) {
            this.setState({
              schedulehistoryData: response.data,
              totalRecordCount: response.totalcount,
            });
          } else {
            toast.error(response.message);
          }
        }
      })
      .catch((err) => {
        if (this.mounted) {
          toast.error(err.message);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  receiveCurrentPageNumber = (pageNumber) => {
    // Update current page and fetch new data
    this.setState({ currentPage: pageNumber }, () => {
      // This ensures the page number is updated before calling the data fetching method.
      this.getScheduleHistoryListData();
    });
  };

  handelSearchChange = (e) => {
    let searchQuery = { ...this.state.searchQuery };
    searchQuery[e.target.name] = e.target.value;
    this.setState({ searchQuery });
  };

  fetchAllDetails = () => {
    const { searchText, from_date, to_date } = this.state.searchQuery;

    let filterCriteriaData = [];
    if (searchText) {
      filterCriteriaData.push({
        filter_type: "text",
        type: "contains",
        filter: searchText,
        filter_field: "schedule_name",
      });
    }

    if (from_date && to_date) {
      filterCriteriaData.push(
        {
          type: "greater than",
          filter: from_date,  // Use from_date for 'greater than'
          filter_field: "last_run_time",
          filter_type: "text"
        },
        {
          type: "less than",
          filter: to_date,  // Use to_date for 'less than'
          filter_field: "last_run_time",
          filter_type: "text"
        }
      );
      
    }

    this.setState({
      filterCriteriaData,
      currentPage: 1,
    }, () => {
      this.getScheduleHistoryListData();
    });
  };

  clearButtonHandler = () => {
    this.setState({
      searchQuery: { searchText: "", from_date: "", to_date: "" },
      filterCriteriaData: [],
      currentPage: 1,
    }, () => {
      this.getScheduleHistoryListData();
    });
  };

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { standard_privileges } = this.state.userInfo.privileges;
      standard_privileges.forEach((data) => {
        if (data["History / Log"]) {
          this.setState({ isWrite: data["History / Log"].write });
        }
      });
    } else {
      this.setState({ isWrite: true });
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.getScheduleHistoryListData();
    this.getPrivilages();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onHideModal = () => {
    this.setState({ showDetailLog: false });
  };

  render() {
    const setHeaderData = this.state.isWrite
      ? this.state.headers.map((header, index) => (
          <th className="text-nowrap" key={index}>
            {header}
          </th>
        ))
      : this.state.headers
          .filter((header) => header !== "Action")
          .map((header, index) => (
            <th className="text-nowrap" key={index}>
              {header}
            </th>
          ));

    const setTableData = this.state.schedulehistoryData.map((tableData, index) => (
      <tr key={index}>
        <td>{tableData.schedule_name}</td>
        <td>{tableData.cron_string}</td>
        <td>{tableData.activation_status ? "Active" : "Inactive"}</td>
        <td>{tableData.app_name}</td>
        <td>{tableData.logic_name}</td>
        <td>{tableData.parameters}</td>
        <td>{tableData.no_of_retries}</td>
        <td>{tableData.max_run_time}</td>
        <td>{new Date(tableData.last_run_time).toLocaleString()}</td> {/* Format last_run_time */}
        <td>{tableData.next_run_time}</td>
        <td>{tableData.status}</td>
        {this.state.isWrite && (
          <td>
            <div className="action-icons">
              <FontAwesomeIcon
                icon={"fa fa-eye"}
                className="me-2"
                onClick={() =>
                  this.setState({
                    showDetailLog: true,
                    modalDetail: tableData,
                  })
                }
              />
            </div>
          </td>
        )}
      </tr>
    ));

    return (
      <div>
        <div className="d-help py-2">
          <div>
            <h5 className="text-nowrap">Schedule History</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-lg-2 col-12 col-sm-3">
            <div className="input-group input-group-sm">
              <span className="input-group-text" id="basic-addon1">
                <FontAwesomeIcon icon={"fa fa-search"} />
              </span>
              <input
                type="text"
                className="form-control"
                name="searchText"
                onChange={this.handelSearchChange}
                value={this.state.searchQuery.searchText}
                placeholder="Search Schedule History"
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-sm-6">
            <div className="input-group input-group-sm">
              <input
                type="date"
                className="form-control"
                name="from_date"
                value={this.state.searchQuery.from_date}
                onChange={this.handelSearchChange}
              />
              <span className="input-group-text">To</span>
              <input
                type="date"
                className="form-control"
                name="to_date"
                value={this.state.searchQuery.to_date}
                readOnly={!this.state.searchQuery.from_date}
                min={this.state.searchQuery.from_date}
                onChange={this.handelSearchChange}
              />
            </div>
          </div>

          <div className="col-md-2 col-lg-2 col-12 col-sm-3">
            <div className="d-flex">
              <button
                type="button"
                className="search-btn mb-0"
                onClick={this.fetchAllDetails}
                disabled={
                  this.state.searchQuery.searchText === "" &&
                  this.state.searchQuery.to_date === ""
                }
              >
                Search
              </button>
              <button
                type="button"
                className="search-btn mx-1"
                onClick={this.clearButtonHandler}
                disabled={
                  this.state.searchQuery.searchText === "" &&
                  this.state.searchQuery.to_date === ""
                }
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-12 ">
            <div className="table-responsive table-hover">
              {this.state.loading ? (
                <Loader />
              ) : (
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr className="head-row">{setHeaderData}</tr>
                  </thead>
                  <tbody>
                    {this.state.totalRecordCount !== 0 ? (
                      setTableData
                    ) : (
                      <tr>
                        <td
                          colSpan={this.state.headers.length}
                          className="no_table_records"
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className="dynamic_pagination justify-content-between">
          {this.state.totalRecordCount > 0 && (
            <div className="my-2">
              Showing {" "}
              {(this.state.currentPage - 1) * this.state.dataPerPage + 1} to {" "}
              {Math.min(
                this.state.currentPage * this.state.dataPerPage,
                this.state.totalRecordCount
              )} of {this.state.totalRecordCount} entries
            </div>
          )}
          {this.state.totalRecordCount > this.state.dataPerPage && !this.state.loading ? (
            <div className="dynamic_pagination my-2">
              <Pagination
                pageRangeDisplayed={3}
                activePage={this.state.currentPage}
                itemsCountPerPage={this.state.dataPerPage}
                totalItemsCount={this.state.totalRecordCount}
                onChange={this.receiveCurrentPageNumber}
              />
            </div>
          ) : null}
        </div>

        <Modal show={this.state.showDetailLog} onHide={this.onHideModal}>
          <Modal.Header closeButton>
            <h6>Log Details</h6>
          </Modal.Header>
          <Modal.Body>
            {this.state.modalDetail?.status === "Running" ? (
              <h4>{this.state.modalDetail.log}</h4>
            ) : null}

            {this.state.modalDetail?.status === "Completed" ? (
              <>
                <h4>{this.state.modalDetail.log}</h4>
                <h5>Duration: {this.state.modalDetail.duration}</h5>
              </>
            ) : null}

            {this.state.modalDetail?.status === "Server Interrupted" ? (
              <h4>{this.state.modalDetail.duration}</h4>
            ) : null}

            {this.state.modalDetail?.status === "Error" ? (
              <div>
                <h4>{this.state.modalDetail.log?.message}</h4>
                <h5>Node: {this.state.modalDetail.log?.node_name}</h5>
                <h5>Duration: {this.state.modalDetail.duration}</h5>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onHideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Schedulehistory;
