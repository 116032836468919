import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTenantDetail, upsertSingleRecord, filterThemeData } from "../../services/appService";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";


function Theme() {
  const history = useHistory();

  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true);

  const defaultColors = {
    primaryColor: "#2196F3",
    secondaryColor: "#607D8B",
    backgroundColor: "#f6f7fb",
    textColor: "#000",
    submitButtonColor: "#198754",
    chooseFileButtonColor: "#2196F3",
    importButtonColor: "#0dcaf0",
    exportButtonColor: "#0d6efd",
    backButtonColor: "#6c757d",
    addButtonColor: "#6c757d",
    cancelButtonColor: "#dc3545",
    testLogicButtonColor: "#0d6efd",
    uninstallButtonColor: "#dc3545",
    installButtonColor: "#198754",
    boxBackgroundColor : "#ffffff",
    navMenuBackgroundColor : "#ffffff",
    logoBackgroundColor : "#ffffff",
  };

  const [colors, setColors] = useState(defaultColors);

  const getThemingData = async () => {
    const obj = {
      FilterCriteria: [],
      SortCriteria: [],
      PaginationCriteria: {
        limit: 10,
        skip: 0,
      },
    };

    try {
      const response = await filterThemeData(obj);
      if (response && response.data.length > 0) {
        const { _id, ...colorData } = response?.data[0] || {};
        setFilterData(response.data[0]);
        setColors({
          ...defaultColors,
          ...colorData,
        });
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getThemingData();
  }, []);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleColorChange = (color, field) => {
    setColors((prevColors) => ({
      ...prevColors,
      [field]: color,
    }));
  };

  const resetColor = (field) => {
    confirmAlert({
      message: `Are you sure you want to reset the color for "${field.replace(/([A-Z])/g, " $1")}" to the default color?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setColors((prevColors) => ({
              ...prevColors,
              [field]: defaultColors[field],
            }));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const resetAllColors = () => {
    confirmAlert({
      message: "Are you sure you want to reset all colors to the default colors?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setColors(defaultColors);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const addProfile = async (obj) => {
    try {
      const response = await upsertSingleRecord(obj);
      if (response) {
        toast.success(response.message);
        window.location.reload();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const submitCompanyDetails = (event) => {
    event.preventDefault();

    const tenantId = JSON.parse(localStorage.getItem("tenantId")) || null;
    const userId = JSON.parse(localStorage.getItem("userid")) || null;

    if (!tenantId || !userId) {
      toast.error("User or tenant information is missing.");
      return;
    }

    const obj = {
      tenant_id: tenantId,
      dataset_name: "fc_theme",
      object_id: filterData?._id?.$oid || "",
      user_id: userId,
      fields: { ...colors },
    };

    addProfile(obj);
    localStorage.setItem("colors", JSON.stringify(colors));
  };

  return (
    <div>
      <div className="d-help py-2">
        <h5>Theme</h5>
        <button
          type="button"
          className="back-btn m-0"
          onClick={handleBackClick}
        >
          <FontAwesomeIcon icon="fa fa-arrow-left" />
          <span className="ms-1">Back</span>
        </button>
      </div>

      {loading ? (
        <div className="text-center py-5">Loading...</div>
      ) : (
        <div className="border border-secondary border-opacity-25 p-3 bg-white mb-2">
          {Object.keys(colors).map((field) => (
            <div className="form-group row align-items-center mb-3" key={`color-${field}`}>
              <label className="col-form-label col-md-3 col-sm-3 text-capitalize">
                {field.replace(/([A-Z])/g, " $1")}
              </label>
              <div className="col-md-3 col-sm-3 d-flex align-items-center">
                <input
                  type="color"
                  className="form-control form-control-color form-control-sm me-2"
                  value={colors[field]}
                  onChange={(e) => handleColorChange(e.target.value, field)}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Selected Color"
                  value={colors[field]}
                  onChange={(e) => handleColorChange(e.target.value, field)}
                />
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary m-2"
                  onClick={() => resetColor(field)}
                  title="Reset to Default"
                >
                  <FontAwesomeIcon icon="fa fa-undo" />
                </button>
              </div>
            </div>
          ))}
          <div className="text-end">
          <button
              type="button"
              className="btn btn-md btn-outline-danger m-0"
              onClick={resetAllColors}
            >
              Reset All
            </button>
            <button
              type="submit"
              className="custom-btn m-0 ms-2"
              onClick={submitCompanyDetails}
            >
              <FontAwesomeIcon icon="fa-regular fa-circle-check" />
              &nbsp;Apply&nbsp;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Theme;
