import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { getApiLogs } from "../../services/appService";
import Loader from "../dataset/Loader/Loader";
import "./ApiLogs.scss";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom"
import { toast } from "react-toastify";

const ApiLogs = (props) => {
  const [tableHeadings, setTableHeadings] = useState([
    "#",
    "Host name",
    "Host ID",
    "Request",
    "Success",
    "Message",
    "HTTP code",    
    "Logs",
    "Apikey",
    "Current Date & Time",
    "IP",
    "Remote Add",
    "x-real-ip",
    "x-forwarded-for",
    "app_name",
    "logic_name",
    "duration"
  ]);

  const [apiLogs, setApiLogs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalRecords, setTotalRecords] = useState(0);

  const [dataPerPage, setdataPerPage] = useState(20);

  useEffect(() => {
    setIsLoading(true);    
    const requestBody = {
      FilterCriteria: [],
      SortCriteria: [],
      PaginationCriteria: {
        limit: dataPerPage,
        skip: (currentPage - 1) * dataPerPage,
      },
    };  
    getApiLogs(requestBody)
      .then((response) => {
        if (response.success) {
          console.log(response,"--------")
          setApiLogs(response.data);
          setTotalRecords(response.totalcount);
          setIsLoading(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
  }, [currentPage]);

  const setHeaderData = tableHeadings.map((header, index) => (
    <th key={index} className="text-nowrap head-row">
      {header}
    </th>
  ));

  const showPopupModal = (data) => {
    confirmAlert({
      title: "Request Details",
      message: (
        <pre>
          {(data)}
        </pre>
      ),
      buttons: [
        {
          label: "Close",
          onClick: () => {},
        },
      ],
    });
  }; 

  const setTableData = apiLogs.map((data, index) => {
    return (
      <tr key={index} className="text-nowrap">
        <td>
          {index +
            1 +
            totalRecords * (currentPage - 1) -
            (totalRecords - dataPerPage) * (currentPage - 1)}
        </td>
        <td>{data.host_name}</td>
        <td>{data.host_id}</td>
        <td>
          {typeof data?.request === "object" ? (
            <span              
              onClick={() => showPopupModal(JSON.stringify(data?.request, null, 2))}
            >
              <Link to="#">{JSON.stringify(data?.request).slice(0, 40)}...</Link>
            </span>
          ) : (
            <span></span>
          )}
        </td>        
        <td>{String(data?.response?.success)}</td>
        <td>{data?.response?.message}</td>
        <td>{data?.response?.http_code}</td>
        <td>{data.count}</td>
        <td>
          {data?.api_key?.substring(0, 2)}**********
          {data?.api_key?.substring(
            parseInt(data.api_key.length),
            parseInt(data.api_key.length - 4)
          )}
        </td>
        <td>{data.time}</td>
        <td>{data?.response?.ip}</td>
        <td>{data?.response?.remote_add}</td>
        <td>{String(data?.response?.["x-real-ip"])}</td>
        <td>{String(data?.response?.["x-forwarded-for"])}</td>
        <td>{data?.app_name}</td>
        <td>{data?.logic_name}</td>
        <td>{data?.duration}</td>
      </tr>
    );
  });  

  const receiveCurrentPageNumber = (langValue) => {
    setCurrentPage(langValue);
  };

  return (
    <div>
      <div className="d-help py-2 flex-wrap">
        <h5 className="m-0">API logs</h5>
        <button
          type="button"
          className="back-btn m-0"
          onClick={(e) => {
            e.preventDefault();
            props?.history?.goBack();
          }}
        >
          <FontAwesomeIcon icon="fa fa-arrow-left" />
          <span className="ms-1">Back</span>
        </button>
      </div>
      <div>
        <div className="table-responsive">
          {isLoading ? (
            <Loader />
          ) : (
            <table className="table table-bordered my-2">
              <thead>
                <tr>{setHeaderData}</tr>
              </thead>
              <tbody>
                {apiLogs.length ? (
                  setTableData
                ) : (
                  <tr>
                    <td colSpan="12">
                      <h5>No records found</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="dynamic_pagination justify-content-between">
          {totalRecords > 0 && (
            <div className="my-2">
              Showing {" "}
              {(currentPage - 1) * dataPerPage + 1} to {" "}
              {Math.min(
                currentPage * dataPerPage,
                totalRecords
              )} of {totalRecords} entries
            </div>
          )}
          {totalRecords > 20 && (
            <div className="dynamic_pagination my-2">
              <Pagination
                pageRangeDisplayed={3}
                activePage={currentPage}
                itemsCountPerPage={20}
                totalItemsCount={totalRecords}
                onChange={receiveCurrentPageNumber}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    
  );
};

export default ApiLogs;
