import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  displayComponentType,
  displayComponentTypeList,
} from "../../../common/model/Model";
import { useAccordionButton } from "react-bootstrap/esm/AccordionButton";
import { v4 as uuidv4 } from "uuid";
import { Accordion, Card, Form } from "react-bootstrap";
import DisplayBuilderGraph from "./DisplayBuilderGraph";
import DisplayBuilderTable from "./DisplayBuilderTable";
import DisplayBuilderHeader from "./DisplayBuilderHeader";
import DisplayBuilderCustomSection from "./DisplayBuilderCustomSection";
import DisplayBuilderCard from "./DisplayBuilderCard";
import DisplayBuilderSummary from "./DisplayBuilderSummary";
import DisplayBuilderCalender from "./DisplayBuilderCalender";
import DisplayBuilderFilterForm from "./DisplayBuilderFilterForm";
import DisplayBuilderStepperForm from "./DisplayBuilderStepperForm";
import DisplayBuilderScheduleList from "./DisplayBuilderScheduleList";
import DisplayBuilderKanban from "./displayBuilderKanban/DisplayBuilderKanban";
import DisplayBuilderProgressList from "./DisplayBuilderProgressList";
import DisplayBuilderGroupList from "./DisplayBuilderGroupList";
import DisplayBuilderHierarchyTable from "./DisplayBuilderHierarchyTable";
import DisplayTransition from "../display_transition/DisplayTransition";
import {
  formPagesList,
  getLogicsByAppId,
  getPagesByAppId,
} from "../../../../services/appService";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-quill/dist/quill.snow.css";
import "./DisplayBuilder.scss";
import DisplayBuilderPrintableView from "./DisplayBuilderPrintableView";
import DisplayBuilderDynamicTable from "./DisplayBuilderDynamicTable";

import DisplayBuilderListView from "./DisplayBuilderListView";
import DisplayBuilderViewPage from "./DisplayBuilderViewPage";
import DisplayBuilderGoogleMap from "./DisplayBuilderGoogleMap";

import DisplayBuilderEchart from "./DisplayBuilderEchart";

const DisplayBuilder = forwardRef((props, ref) => {
  const [pageType] = useState(displayComponentTypeList);
  const [columnWidth] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [displayComponentData, setDisplayComponentData] = useState([
    {
      componentType: "Graph",
      columenWidth: 12,
      columnWidthTable: 12,
      headerTitle: "",
      summaryTitle: "",
      htmlCode: "",
      card: 12,
      summaryCard: 12,
      dataAttribute: "",
      displayAttribute: "",
      refValue: "",
      listRefValue: "",
      tableName: "",
      listName: "",
      formId: "",
      uploadFile: "",
      component_id: uuidv4(),
      viewAsDashboard: false,
      showExport: true,
      showPagination: true,
      showFilter: true,
      transitionType: "",
      transitionAppId: "",
      transitionPageId: "",
      transitionActionTitle: "",
      transitionSelectedIcon: "",
      selectedLogic: "",
      disclaimerNote: "",
      printTemplate: "",
      sectionNames: [],
      isCacheEnabled: false,
    },
  ]);
  const [tableActionContent, setTableActionContent] = useState([
    {
      actionName: "Update",
      referenceValue: "",
      logicNameId: "",
      logicNameValue: "",
      selectPage: "",
      buttonPlacement: "Table",
      buttonColor: "#000000",
      updateOnTable: "No",
      action_id: uuidv4(),
    },
    {
      actionName: "View",
      referenceValue: "",
      logicNameId: "",
      logicNameValue: "",
      selectPage: "",
      buttonPlacement: "Table",
      buttonColor: "#000000",
      viewOnTable: "No",
      action_id: uuidv4(),
    },
    {
      actionName: "Clone",
      referenceValue: "",
      logicNameId: "",
      logicNameValue: "",
      selectPage: "",
      buttonPlacement: "Table",
      buttonColor: "#000000",
      updateOnTable: "No",
      action_id: uuidv4(),
    },
  ]);
  const [appLogicArr, setAppLogicArr] = useState([]);
  const [formsList, setFormsList] = useState([]);
  const [activeAccordian, setActiveAccordian] = useState(0);

  useImperativeHandle(ref, () => ({
    sendDisplaydataToParent,
  }));

  useEffect(() => {
    getAllFormList();
    if (props?.sendDisplayData?.length) {
      setDisplayComponentData(props?.sendDisplayData);
    }
  }, [props?.sendDisplayData]);

  useEffect(() => {
    if (props?.appId !== "") {
      getAppLogicData();
    }
  }, [props?.appId]);

  function getAllFormList() {
    let bodyObj = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: "form_component",
          filter_field: "page_type",
        },
      ],
      export: true,
    };
    formPagesList(bodyObj)
      .then((response) => {
        if (response?.success) {
          setFormsList(
            response.data?.map((form) => {
              let { _id, function_name } = form;
              return { _id, function_name };
            })
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  const getAppLogicData = () => {
    getLogicsByAppId(props?.appId)
      .then((response) => {
        if (response.success === true) {
          setTableActionContent([
            {
              actionName: "Update",
              referenceValue: "",
              logicNameValue: "",
              logicNameId: "",
              selectPage: "",
              buttonPlacement: "Table",
              buttonColor: "#000000",
              updateOnTable: "No",
              action_id: uuidv4(),
            },
            {
              actionName: "View",
              referenceValue: "",
              logicNameValue: "",
              logicNameId: "",
              selectPage: "",
              buttonPlacement: "Table",
              buttonColor: "#000000",
              viewOnTable: "No",
              action_id: uuidv4(),
            },
            {
              actionName: "Clone",
              referenceValue: "",
              logicNameValue: "",
              logicNameId: "",
              selectPage: "",
              buttonPlacement: "Table",
              buttonColor: "#000000",
              updateOnTable: "No",
              action_id: uuidv4(),
            },
          ]);
          setAppLogicArr(response.data);
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // add new type
  const addNewDisplayTypeComponent = () => {
    let obj = {
      componentType: "Graph",
      columenWidth: 12,
      columnWidthTable: 12,
      headerTitle: "",
      htmlCode: "",
      card: 12,
      summaryCard: 12,
      refValue: "",
      listRefValue: "",
      selectedLogic: "",
      tableName: "",
      listName: "",
      formId: "",
      uploadFile: "",
      errors: {},
      actionDetails: [
        {
          actionName: "Update",
          referenceValue: "",
          logicNameValue: "",
          logicNameId: "",
          selectPage: "",
          buttonPlacement: "Table",
          buttonColor: "#000000",
          updateOnTable: "No",
          action_id: uuidv4(),
        },
        {
          actionName: "View",
          referenceValue: "",
          logicNameValue: "",
          logicNameId: "",
          selectPage: "",
          buttonPlacement: "Table",
          buttonColor: "#000000",
          viewOnTable: "No",
          action_id: uuidv4(),
        },
      ],
      component_id: uuidv4(),
      viewAsDashboard: false,
      showExport: true,
      showPagination: true,
      showFilter: true,
      transitionType: "",
      transitionAppId: "",
      transitionPageId: "",
      disclaimerNote: "",
      sectionNames: [],
    };
    const rows = [...displayComponentData, obj];
    setDisplayComponentData(rows);
  };

  // remove type
  function removeDiv(currentElement) {
    if (displayComponentData?.length - 1 > 0) {
      const newDisplayComponentData = displayComponentData?.filter(
        (_, index) => index !== currentElement
      );
      setDisplayComponentData(newDisplayComponentData);
    }
  }

  // edit functionality
  function changeValue(e, index) {
    const rows = [...displayComponentData];
    let name = e.target.name;
    let value = e.target.value;
    rows[index][name] = value;
    setDisplayComponentData(rows);
  }

  // drag feature
  function handleOnDragEndAddPage(result) {
    if (!result.destination) return;
    const items = Array.from(displayComponentData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDisplayComponentData(items);
  }

  // submitDataToParent
  function sendDisplaydataToParent() {
    let errorValueFlag = true;
    displayComponentData.map((data, index) => {
      if (
        data.componentType !== displayComponentType.filterForm && data.componentType !== displayComponentType.stepperForm &&
        data.componentType !== displayComponentType.customSection
      ) {
        if (displayComponentData[index]?.refValue.length <= 0) {
          toast.error(
            "Logic Variable Name Cannot Be Empty" +
              " At Component " +
              (index + 1)
          );
          errorValueFlag = false;
          return false;
        }
      }

      if (
        data.componentType === displayComponentType.table ||
        data.componentType === displayComponentType.hierarchyTable
      ) {
        if (displayComponentData[index]?.tableName?.length <= 0) {
          errorValueFlag = false;
          toast.error(
            "Table Name Cannot Be Empty" + " At Component " + (index + 1)
          );
          return false;
        }
      }

      if (data.componentType === displayComponentType.listView) {
        if (displayComponentData[index]?.listName?.length <= 0) {
          errorValueFlag = false;
          toast.error(
            "List Name Cannot Be Empty" + " At Component " + (index + 1)
          );
          return false;
        }
      }
      if (data.componentType === "Header") {
        if (displayComponentData[index]?.headerTitle.length <= 0) {
          errorValueFlag = false;
          toast.error(
            "Header Title Cannot Be Empty" + " At Component " + (index + 1)
          );
          return false;
        }
      }
      if (data.componentType === "Calendar") {
        if (!displayComponentData[index]?.selectedLogic?.["value"]) {
          errorValueFlag = false;
          toast.error("Logic Cannot Be Empty" + " At Component " + (index + 1));
          return false;
        }
      }
    });
    if (errorValueFlag) {
      props?.returnData(displayComponentData);
    }
  }

  function displayTransitionChangeValue(displayTransitionData, index) {
    let rows = [...displayComponentData];
    rows[index] = { ...rows[index], ...displayTransitionData };
    setDisplayComponentData(rows);
  }

  function onDisplayDataChange(changedObject, index) {
    let rows = [...displayComponentData];
    rows[index] = { ...rows[index], ...changedObject };
    setDisplayComponentData(rows);
  }

  function ToggleAccordion({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <button
        type="button"
        className="accordion_button_toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  // page type dropdown
  const pageTypeList = pageType.map((data, index) => (
    <option key={index} value={data?.value}>
      {data?.label}
    </option>
  ));
  // column width dropdown
  const columnWidthList = columnWidth.map((data, index) => (
    <option key={index} value={data}>
      {data}
    </option>
  ));

  const selectFormList = formsList?.map((data, index) => (
    <option key={index} value={data._id.$oid}>
      {data?.function_name}
    </option>
  ));

  const logicList = appLogicArr?.map((actionName) => {
    return {
      label: actionName.function_name,
      value: actionName?._id?.$oid,
    };
  });

  displayComponentData.forEach((objData, i) => {
    if (!objData.actionDetails && objData.componentType === "Table") {
      objData["actionDetails"] = tableActionContent;
    } else if (
      objData.actionDetails &&
      objData.actionDetails[0]["actionName"] !== "Update" &&
      objData.actionDetails[0]["actionName"] !== "Clone"
    ) {
      objData["actionDetails"] = [
        ...tableActionContent,
        ...objData["actionDetails"],
      ];
    }
  });

  return (
    <div className="display_component bg-white border border-secondary-subtle border-opacity-25 sort_app scroll-view p-2">
      <div className="m-0">
        <div className="col-md-12 mt-2">
          <h5 className="my-2">
            Display Component ({displayComponentData?.length})
          </h5>
          <DragDropContext onDragEnd={handleOnDragEndAddPage}>
            <Droppable droppableId="characters">
              {(provided) => (
                <ul
                  className="characters p-0 m-0"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {displayComponentData.map((objData, i) => {
                    return (
                      <Draggable
                        key={objData + i}
                        draggableId={objData + i}
                        index={i}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="mb-2 border-0 p-0 list"
                          >
                            <Accordion
                              activeKey={activeAccordian + ""}
                              className="w-100"
                              defaultActiveKey={"0"}
                              onSelect={(e) => {
                                setActiveAccordian(e);
                              }}
                            >
                              <Card>
                                <Card.Header className="border-bottom-0">
                                  <div className="d-help col-12">
                                    <div className="flex-fill d-help">
                                      <div className="flex-fill">
                                        <div className="pt-2 pb-2 ps-2 pe-0">
                                          <h6 className="m-0 title">
                                            {i + 1}. Component (
                                            {
                                              pageType?.find(
                                                (type) =>
                                                  type.value ===
                                                  objData.componentType
                                              )?.label
                                            }
                                            )
                                          </h6>
                                        </div>
                                      </div>

                                      <div>
                                        <span
                                          {...provided.dragHandleProps}
                                          role="none"
                                        >
                                          <FontAwesomeIcon
                                            className="me-4"
                                            icon={"fa fa-bars"}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        {displayComponentData?.length > 1 && (
                                          <FontAwesomeIcon
                                            icon={"fa fa-minus-circle"}
                                            title="Remove"
                                            className="pointer text-danger add_remove_size ms-0 me-4"
                                            aria-hidden="true"
                                            onClick={() => removeDiv(i)}
                                          />
                                        )}
                                        <FontAwesomeIcon
                                          icon={"fa fa-plus-circle"}
                                          title="Add"
                                          className="pointer text-success add_remove_size ms-0 me-3"
                                          aria-hidden="true"
                                          onClick={() =>
                                            addNewDisplayTypeComponent()
                                          }
                                        />
                                      </div>
                                    </div>
                                    <ToggleAccordion eventKey={i + ""}>
                                      <FontAwesomeIcon
                                        icon={
                                          activeAccordian == i
                                            ? "fa fa-chevron-up"
                                            : "fa fa-chevron-down"
                                        }
                                        role="button"
                                      />
                                    </ToggleAccordion>
                                  </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey={i + ""}>
                                  <Card.Body className="p-0">
                                    <div className="main_display_content w-100 p-2 bg-white">
                                      <div className="row">
                                        <div className="col-md-4 form-group">
                                          <label className="form-label p-0 mt-2 required">
                                            Select Component Type
                                          </label>
                                          <div>
                                            <Form.Select
                                              size="sm"
                                              name="componentType"
                                              onChange={(e) => {
                                                changeValue(e, i);
                                              }}
                                              value={objData.componentType}
                                              className="form-control"
                                            >
                                              {pageTypeList}
                                            </Form.Select>
                                          </div>
                                        </div>

                                        {objData.componentType ===
                                        displayComponentType.graph ? (
                                          <DisplayBuilderGraph
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.echart ? (
                                          <DisplayBuilderEchart
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.table ? (
                                          <DisplayBuilderTable
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                            logicList={logicList}
                                            formsList={formsList}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.listView ? (
                                          <DisplayBuilderListView
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                            logicList={logicList}
                                            formsList={formsList}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.header ? (
                                          <DisplayBuilderHeader
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.customSection ? (
                                          <DisplayBuilderCustomSection
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.cards ? (
                                          <DisplayBuilderCard
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.summary ? (
                                          <DisplayBuilderSummary
                                            logicList={logicList}
                                            displaySummary={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                        displayComponentType.calendar ? (
                                          <DisplayBuilderCalender
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                            logicList={logicList}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                          displayComponentType.filterForm && (
                                          <DisplayBuilderFilterForm
                                            displayFilterFormObj={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.stepperForm && (
                                          <DisplayBuilderStepperForm
                                            displayFilterFormObj={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        )}

                                        {objData.componentType ===
                                        displayComponentType.scheduleList ? (
                                          <DisplayBuilderScheduleList
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                            logicList={logicList}
                                          />
                                        ) : null}

                                        {objData.componentType ===
                                          displayComponentType.kanban && (
                                          <DisplayBuilderKanban
                                            displayKanbanObj={objData}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                            logicList={logicList}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.progressList && (
                                          <DisplayBuilderProgressList
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                            logicList={logicList}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.scheduleGroupList && (
                                          <DisplayBuilderGroupList
                                            displayBuilder={objData}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                            logicList={logicList}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.hierarchyTable && (
                                          <DisplayBuilderHierarchyTable
                                            formsList={formsList}
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.printableView && (
                                          <DisplayBuilderPrintableView
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.dynamicTable && (
                                          <DisplayBuilderDynamicTable
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.viewPage && (
                                          <DisplayBuilderViewPage
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            selectFormList={selectFormList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        )}

                                        {objData.componentType ===
                                          displayComponentType.googleMap && (
                                          <DisplayBuilderGoogleMap
                                            logicList={logicList}
                                            displayBuilder={objData}
                                            columnWidthList={columnWidthList}
                                            onChange={(changeObject) => {
                                              onDisplayDataChange(
                                                changeObject,
                                                i
                                              );
                                            }}
                                          />
                                        )}
                                      </div>

                                      {objData.componentType !==
                                        displayComponentType.filterForm && (
                                        <div className="p-2 border mt-2">
                                          <DisplayTransition
                                            value={objData}
                                            onChange={(obj) =>
                                              displayTransitionChangeValue(
                                                obj,
                                                i
                                              )
                                            }
                                            transitionTitle="Display Transition"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
});

export default DisplayBuilder;
