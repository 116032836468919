import axios from "./axiosApp";
import nojwtInstance from "./loginAxios";
import { serviceUrls } from "./serviceUrls";
import appInstance from "./axiosApp";

const tenantId = JSON.parse(localStorage.getItem("tenantId"));

export const fetchInstallAppData = async (
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().getInstallAppsData, config);
};

export const fetchInstallAppDataBadegs = async (
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().getInstallAppsBadges, config);
};

export const fetachAllApps = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().getAllApps, config);
};

export const fetchPageListData = (
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(serviceUrls().getPageList, config);
};

export const fetchAppsFlows = (config = null, axiosInstance = appInstance) => {
  return axiosInstance.get(serviceUrls().getAppsFlows, config);
};

export const headerSearchBox = (
  app_Id,
  flow_id,
  logic_id,
  appSessionId,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    serviceUrls().headerSearch +
    "&app_id=" +
    app_Id +
    "&flow_id=" +
    flow_id +
    "&logic_id=" +
    logic_id +
    "&app_session_id=" +
    appSessionId,
    config
  );
};

export const fetchSearchAppsFlows = (
  config = null,
  axiosInstance = appInstance
) => {
  return axiosInstance.get(serviceUrls().getSearchAppFlows, config);
};

export const createNewApp = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().createApp, body, config);
};

export const fetchAppLogic = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(serviceUrls().getAppsLogics, body, config);
};

export const fetchAppsServices = (
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(serviceUrls().getAppsServices, config);
};

export const installNewApp = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().installApp, body, config);
};

export const uninstallNewApp = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().uninstallApp, body, config);
};

export const getPagesByAppId = (
  appId,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().getPageByAppId +
    "?app_id=" +
    appId +
    "&dataset_name=fc_app_pages" +
    "&tenant_id=" +
    tenant_id +
    ""
  );
};

export const getLogicsByAppId = (
  appId,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().getLogicByAppId +
    "?app_id=" +
    appId +
    "&dataset_name=fc_app_logics" +
    "&tenant_id=" +
    tenant_id +
    ""
  );
};

export const getFlowsByAppId = (
  appId,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().getFlowByAppId +
    "?app_id=" +
    appId +
    "&dataset_name=fc_app_flows" +
    "&tenant_id=" +
    tenant_id +
    ""
  );
};

export const getDynamicFlowData = (
  appId,
  appSessionId,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().getFlowById +
    "?app_id=" +
    appId +
    "&dataset_name=fc_app_flows" +
    "&tenant_id=" +
    tenant_id +
    "&app_session_id=" +
    appSessionId
  );
};

export const getDynamicSingleFlowData = (
  appId,
  flowId,
  appSessionId,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().getFlowById +
    "?app_id=" +
    appId +
    "&flow_id=" +
    flowId +
    "&dataset_name=fc_app_flows" +
    "&tenant_id=" +
    tenant_id +
    "&app_session_id=" +
    appSessionId
  );
};

export const newLogin = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().login, body, config);
};

export const changePassword = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().changePassword, body, config);
};

export const resetPassWord = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().resetPassWord, body, config);
};

export const profileUpdate = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().profileUpdate, body, config);
};

export const updateViewData = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().updateViewData, body, config);
};

export const mentionUserEmail = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().mentionUserEmail, config);
};

export const upsertComment = (
  body,
  config = null,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.post(
    serviceUrls().upsertComment +
    "?tenant_id=" +
    tenant_id +
    "" +
    "&dataset_name=" +
    body.dataset_name,
    body,
    config
  );
};

export const tenantUserLogin = (
  body,
  tenantName,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(
    serviceUrls().tenantLogin + "?tenant_name=" + tenantName + "",
    body,
    config
  );
};

export const executeAppEngine = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().executeApp, body, config);
};

export const upsert_schedule_record = (
  body,
  config = null,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId")),
  userid = JSON.parse(localStorage.getItem("userid")),
  role = JSON.parse(localStorage.getItem("role"))
) => {
  let body_Object = {
    ...body,
    tenant_id: tenant_id,
    dataset_name: body.dataset_name,
    fields: { ...body.fields, user_id: userid, role_name: role },
  };
  return axiosInstance.post(
    serviceUrls().upsert_schedule_record,
    body_Object,
    config
  );
};

export const saveAppEngineData = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().saveAppData, body, config);
};
export const createNewTenant = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().createTenant, body, config);
};

export const createNewCustomerTenant = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().createCustomerTenant, body, config);
};

export const createNewRole = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().addRoles, body, config);
};

export const deleteRole = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.delete(serviceUrls().getDeleteRoles, body, config);
};

export const getRoleData = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().getDeleteRoles, body, config);
};

export const createPrivilege = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().addPrivilege, body, config);
};

export const deletePrivilegeData = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.delete(serviceUrls().getDeletePrivileges, body, config);
};

export const updatePrivileges = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceUrls().addPrivilege, body, config);
};

export const getPrivilegeData = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().getDeletePrivileges, body, config);
};

export const addNewUser = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().addUser, body, config);
};

export const updateUser = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.patch(serviceUrls().addUser, body, config);
};
export const deleteUser = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.delete(serviceUrls().deleteUser, body, config);
};

export const updateRole = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.patch(serviceUrls().addRoles, body, config);
};

export const checkSameValueData = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().checkKeyExistAppFlow + "&dataset_name=" + body.dataset_name,
    body.fields,
    config
  );
};

export const getManager = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().getManager, body, config);
};

export const fetchSchedules = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().getSchedules, config);
};

export const fetchAllSchedules = (config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().getAllSchedules, config);
};


export const fetchScheduleshistory = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().getScheduleshistory, config);
};


export const fetchAllScheduleshistory = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getAllScheduleshistory + "?dataset_name=sys_schedule_log" + "&tenant_id=" + JSON.parse(localStorage.getItem("tenantId")), body,config);
};




export const getRecordById = (
  bodyObj,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    serviceUrls().getRecordById +
    "&object_id=" +
    bodyObj.id +
    "&dataset_name=" +
    bodyObj.dataset_name,
    config
  );
};

export const getRecordByIdForStepper = (
  { object_id },
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    `${serviceUrls().getRecordById}&object_id=${object_id}&dataset_name=fc_app_pages`,
    config
  );
};


export const deleteScheduleRecord = (
  bodyObj,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().deleteScheduleRecord,
    bodyObj,
    config
  );
};

export const runSchedule = (
  scheduleId,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    serviceUrls().schedule_run + "&object_id=" + scheduleId,
    config
  );
};

export const generateAuthKey = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().generateAuthKey, config);
};

export const getUserEmails = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().getUserEmails, config);
};

export const generateAppLink = (
  body,
  config = null,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  let bodyObj = {
    ...body,
    tenant_id: tenant_id,
  };
  return axiosInstance.post(serviceUrls().generateAppLink, bodyObj, config);
};

export const updateLinkStatus = (
  body,
  config = null,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  let bodyObj = {
    ...body,
    tenant_id: tenant_id,
  };
  return axiosInstance.patch(serviceUrls().generateAppLink, bodyObj, config);
};

export const runApp = (body, config = null, axiosInstance = nojwtInstance) => {
  return axiosInstance.post(serviceUrls().runApp, body, config);
};

export const getAllLinks = (
  appId,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    serviceUrls().getAllSharedAppLinks + `&app_id=${appId}`,
    config
  );
};

export const importDatasetlData = (
  bodyFormData,
  obj_id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().importDatasetlData +
    "&dataset_id=" +
    obj_id.parentDatasetId +
    "&object_id=" +
    obj_id.DataStructureObjId,
    bodyFormData,
    config
  );
};

export const pageClone = (
  body,
  app_id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().page_clone + "&app_id=" + app_id,
    body,
    config
  );
};

export const logicClone = (
  body,
  app_id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().logic_clone + "&app_id=" + app_id,
    body,
    config
  );
};

export const flowClone = (
  body,
  app_id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().flow_clone + "&app_id=" + app_id,
    body,
    config
  );
};

export const addNewApi = (
  body,
  config = null,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  let bodyObj = {
    ...body,
    tenant_id: tenant_id,
  };
  return axiosInstance.post(serviceUrls().addNewApi, bodyObj, config);
};

export const getAllApi = (
  body,
  config = null,
  axiosInstance = axios,
) => {
  return axiosInstance.post(
    serviceUrls().addNewApi,body,
    config
  );
};

export const editApi = (
  body,
  config = null,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  let bodyObj = {
    ...body,
    tenant_id: tenant_id,
  };
  return axiosInstance.patch(serviceUrls().addNewApi, bodyObj, config);
};

export const getApiById = (
  apiId,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    serviceUrls().getApiById + "&object_id=" + apiId,
    config
  );
};

export const getApiSpecification = (
  body,
  config = null,
  axiosInstance = axios
) => {
  let bodyObj = {
    ...body,
    tenant_name: JSON.parse(localStorage.getItem("query_param")),
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
  };
  return axiosInstance.post(serviceUrls().getApiSpecification, bodyObj, config);
};

export const getApiLogs = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().getApiLogsData,body,
    config
  );
};

export const getApiSpecificationForGet = (
  apiKey,
  logicName,
  config = null,
  axiosInstance = nojwtInstance,
  tenant_name = JSON.parse(localStorage.getItem("query_param"))
) => {
  return axiosInstance.get(
    serviceUrls().getApiSpecification +
    "&tenant_name=" +
    tenant_name +
    "&api_key=" +
    apiKey +
    "&logic_name=" +
    logicName,
    config
  );
};

export const getSearchedApiByName = (
  apiName,
  pageNo,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    serviceUrls().searchApiByName +
    "&api_name=" +
    apiName +
    "&page_no=" +
    pageNo,
    config
  );
};

export const getTenantDetails = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().getTenants, body, config);
};

export const addSubscription = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().userSubscription, body, config);
};

export const getTenantSubscription = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    serviceUrls().userSubscription +
    `?tenant_id=${body.id}&tenant_name=${body.name}`,
    body,
    config
  );
};

export const getAlertSubscription = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    serviceUrls().userSubscriptionAlert + `?tenant_id=${body.id}`,
    body,
    config
  );
};

export const fetchInstallAppWithPages = async (
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().fetchInstallAppWithPages, config);
};

export const getTenantDetail = (
  tenant_Name,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    serviceUrls().getTenantDetails + `?tenant_name=${tenant_Name}`,
    config
  );
};

export const getStoreDetails = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().storeListDetails, config);
};

export const addStoreDetails = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().storeListDetails, body, config);
};

export const updateStoreDetails = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceUrls().storeListDetails, body, config);
};

export const getProductList = (storeId, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().productList + "&store_id=" + storeId);
};

export const addProduct = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().productList, body, config);
};

export const updateProductDetails = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceUrls().productList, body, config);
};

export const cloneTenantData = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().cloneTenant, body, config);
};

export const fetchAttributeSetList = (
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(serviceUrls().getAttributeSetList, config);
};
export const fetchAttributeDetails = (
  attributeId,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().getFlowByAppId +
    "?id=" +
    attributeId +
    "&dataset_name=attributes" +
    "&tenant_id=" +
    tenant_id +
    ""
  );
};

export const upsertSingleRecord = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().upsertSingleRecord, body, config);
};

export const storeAttributeDetails = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().upsertAttributeSet, body, config);
};

export const logOut = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().logout, config);
};

export const getProductDetails = (
  appId,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().getFlowByAppId +
    "?id=" +
    appId +
    "&dataset_name=products" +
    "&tenant_id=" +
    tenant_id +
    ""
  );
};

export const fetchTagsByStoreId = (
  store_Id,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().fetchTagsByStoreId +
    "?store_id=" +
    store_Id +
    "&tenant_id=" +
    tenant_id +
    ""
  );
};

export const updateTagsByStoreId = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchTagsByStoreId, body, config);
};

export const fetchCategoryByStoreDetails = (
  store_Id,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.get(
    serviceUrls().fetchCategoryStore +
    "?tenant_id=" +
    tenant_id +
    "&store_id=" +
    store_Id +
    ""
  );
};

export const fetchproductByCategory = (
  body,
  storeId,
  config = null,
  axiosInstance = axios,
  tenant_id = JSON.parse(localStorage.getItem("tenantId"))
) => {
  return axiosInstance.post(
    serviceUrls().fetchRelatedProductFromCategories +
    "?tenant_id=" +
    tenant_id +
    "&store_id=" +
    storeId,
    body,
    { cancelToken: config }
  );
};

export const uploadFile = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().uploadFileToAws, body, config);
};

export const createCategory = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().category, body, config);
};

export const updateCategory = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.patch(serviceUrls().category, body, config);
};

export const getCategorySet = (
  store_id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    `${serviceUrls().categorySet}&store_id=${store_id}`,
    config
  );
};

export const setCategorySet = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(`${serviceUrls().categorySet}`, body, config);
};

export const getStoreDetailsById = (
  store_id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    `${serviceUrls().getStoreDetails}&store_id=${store_id}`,
    config
  );
};

export const exportProductList = (
  perms,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    `${serviceUrls().exportImportProduct}&store_id=${perms.store_id
    }&category_id=${perms.category_id}`,
    config
  );
};

export const importProductList = (
  store_id,
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    `${serviceUrls().exportImportProduct}&store_id=${store_id}`,
    body,
    config
  );
};

export const getDataById = (
  category_id,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    `${serviceUrls().getCategorieById
    }&dataset_name=categories&id=${category_id}`,
    config
  );
};

export const getFilterdProductList = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(
    `${serviceUrls().filterProductsRecord}&dataset_name=product_filter`,
    body,
    config
  );
};

export const getStoreAttributes = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(
    `${serviceUrls().filterProductsRecord}&dataset_name=attributes`,
    body,
    config
  );
};

export const getAllDropdownList = (
  object,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(
    `${serviceUrls().filterProductsRecord}&dataset_name=${object.datasetName}`,
    object.body,
    config
  );
};

export const getAllUserList = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().allUserList, config);
};

export const saveFiltersList = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().saveFilters, body, config);
};

export const getSavedFilterdList = (
  body,
  datasetName,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(
    `${serviceUrls().filterProductsRecord}&dataset_name=${datasetName}`,
    body,
    config
  );
};

export const importFieldsDataset = (config = null, axiosInstance = axios) => {
  return axiosInstance.get(`${serviceUrls().importDatasetFields}`, config);
};

export const importLogicTableData = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(`${serviceUrls().importLogicData}`, body, config);
};

export const fetchlogicDataByDataset = (
  datasetName,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    `${serviceUrls().getAllLogicData}&dataset_name=${datasetName}`,
    config
  );
};

export const formPagesList = (
  bodyObj,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(`${serviceUrls().form_page_list}`, bodyObj, config);
};

export const productSubscriptionValidation = (
  product_count,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    `${serviceUrls().productValidation
    }?tenant_id=${tenantId}&product_count=${product_count}`,
    config
  );
};

export const getSingleProductDetails = (
  bodyData,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    serviceUrls().singleProduct +
    "?id=" +
    bodyData.id +
    "&tenant_id=" +
    bodyData.tenant_id +
    ""
  );
};

// add product to whishlist
export const addProductToWishlist = (
  bodyData,
  tenant_Id,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().addProductToWishlist + "?tenant_id=" + tenant_Id,
    bodyData
  );
};

export const filterData = (
  bodyData,
  datasetName,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(
    serviceUrls().filterQueryData + "&dataset_name=" + datasetName,
    bodyData
  );
};

export const filterThemeData = (
  bodyData,
  datasetName,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().filterThemingData,
    bodyData
  );
};

export const viewWishlist = (bodyData, axiosInstance = nojwtInstance) => {
  return axiosInstance.get(
    serviceUrls().viewWishlist +
    "?tenant_id=" +
    bodyData.tenant_Id +
    "&wishlist_id=" +
    bodyData.wishlist_Id
  );
};

export const removeProductFromWishlist = (
  body,
  tenant_Id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.delete(
    serviceUrls().addProductToWishlist +
    "?tenant_id=" +
    tenant_Id +
    "&product_id=" +
    body.product_id +
    "&wishlist_id=" +
    body.wishlist_id,
    config
  );
};

export const deleteWishlist = (
  body,
  datasetName,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().deleteWishlist + "&dataset_name=" + datasetName,
    body,
    config
  );
};

export const createOrder = (body, tenant_Id, axiosInstance = nojwtInstance) => {
  return axiosInstance.post(serviceUrls().createOrder, body);
};

export const getOrderDetails = (
  orderId,
  tenant_Id,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.get(
    serviceUrls().getPaymentOrder + "/" + orderId + "?tenant_id=" + tenant_Id
  );
};

export const getCustomerRate = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().getCustomerRate, body, config);
};

export const updateCustomerSubscription = (
  body,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().updateCustomerSubscription, body);
};
export const createPayment = (
  body,
  tenant_Id,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(serviceUrls().createPayment, body, tenant_Id);
};

export const createPaymentGet = (body) => {
  return nojwtInstance.get(
    serviceUrls().createPaymentGet +
    "&id=" +
    body.payment_id +
    "&payment_id=" +
    body.id +
    "&payment_status=" +
    body.payment_status,
    body
  );
};

export const cloneApp = (body, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().cloneApp, body);
};

export const sendEmailToSubAdmin = (
  body,
  product_id,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().sendEmailSubAdmin + "&product_id=" + product_id,
    body
  );
};

export const updateSubscription = (body, axiosInstance = nojwtInstance) => {
  return axiosInstance.post(serviceUrls().updateSubscription, body);
};

export const getAllStoreComponentPages = (
  body,
  config = null,
  axiosInstance = nojwtInstance
) => {
  return axiosInstance.post(
    `${serviceUrls().fetchAllStoreComponentPages}&dataset_name=fc_app_pages`,
    body,
    config
  );
};

export const exportUserList = async (config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().importExportUser, config);
};

export const importUserList = async (
  fileData,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().importExportUser, fileData, config);
};

export const importDataViaDataset = async (
  fileData,
  dataset_id,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(`${serviceUrls().importDataFromDataset}&dataset_id=${dataset_id}`, fileData, config);
};

export const exportApp = async (
  appId,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    `${serviceUrls().importExportApp}&app_id=${appId}`,
    config
  );
};

export const importApp = async (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(`${serviceUrls().importExportApp}`, body, config);
};

export const addToCart = async (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(`${serviceUrls().addToCart}`, body, config);
};

export const addToCartList = async (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(`${serviceUrls().addToCart}`, body, config);
};
export const clearAddToCartList = async (
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.delete(`${serviceUrls().addToCart}`, config);
};

export const cartConfigUpdate = async (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(`${serviceUrls().cartConfig}`, body, config);
};

export const getCartConfigDetails = async (
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(`${serviceUrls().cartConfig}`, config);
};

export const fetchAuditLogData = (
  datasetName,
  config = null,
  axiosInstance = appInstance
) => {
  let tenant_id = JSON.parse(localStorage.getItem("tenantId"));
  return axiosInstance.get(
    `/flashcorp/api/v1/get_all_app_flows?dataset_name=${datasetName + "_adt"
    }&tenant_id=${tenant_id}`,
    config
  );
};
