import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  createPrivilege,
  deletePrivilegeData,
  getPrivilegeData,
  updatePrivileges,
} from "../../services/appService";
import { Modal, Button } from "react-bootstrap";
import Loader from "../dataset/Loader/Loader";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "../application/customModal/CustomModal";
export class StandardPrivileges extends Component {
  constructor() {
    super();
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      getRolesHeader: ["", "Read", "Write"],
      privilegeName: "",
      componentData: [
        "Roles",
        "Privileges",
        "AddUser",
        "Datasets",
        "Dataset  Editor",
        "My Apps",
        "Available Apps",
        "Create Apps",
        "Create Schedules",
        "Schedules",
        "History / Log",
        "List of Api",
        "Logs",
        "Store",
        "Company Profile",
      ],
      currentPage: 1,
      dataPerPage: 10,
      standardPrivilegeTableData: [],
      errors: {},
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      isWrite: false,
      totalDataCount: 0,
      privilegeTableData: [],
      showDeleteModal: false,
      editState: false,
      objectId: "",
      isLoading: true,
      deleteLoader: false,
      savePrivilegeBtnLoader: false,
    };
  }

  componentDidMount() {
    this.setStandardPrivilegeData();
    this.getPrivilages();
    this.getPrivilegeTableData();
  }

  setStandardPrivilegeData = () => {
    let standardPrivilegeTableData = [];

    this.state.componentData.forEach((data, index) =>
      standardPrivilegeTableData.push({
        [data]: {
          read: false,
          write: false,
        },
      })
    );
    this.setState({
      standardPrivilegeTableData,
    });
  };

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["Privileges"]) {
          this.setState({ isWrite: data["Privileges"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true });
    }
  };

  getPrivilegeTableData = () => {
    this.setState({ isLoading: true });
    getPrivilegeData()
      .then((response) => {
        if (response.success === true) {
          this.setState({
            privilegeTableData: this.standardPrivilege(response.data),
            totalDataCount: this.standardPrivilege(response.data).length,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true, isLoading: false });
      });
  };

  standardPrivilege(alldata) {
    return alldata?.filter((data) => {
      if (data.privilegeType === "standard_privileges") {
        return data;
      }
    });
  }

  updateStandardPrivilegeTableData = (e, i, val) => {
    let checkboxVal = [];
    checkboxVal = this.state.standardPrivilegeTableData;
    checkboxVal[i][val][e.target.value] = e.target.checked;
    this.setState({ standardPrivilegeTableData: checkboxVal });
  };

  changeValue = (e) => {
    this.setState({ privilegeName: e.target.value });
  };

  validateFieldForm() {
    let errors = {};
    let formIsValid = true;

    if (this.state.privilegeName === "") {
      formIsValid = false;
      errors["privilegeName"] = "*Please enter Privilege Name.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submitPrivileges = (event) => {
    event.preventDefault();
    if (this.validateFieldForm()) {
      if (this.state.editState) {
        let standardPrivileges_update = {
          tenant_id: this.state.tenant_Id,
          is_primary: true,
          fields: {
            standard_privileges: this.state.standardPrivilegeTableData,
            privilegeName: this.state.privilegeName,
            privilegeType: "standard_privileges",
          },
          object_id: this.state.objectId,
        };
        updatePrivileges(standardPrivileges_update)
          .then((response) => {
            if (response.success === true) {
              toast.success(response.message);
              this.setState({
                editState: false,
                privilegeName: "",
                privilegeType: "",
              });
              this.setStandardPrivilegeData();
              this.getPrivilegeTableData();
            }
          })
          .catch((errors) => {
            if (errors.response) {
              toast.error(errors.response.data.message);
            }
          });
      } else {
        let standardPrivileges = {
          tenant_id: this.state.tenant_Id,
          is_primary: true,
          fields: {
            standard_privileges: this.state.standardPrivilegeTableData,
            privilegeName: this.state.privilegeName,
            privilegeType: "standard_privileges",
          },
        };
        this.setState({
          savePrivilegeBtnLoader: true,
        });
        createPrivilege(standardPrivileges)
          .then((response) => {
            if (response.success === true) {
              toast.success(response.message);
              this.setState({
                savePrivilegeBtnLoader: false,
                privilegeName: "",
              });
              this.getPrivilegeTableData();
              this.setStandardPrivilegeData();
            }
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
              this.setState({
                savePrivilegeBtnLoader: false,
                privilegeName: "",
              });
            }
          });
      }
    }
  };

  deletePrivileges = () => {
    this.setState({
      deleteLoader: true,
    });
    let body = {
      data: {
        data: [this.state.objectId],
      },
    };
    if (this.state.currentPage * 10 - this.state.totalDataCount === 9) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
    deletePrivilegeData(body)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          this.setState({ editState: false });
          this.getPrivilegeTableData();
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ showDeleteModal: false, deleteLoader: false });
      });
  };

  receiveCurrentPageNumber = (langValue) => {
    this.setState({ currentPage: langValue });
  };

  editStandaedPrivileges(data) {
    window.scrollTo({
      top: 50,
      behavior: "smooth",
    });
    let standardPrivilegeTableData = [];
    let tempArry = data.standard_privileges.map((value) => {
      return Object.keys(value)[0];
    });

    this.state.componentData.forEach((value) => {
      if (!tempArry.includes(value)) {
        standardPrivilegeTableData.push({
          [value]: {
            read: false,
            write: false,
          },
        });
      }
    });

    this.setState({
      standardPrivilegeTableData: [
        ...data.standard_privileges,
        ...standardPrivilegeTableData,
      ],
      privilegeName: data.privilegeName,
      privilegeType: data.privilegeType,
      editState: true,
      objectId: data._id.$oid,
    });
  }

  render() {
    const setHeaderData = this.state.getRolesHeader.map((header, index) => (
      <th className="table_head" key={index}>
        {header}
      </th>
    ));
    const setStandardPrivilegeTableData = [];
    this.state.standardPrivilegeTableData.forEach((data, index) =>
      Object.keys(data).forEach((val, i) => {
        setStandardPrivilegeTableData.push(
          <tr key={index}>
            <td>{val}</td>

            {this.state.isWrite ? (
              <td>
                <input
                  className="table-checkbox pointer"
                  type="checkbox"
                  onChange={(e) => {
                    this.updateStandardPrivilegeTableData(e, index, val);
                  }}
                  checked={
                    this.state.standardPrivilegeTableData[index][val]["read"]
                  }
                  value={"read"}
                />
              </td>
            ) : (
              <td>
                <input className="table-checkbox" type="checkbox" disabled />
              </td>
            )}

            {this.state.isWrite ? (
              <td>
                <input
                  className="table-checkbox pointer"
                  type="checkbox"
                  onChange={(e) => {
                    this.updateStandardPrivilegeTableData(e, index, val);
                  }}
                  checked={
                    this.state.standardPrivilegeTableData[index][val]["write"]
                  }
                  value={"write"}
                />
              </td>
            ) : (
              <td>
                <input className="table-checkbox" type="checkbox" disabled />
              </td>
            )}
          </tr>
        );
      })
    );

    const { currentPage, dataPerPage } = this.state;

    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const currentData = this.state.privilegeTableData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    const priviledgeTableBodyData = currentData?.map((data, index) => {
      return (
        <tr key={index}>
          <td>{data.privilegeName}</td>
          {this.state.isWrite ? (
            <td className="text-nowrap">
              <div className="d-flex text-center m-0">
                <div className="action-icons">
                  <FontAwesomeIcon
                    icon={"fa fa-pen-to-square"}
                    className="me-2"
                    role="button"
                    size="lg"
                    onClick={() => {
                      this.editStandaedPrivileges(data);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={"fa fa-trash"}
                    role="button"
                    size="lg"
                    className="me-2"
                    onClick={() =>
                      this.setState({
                        showDeleteModal: true,
                        objectId: data._id.$oid,
                      })
                    }
                  />
                </div>
              </div>
            </td>
          ) : null}
        </tr>
      );
    });

    return (
      <div>
        <div className="d-help py-2">
          <h5>Standard Privileges</h5>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={(e) => {
              e.preventDefault();
              this.props?.history?.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>

        {this.state.isWrite ? (
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              Privilege Name
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Privilege Name"
                name="privilegeName"
                value={this.state.privilegeName}
                onChange={(e) => {
                  this.changeValue(e);
                }}
              />
              <div className="errorMsg text-danger">
                {this.state.errors.privilegeName}
              </div>
            </div>
          </div>
        ) : null}

        <CustomModal
          modalState={this.state.showDeleteModal}
          modalTitle={"Are you sure?"}
          modalDiscription={"Do you want to delete privilege"}
          hideModal={() => this.setState({ showDeleteModal: false })}
          onDeleteClick={() => this.deletePrivileges()}
          isLoading={this.state.deleteLoader}
        />
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>{setHeaderData}</tr>
                </thead>
                <tbody>{setStandardPrivilegeTableData}</tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.isWrite ? (
          <div className="d-flex justify-content-end">
            {this.state.editState ? (
              <button
                onClick={() => {
                  this.setState({
                    privilegeName: "",
                    privilegeType: "",
                    editState: false,
                  });
                  this.setStandardPrivilegeData();
                }}
                type="button"
                className="btn btn-danger btn-md mt-2 me-1"
              >
                <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                <span className="ms-1">Cancel</span>
              </button>
            ) : null}
            <button
              onClick={(event) => this.submitPrivileges(event)}
              type="submit"
              className="custom-btn mt-2 me-0"
            >
              {this.state.editState ? (
                <>
                  <FontAwesomeIcon icon="fa fa-retweet" />
                  <span className="ms-1">Update Privilege</span>
                </>
              ) : (
                <>
                  {this.state.savePrivilegeBtnLoader ? (
                    <div className="spinner-border spinner-border-sm text-light" />
                  ) : (
                    <FontAwesomeIcon icon="fa-regular fa-circle-check" />
                  )}
                  <span className="ms-1">Save Privilege</span>
                </>
              )}
            </button>
          </div>
        ) : null}

        <div className="d-help py-2">
          <h5>Saved Privileges</h5>
        </div>

        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr className="head-row">
                    <th className="text-nowrap">Privilege Name</th>
                    {this.state.isWrite ? (
                      <th className="text-nowrap">Actions</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>{priviledgeTableBodyData}</tbody>
              </table>
              {this.state.totalDataCount > 10 ? (
                <div className="dynamic_pagination my-2">
                  <Pagination
                    pageRangeDisplayed={3}
                    activePage={this.state.currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.totalDataCount}
                    onChange={this.receiveCurrentPageNumber}
                  />
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default StandardPrivileges;
