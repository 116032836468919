import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { getLastNodeData } from "../../../services/API";
import { FormContext } from "../contexts/FormContext";
import "./CheckList.scss";

const CheckList = ({ field, onChange, value = [], appSessionId, isEdit, disabled = false, }) => {
  const [checkListData, setCheckListData] = useState([]);
  const { autoFillData } = useContext(FormContext);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (isEdit) {
      setCheckListData(value);
    } else {
      getDataByLogic();
    }
  }, [isEdit]);
  useEffect(() => {
    if (isEdit) {
      setCheckListData(value);
    }else if(autoFillData){
      setCheckListData(autoFillData[field.name]);
    }
  }, [value]);
  useEffect(() => {
    if (value.length === 0) {
      getDataByLogic();
    }
  }, [value]);
  const getDataByLogic = () => {
    if(!field?.checkList?.checkList_logic_name){
      return;
    }
    setLoader(true);
    getLastNodeData(appSessionId, field?.checkList?.checkList_logic_name)
      .then((response) => {
        const initialData = response?.data?.value?.data?.data || [];
        setCheckListData(initialData);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const handleChange = (index) => {
    const updatedObj = [...checkListData];
    updatedObj[index].status =
      updatedObj[index].status === "Yes" ? "No" : "Yes";
    setCheckListData(updatedObj);
    onChange(updatedObj);
  };
  const handleCommentsChange = (index,value) => {
    if(index>=0 && checkListData){
      const updatedObj = [...checkListData];
      updatedObj[index].comments = value;
      setCheckListData(updatedObj);
      console.log(updatedObj);
      onChange(updatedObj);
    }
  };

  return (
    <div className="m-2 p-2 border-left">
      <div className="d-flex justify-content-between align-items-center border-dotted-header pb-2 m-2 custom-width">
        <h6>{field?.checkList?.checkList_title}</h6>
      </div>
      {loader ? (
        <div className="tableSpinner">
          <div className="text-center spinner-border spinner-border-sm me-1" />
        </div>
      ) : (
        checkListData?.map((item, index) => {
          return (
            <div className="d-flex justify-content-between align-items-center px-2 pb-1 my-2 mx-2 border-dotted custom-width">
              <label>
                {item?.status === "Yes" ? (
                  <FontAwesomeIcon icon="fa-solid fa-check" className="fa-lg"/>
                ) : (
                  <FontAwesomeIcon icon="fa-solid fa-hourglass-half"  className="fa-lg"/>
                )}
                &nbsp;{index+1}.
              </label>
              <label className="description-label">{item?.description}</label>
              <FontAwesomeIcon
                size="2xl"
                role="button"
                className={`stauspointer toggleBtn toggleFormBtnSize ms-0 ${
                  item?.status === "Yes" ? "text-success" : "text-danger"
                }`}
                icon={
                  item?.status === "Yes"
                    ? "fa fa-toggle-on"
                    : "fa fa-toggle-off"
                }
                aria-hidden="true"
                onClick={() => handleChange(index)}
              />
              <div class="input-group comments-box-width position-relative">
                <div class="input-group-addon comment-icon-position">
                  <FontAwesomeIcon icon="fa-solid fa-comment" />
                </div>
                <input
                  size="sm"
                  type="text"
                  className="form-control comments-box-input"
                  placeholder="Enter comments here"
                  disabled={disabled ? disabled : field?.initialDisable === "yes"}
                  value={item?.comments}
                  onChange={(e) => {
                    handleCommentsChange(index,e.target.value);
                  }}
                />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default CheckList;
