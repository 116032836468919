import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getAllApi,
  getRoleData,
  getSearchedApiByName,
} from "../../services/appService";
import Loader from "../dataset/Loader/Loader";
import { toast } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListOfApi = () => {
  const [tableHeadings, setTableHeadings] = useState([
    "#",
    "API name",
    "API type",
    "API owner",
    "App name",
    "Logic",
    "API key",
    "Actions",
  ]);

  const [filterCriteriaData, setFilterCriteriaData] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [apiList, setApiList] = useState([]);
  const [searchQuery, setsearchQuery] = useState({
    searchText: ""
  })
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalRecords, setTotalRecords] = useState(0);

  const [dataPerPage, setdataPerPage] = useState(20);

  const [searchText, setSearchText] = useState("");
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("user-info"))
  );
  const [isWrite, setIsWrite] = useState(false);


  useEffect(() => {
    getPrivilages();
    fetchRolesData();
      setIsLoading(true);    
      const requestBody = {
        FilterCriteria: filterCriteriaData,
        SortCriteria: [],
        PaginationCriteria: {
          limit: dataPerPage,
          skip: (currentPage - 1) * dataPerPage,
        },
      };  
      getAllApi(requestBody)
        .then((response) => {
          if (response.success) {
            console.log(response,"--------")
            setApiList(response.data);
            setTotalRecords(response.totalcount);
            setIsLoading(false);
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(false);
        })
    }, [currentPage]);

  let getPrivilages = () => {
    if (userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["List of Api"]) {
          setIsWrite(data["List of Api"]?.write);
        }
      });
    } else {
      setIsWrite(true);
    }
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit Api
    </Tooltip>
  );

  const getRoleName = (role_id) => {
    let role_name = "";
    roleList.map((role) => {
      if (role._id.$oid === role_id) {
        role_name = role.role_name;
      }
    });
    return role_name;
  };

  const setHeaderData = isWrite
    ? tableHeadings.map((header, index) => (
        <th key={index} className="text-nowrap head-row">
          {header}
        </th>
      ))
    : tableHeadings
        .filter((header) => header !== "Actions")
        .map((header, index) => (
          <th key={index} className="text-nowrap head-row">
            {header}
          </th>
        ));

  const setTableData = apiList.map((api, index) => (
    <tr key={index}>
      <td>
        {index +
          1 +
          totalRecords * (currentPage - 1) -
          (totalRecords - dataPerPage) * (currentPage - 1)}
      </td>
      <td>{api.api_name}</td>
      <td>{api?.select_type || "user"}</td>
      <td>
        {api?.select_type === "role"
          ? getRoleName(api.api_owner)
          : api.api_owner}
      </td>
      <td>{api.app_name}</td>
      <td>{api.logic_name}</td>
      <td>
        {api.api_key.substring(0, 2)}**********
        {api.api_key.substring(
          parseInt(api.api_key.length),
          parseInt(api.api_key.length - 4)
        )}
      </td>
      {isWrite ? (
        <td>
          <div className="action-icons">
            <Link to={`edit-api/${api._id.$oid}`}>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <FontAwesomeIcon
                  icon={"fa fa-pen-to-square"}
                  size="lg"
                />
              </OverlayTrigger>
            </Link>
          </div>
        </td>
      ) : null}
    </tr>
  ));

  const receiveCurrentPageNumber = (langValue) => {
    setCurrentPage(langValue);
  };

  const handleClearSearchApi = () => {
    setSearchText(""); // Clear search input
    setFilterCriteriaData([]); // Clear filters
    setCurrentPage(1); // Reset pagination
  
    // Fetch all API data without filters
    const requestBody = {
      FilterCriteria: [],
      SortCriteria: [],
      PaginationCriteria: {
        limit: dataPerPage,
        skip: 0,
      },
    };
  
    setIsLoading(true);
    getAllApi(requestBody)
      .then((response) => {
        if (response.success) {
          setApiList(response.data);
          setTotalRecords(response.totalcount);
          setIsLoading(false);
        } else {
          toast.error(response.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLoading(false);
      });
  };

  const fetchRolesData = () => {
    getRoleData()
      .then((response) => {
        if (response.success === true) {
          setRoleList(response.data);
        }
      })
      .catch((err) => {});
  };

  const fetchAllDetails = () => {
    const trimmedSearchText = searchText.trim();
  
    // Build filter criteria for API name
    let filterCriteria = [];
    if (trimmedSearchText) {
      filterCriteria.push({
        filter_type: "text",
        type: "contains",
        filter: trimmedSearchText,
        filter_field: "api_name", // Ensure this matches your backend field
      });
    }
  
    // Update state and fetch results
    setFilterCriteriaData(filterCriteria);
    setCurrentPage(1); // Reset to the first page for new search results
  
    const requestBody = {
      FilterCriteria: filterCriteria,
      SortCriteria: [],
      PaginationCriteria: {
        limit: dataPerPage,
        skip: 0,
      },
    };
  
    setIsLoading(true);
    getAllApi(requestBody)
      .then((response) => {
        if (response.success) {
          setApiList(response.data);
          setTotalRecords(response.totalcount);
          setIsLoading(false);
        } else {
          toast.error(response.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLoading(false);
      });
  };
  

  return (
    <div>
      <div className="d-help py-2 flex-wrap">
        <h5 className="m-0">Expose API's</h5>
        <div className="d-flex align-items-center">
          <div className="input-group input-group-sm mb-0 me-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search Api"
              aria-label="Search Api"
              aria-describedby="Search Api Input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              className="search-btn border-start-0 border-top-0 border-bottom-0 border border-white m-0"
              type="button"
              id="clear-search-api-button"
              onClick={handleClearSearchApi}
              title="Clear search"
            >
              <FontAwesomeIcon icon={"fa-solid fa-xmark"} color="white" />
            </button>
            <button
              className="search-btn m-0"
              type="button"
              id="search-api-button"
              onClick={fetchAllDetails}
              title="Search"
            >
              Search
            </button>
          </div>
          {isWrite ? (
            <Link to={"/add-new-api"}>
              <button
                type="button"
                className="add-btn m-0 text-nowrap"
                title="Add Api"
              >
                &nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-plus" />
                <span className="ms-1">&nbsp;&nbsp;Add API&nbsp;&nbsp;</span>
              </button>
            </Link>
          ) : null}
        </div>
      </div>
      <div className="listApi">
        <div className="table-responsive">
          {isLoading ? (
            <Loader />
          ) : (
            <table className="table table-bordered bg-white my-2">
              <thead>
                <tr>{setHeaderData}</tr>
              </thead>
              <tbody>
                {apiList.length ? (
                  setTableData
                ) : (
                  <tr>
                    <td colSpan="12">
                      <h5>No records found</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="dynamic_pagination justify-content-between">
          {totalRecords > 0 && (
            <div className="my-2">
              Showing {" "}
              {(currentPage - 1) * dataPerPage + 1} to {" "}
              {Math.min(
                currentPage * dataPerPage,
                totalRecords
              )} of {totalRecords} entries
            </div>
          )}
          {totalRecords > 20 && (
            <div className="dynamic_pagination my-2">
              <Pagination
                pageRangeDisplayed={3}
                activePage={currentPage}
                itemsCountPerPage={20}
                totalItemsCount={totalRecords}
                onChange={receiveCurrentPageNumber}
              />
            </div>
          )}
        </div>
    </div>
  );
};

export default ListOfApi;
