import React, { Component } from "react";

export class Pagination extends Component {
  constructor(props) {
    super();
    this.state = {
      pageNumbers: [],
      currentPage: 1,
      dataPerPage: 20,
      currentTopNumber: 1,
    };
    this.handlePagination = this.handlePagination.bind(this);
  }

  handlePagination(event) {
    event.preventDefault();
    this.setState(
      {
        currentPage: Number(event.target.id),
      },
      () => {
        this.props.onSelectLanguage(this.state.currentPage);
        this.showCurrentTopNumber();
      }
    );
  }

  previousPage(event) {
    event.preventDefault();
    if (this.state.currentPage > 1) {
      this.setState(
        {
          currentPage: Number(this.state.currentPage - 1),
        },
        () => {
          this.props.onSelectLanguage(this.state.currentPage);
          this.showCurrentTopNumber();
        }
      );
    }
  }

  nextPage(event) {
    event.preventDefault();
    if (this.state.currentPage < this.state.pageNumbers.length) {
      this.setState(
        {
          currentPage: Number(this.state.currentPage + 1),
        },
        () => {
          this.props.onSelectLanguage(this.state.currentPage);
          this.showCurrentTopNumber();
        }
      );
    }
  }

  showCurrentTopNumber() {
    if (this.state.currentPage === 1) {
      this.setState({
        currentTopNumber: 1,
      });
    } else {
      this.setState({
        currentTopNumber: (this.state.currentPage - 1) * 20 + 1,
      });
    }
  }

  render() {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(this.props.sendRowDataLength / this.state.dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    this.state.pageNumbers = pageNumbers;

    const renderPageNumbers = pageNumbers.map((number, index) => {
      return (
        <li
          className="paginate_button active"
          key={index}
          onClick={this.handlePagination}
        >
          <a
            href="javascript;"
            className={`cursor-pointer ${
              this.state.currentPage === number ? "clicked-page" : null
            }`}
            onClick={this.handlePagination}
            id={number}
          >
            {number}
          </a>
        </li>
      );
    });
    return (
      <div className="row align-items-center">
        <div className="col-md-5 col-sm-5">
          <div className="my-2" id="datatable_info">
            Showing {this.state.currentTopNumber} to{" "}
            {this.state.currentPage * 20 > this.props.sendRowDataLength
              ? this.props.sendRowDataLength
              : this.state.currentPage * 20}{" "}
            of {this.props.sendRowDataLength} entries{" "}
          </div>
        </div>
        <div className="col-md-7 col-sm-7">
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="datatable_paginate"
          >
            <ul className="pagination">
              <li
                className="paginate_button previous disabled"
                id="datatable_previous"
              >
                <a
                  href="javascript;"
                  className="cursor-pointer"
                  onClick={(e) => this.previousPage(e)}
                  disabled={
                    this.state.currentPage === pageNumbers[0] ? true : false
                  }
                >
                  Previous
                </a>
              </li>
              {renderPageNumbers}
              <li className="paginate_button next" id="datatable_next">
                <a
                  href="javascript;"
                  className="cursor-pointer"
                  onClick={(e) => this.nextPage(e)}
                  disabled={
                    this.state.currentPage ===
                    pageNumbers[pageNumbers.length - 1]
                      ? true
                      : false
                  }
                >
                  Next
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Pagination;
