import React from "react";
import Pagination from "react-js-pagination";

const TablePagination = ({
  displayData,
  activePage,
  totalCount,
  paginationData,
}) => {
  return (
    <div className="dynamic_pagination bg-white justify-content-between">
      {(displayData?.showPagination === undefined ||
        displayData?.showPagination) && (
        <>
          {totalCount > 0 && (
            <div className="my-2">
              Showing {(activePage - 1) * 20 + 1} to{" "}
              {activePage * 20 > totalCount ? totalCount : activePage * 20} of{" "}
              {totalCount} entries{" "}
            </div>
          )}
          {totalCount > 20 && (
            <Pagination
              pageRangeDisplayed={3}
              activePage={activePage}
              itemsCountPerPage={20}
              totalItemsCount={totalCount}
              onChange={(e) => {
                paginationData(e);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TablePagination;
