import React, { Component } from "react";
import "./AppDashboard.scss";
import Loader from "../../dataset/Loader/Loader";
import {
  cloneApp,
  createPaymentGet,
  exportApp,
  fetchInstallAppData,
  fetchInstallAppDataBadegs,
  getTenantDetail,
  importApp,
  updateSubscription,
} from "../../../services/appService";
import { toast } from "react-toastify";
import error_500 from "../../../images/internal_server_500.png";
import no_app_found from "../../../images/no_app_found.png";
import * as API from "../../../services/API";
import accessDenied from "../../../images/forbidden304error.png";
import { Button, Modal } from "react-bootstrap";
import {
  getCustomerRate,
  createOrder,
  createPayment,
} from "../../../services/appService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import { fileSaver } from "../../../services/utils";
export class AppDashboard extends Component {
  constructor() {
    super();
    this.state = {
      getAppsRecord: [],
      totalRecordCount: 0,
      loading: true,
      errorMsg: false,
      showRenewModal: false,
      showAppObj: {},
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      rateChart: [],
      appGroupId: "",
      appPrice: {},
      paymentOrder: {
        order_amount: "",
        order_currency: "",
        app_group_id: "",
        frequency: "",
      },
      customerDetails: {
        customer_id: "",
        customer_name: "",
        customer_email: "",
        customer_phone: "",
      },
      paymentSessionId: "",
      cloneAppName: "",
      cloneButtonLoader: false,
      isCloning: false,
      cloneAppId: "",
      cloneAppNameValidationError: "",
      badgesList: [],
      group_id: "",
      purpose: "",
      redirect_url: "",
      app_name: "",
      app_groupId: "",
      id: "",
      importData: "",
      exportLoader: false,
      importLoader: false,
      exportedAppName: "",
      iconImage:"",
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.callRedisAPI();
    this.getCustomerRate();
    let tenantName = JSON.parse(localStorage.getItem("query_param"));
    this.getDetailsOfTanantRecord(tenantName);
    if (this.state?.userInfo?.default_layout === "minimalLayout") {
      this.setState({
        errorMsg: true,
      });
    }

    let query = this.props.location.search;
    let params = new URLSearchParams(query);

    const paymentId = params.get("payment_id");
    const paymentStatus = params.get("payment_status");
    const paymentRequestId = params.get("payment_request_id");

    const obj = {
      id: paymentId,
      payment_id: paymentRequestId,
      payment_status: paymentStatus,
    };

    if (paymentId && paymentStatus && paymentRequestId) {
      createPaymentGet(obj)
        .then((response) => {
          this.setState({
            app_groupId: response.data.app_group_id,
            id: response.data.id,
          });
          toast.success(response.message);
          if (response.data.status === "Completed") {
            updateSubscription({
              tenant_id: JSON.parse(localStorage.getItem("tenantId")),
              app_group_id: this.state.app_groupId,
              id: this.state.id,
            })
              .then((response) => {
                toast.success(response.message);
              })
              .finally(() => {
                this.props.history.push("/app-dashboard");
              });
          } else {
            this.props.history.push("/app-dashboard");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.getAppsRecord !== this.state.getAppsRecord) {
      this.getPrivilages();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getDetailsOfTanantRecord = (tenant_Name) => {
    getTenantDetail(tenant_Name)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            customerDetails: {
              customer_id: response.data[0]?.tenant_id,
              customer_name: response.data[0]?.tenant_name,
              customer_email: response.data[0]?.user_tenant_email,
              customer_phone: response.data[0]?.contact,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  callRedisAPI() {
    let objData = {
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    };
    API.callVPApiRedis(objData)
      .then((response) => {
        if (this.mounted) {
          if (response) {
            this.getInstallData();
            this.getInstallBadegs();
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorMsg: true });
      });
  }

  getCustomerRate() {
    getCustomerRate()
      .then((response) => {
        if (response.success) {
          //var priceRateObj = response.data.filter(obj=> obj.app_group_id=="group_one");
          this.setState({ rateChart: response.data });
        } else {
          this.setState({ rateChart: [] });
          toast.error(response.message);
        }
      })
      .catch((err) => {
        this.setState({ rateChart: [] });
        toast.error(err.message);
      });
  }

  getInstallData() {
    this.setState({ loading: true });
    fetchInstallAppData()
      .then((response) => {
        if (this.mounted) {
          if (response.success === true) {
            this.setState({
              getAppsRecord: response.data,
              totalRecordCount: response.total_count,
              loading: false,
            });

            this.setState({
              isCloning: false,
            });
          } else {
            this.setState(
              {
                getAppsRecord: [],
                loading: false,
              },
              () => {
                toast.error(response.message);
              }
            );
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorMsg: true }, () =>
          toast.error(err.message)
        );
      });
  }

  getInstallBadegs = () => {
    fetchInstallAppDataBadegs().then((response) => {
      if (response?.success) {
        this.setState({ badgesList: response?.data || [] });
      }
    });
  };

  renewApp(grpId, appName) {
    if (grpId && grpId != null) {
      var appPrice = this.state.rateChart.filter(
        (obj) => obj.app_group_id == grpId
      );
      if (appPrice.length == 0) {
        appPrice = this.state.rateChart.filter(
          (obj) => obj.app_group_id == "group_one"
        );
      }

      this.setState({ appPrice: appPrice[0] });
      this.setState({
        appGroupId: grpId,
        showRenewModal: true,
        app_name: appName,
      });
    } else {
      toast.error("Group does not exists!");
    }
  }

  fetchDataOfApp(appId) {
    this.setState({
      exportLoader: true,
    });
    exportApp(appId)
      .then((response) => {
        let appName = response.data.app_details.app_name;
        let fileType = ".json";
        this.setState({
          exportAppData: [response.data],
        });
        fileSaver(this.state.exportAppData, appName, fileType);
      })
      .finally(() => {
        this.setState({
          exportLoader: false,
        });
      });
  }

  importAppData = (files) => {
    this.setState({ importLoader: true });
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      const fileContentData = JSON.parse(fileContent);
      try {
        const [objectToImport] = fileContentData;
        importApp({ data: objectToImport })
          .then(() => {
            fetchInstallAppData();
            this.callRedisAPI();
            this.getCustomerRate();
          })
          .finally(() => {
            this.setState({ importLoader: false });
          });
      } catch {
        toast.error("Invalid File");
        this.setState({ importLoader: false });
      }
    };
    reader.readAsText(file);
  };

  handleNextButton(groupId, appName) {
    this.setState({ payment_type: false, showRenewModal: true });
    this.renewApp(groupId, appName);
  }
  routeToReactUI(data) {
    let appId = data._id.$oid;
    this.props.history.push(`/app_ui/${appId}`);
  }

  createAppOrder = () => {
    if (this.state.paymentOrder.order_amount) {
      createOrder(this.state.paymentOrder)
        .then((response) => {
          if (response.success) {
            const paymentSessionId = response.data.payment_session_id;
            this.setState({
              paymentSessionId: paymentSessionId,
            });
            this.props.history.push(`/payment/` + paymentSessionId);
          } else {
            toast.error(response.message);
            this.setState({ loading: false });
          }
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  payNow(plan) {
    this.setState({ loading: true });
    let monthlyAmount = parseFloat(
      this.state.appPrice.monthly_subscription_rate
    );
    let yearlyAmount = parseFloat(this.state.appPrice.yearly_subscription_rate);

    const { selectedPayment } = this.state;

    if (selectedPayment === "Cashfree") {
      this.setState({
        paymentOrder: {
          order_amount: plan === "MONTHLY" ? monthlyAmount : yearlyAmount,
          frequency: plan,
          order_currency: this.state.appPrice.currency,
          app_group_id: this.state.appGroupId,
          customer_details: this.state.customerDetails,
        },
      });
      setTimeout(() => this.createAppOrder(), 1500);
    } else if (selectedPayment === "Instamojo") {
      const baseURL = process.env.REACT_APP_URL;

      this.setState({
        paymentOrder: {
          order_amount: plan === "MONTHLY" ? monthlyAmount : yearlyAmount,
          frequency: plan,
          order_currency: this.state.appPrice.currency,
          app_group_id: this.state.appGroupId,
          customer_details: this.state.customerDetails,
          purpose: this.state.app_name,
          redirect_url: `${baseURL}app-dashboard`,
        },
      });
      setTimeout(() => this.createPaymentOrder(), 1500);
    }
  }

  createPaymentOrder = () => {
    if (this.state.paymentOrder.order_amount) {
      createPayment(this.state.paymentOrder)
        .then((response) => {
          if (response.success) {
            window.location.href = response.data.longurl;
          } else {
            toast.error(response.message);
          }
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };

  getPrivilages = () => {
    if (this.state.userInfo?.privileges) {
      let { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      let showAppObj = this.state.showAppObj;

      this.state.getAppsRecord?.forEach((data, index) => {
        showAppObj[data._id.$oid] = {
          showApp: false,
          showEditButton: false,
        };

        app_previleges?.forEach((app_previlege, index) => {
          let previlegeAppKey = "";
          let previlegeAppVal = {};
          Object.keys(app_previlege)?.forEach((row_key) => {
            previlegeAppKey = row_key;
          });
          Object.values(app_previlege)?.forEach((row_val) => {
            previlegeAppVal = {
              showApp: row_val.read || row_val.write,
              showEditButton: row_val.write,
            };
          });
          if (previlegeAppKey === data._id.$oid) {
            showAppObj[data._id.$oid] = previlegeAppVal;
          }
        });
        this.setState({ showAppObj }, () => {
          let allAppsDataObj = this.state?.showAppObj;
          let defaultDashboardValue = this.state.userInfo.default_dashboard;
          let allAppsDefaultDashboard = allAppsDataObj?.[defaultDashboardValue];
          let isDefaultAppHavePrivilege = allAppsDefaultDashboard?.showApp;
          if (
            this.state.userInfo?.default_dashboard &&
            isDefaultAppHavePrivilege
          ) {
            this.runDefaultApp();
          } else this.props.history.push("/app-dashboard");
        });
      });
    }
  };

  runDefaultApp() {
    if (this.state.userInfo?.default_dashboard) {
      this.props.history.push(
        `/app_ui/${this.state.userInfo.default_dashboard}`
      );
    }
  }

  cloneApp(appId, appName) {
    this.setState({
      cloneButtonLoader: true,
    });

    cloneApp({ app_id: appId, app_name: appName })
      .then((res) => {
        if (res.success) {
          this.setState({
            cloneButtonLoader: false,
            loader: true,
          });
          toast.success("App Cloned Successfully");
          this.getInstallData();
        }
      })
      .catch((err) => {
        this.setState({
          cloneButtonLoader: false,
          isCloning: false,
        });
        toast.error(err.response.data.message);
      });
  }

  render() {
    const appData = [];
    let isUserAdmin = this.state.userInfo.role_name.toLowerCase() === "admin";
    let isUserSubAdmin =
      this.state.userInfo.role_name.toLowerCase() === "subadmin";
    this.state.getAppsRecord?.forEach((data, index) =>
      isUserAdmin ||
      (Object.keys(this.state.showAppObj).length > 0 &&
        this.state.showAppObj[data._id.$oid]?.showApp &&
        (!data.app_subscription ||
          data.app_subscription.subscription_status === "active")) ||
      data?.created_by === this.state.userInfo.user_id
        ? appData.push(
            <div
              className="col-lg-2 col-md-3 col-sm-4 col-6 p-0 position-relative my-1"
              key={index}
              onClick={() => this.routeToReactUI(data)}
            >
              <div className="app-box parent mx-1">
                <div className="app-logo-img">
                  <img src={data?.data_file} alt="img"></img>
                </div>
                <p>{data.app_name}</p>
                <div
                  className="btn bg-gray3 child"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa fa-ellipsis-vertical"
                    color="grey"
                  />
                  <div className="editButton display-sm-none">
                    <ul className="drawer editButtonList m-0">
                      <Link
                        to={`/share-app?app_id=${data?._id.$oid}&appName=${data?.app_name}`}
                        className="app_actions_clone_button border-none bg-white text-dark m-0 pt-1"
                      >
                        <FontAwesomeIcon
                          icon={"fa fa-share-alt"}
                          className="me-2"
                        />
                        Share
                      </Link>
                      {isUserAdmin ||
                      isUserSubAdmin ||
                      (Object.keys(this.state.showAppObj).length > 0 &&
                        this.state.showAppObj[data._id.$oid]
                          ?.showEditButton) ? (
                        <Link
                          to={`/create_app/${data._id.$oid}`}
                          className="app_actions_clone_button border-none bg-white text-dark m-0 pt-1"
                        >
                          <FontAwesomeIcon
                            icon={"fa fa fa-pencil"}
                            className="me-2"
                          />
                          Edit
                        </Link>
                      ) : null}
                      {(isUserAdmin || isUserSubAdmin) && (
                        <button
                          className={`app_actions_clone_button border-none bg-white text-dark m-0 pt-1 ${
                            this.state.isCloning && "cursor-not-allowed"
                          }`}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.setState({
                              isCloning: true,
                              cloneAppId: data._id.$oid,
                              cloneAppName: "",
                              cloneAppNameValidationError: "",
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"fa fa-clone"}
                            className="me-2"
                          />
                          Clone
                        </button>
                      )}
                      {isUserAdmin && (
                        <>
                          <button
                            className={`app_actions_clone_button border-none bg-white text-dark m-0 pt-1 ${
                              this.state.exportLoader && "cursor-not-allowed"
                            }`}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.fetchDataOfApp(data._id.$oid);
                            }}
                            disabled={this.state.exportLoader}
                          >
                            {this.state.exportLoader ? (
                              <div className="spinner-border spinner-border-sm text-dark btnLoader"></div>
                            ) : (
                              <FontAwesomeIcon
                                icon="fa-solid fa-file-export"
                                className="me-2"
                              />
                            )}
                            Export
                          </button>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {this.state?.badgesList?.[index]?.pending_count >= 1 ? (
                <div className="pending-badge">
                  <div className="round-badge text-light">
                    <span>
                      {this.state?.badgesList?.[index]?.pending_count}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          )
        : Object.keys(this.state.showAppObj).length > 0 &&
          this.state.showAppObj[data._id.$oid]?.showApp
        ? appData.push(
            <div
              className="col-lg-2 col-md-3 col-sm-4 col-6 p-0 position-relative mb-2"
              key={index}
            >
              <div className="app-box" style={{ background: "#dee2e6" }}>
                <div className="app-logo-img">
                  <img
                    src={data.data_file}
                    alt="img"
                    style={{ opacity: "0.2" }}
                  ></img>
                </div>
                <h5>{data.app_name}</h5>

                <button
                  className="app-btn-warning display-sm-none"
                  onClick={() =>
                    this.setState({
                      payment_type: true,
                      group_id: data.app_group_id,
                      app_name: data.app_name,
                    })
                  }
                >
                  Renew Subscription{" "}
                  <FontAwesomeIcon icon={"fa fa-solid fa-exclamation"} />
                </button>

                {data.pending_count >= 1 ? (
                  <div className="pending-badge">
                    <div className="round-badge">
                      <span>{data.pending_count}</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )
        : null
    );

    return (
      <div>
        <div className="d-help py-2">
          {this.state.errorMsg ? null : <h5 className="m-0">My Apps</h5>}
          {isUserAdmin && (
            <ReactFileReader
              fileTypes={[".json"]}
              handleFiles={this.importAppData}
              multipleFiles={false}
            >
              <button
                className={`import-btn m-0 text-nowrap`}
                disabled={this.state.importLoader}
              >
                {this.state.importLoader ? (
                  <div className="spinner-border spinner-border-sm text-dark btnLoader">
                    {" "}
                  </div>
                ) : (
                  <FontAwesomeIcon icon="file-import" />
                )}
                <span className="ms-1">Import</span>
              </button>
            </ReactFileReader>
          )}
        </div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="dataset_table app-dashboard mx-2">
            <div>
              <div className={`row py-2`}>
                {this.state.userInfo?.default_layout !== "minimalLayout" ? (
                  appData
                ) : (
                  <img src={accessDenied} alt="access_denied" />
                )}
              </div>
              {this.state.errorMsg === true &&
              this.state?.userInfo?.default_layout !== "minimalLayout" ? (
                <div className="mx-auto">
                  <img
                    className="img-fluid"
                    src={error_500}
                    title="internal server error image"
                    alt="error_img"
                  />
                  <h3 className="text-center">Internal Issue</h3>
                </div>
              ) : this.state.errorMsg === false &&
                this.state.totalRecordCount === 0 ? (
                <div className="mx-auto">
                  <img
                    className="img-fluid"
                    height={400}
                    width={400}
                    src={no_app_found}
                    title="internal server error image"
                    alt="no_app_found"
                  />
                  <h5 className="text-center">No apps found.</h5>
                </div>
              ) : null}
            </div>
            <Modal
              show={this.state.showRenewModal}
              onHide={() => this.setState({ showRenewModal: false })}
            >
              <Modal.Header closeButton>
                <h4>Renew App</h4>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="">
                    <div className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-blue btn-h-outline-blue btn-a-outline-blue w-100 shadow-sm">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-8">
                          <h4 className="pt-3 text-170 text-600 text-primary-d1 letter-spacing">
                            Monthly Plan
                          </h4>

                          <div className="text-secondary-d1 text-120">
                            <span className="ms-n15 align-text-bottom">
                              {this.state.appPrice.currency}
                            </span>
                            <span className="text-180">
                              {this.state.appPrice.monthly_subscription_rate}
                            </span>{" "}
                            / month
                          </div>
                        </div>

                        <div className="col-12 col-md-4 text-center">
                          <a
                            onClick={() => this.payNow("MONTHLY")}
                            href="#"
                            className="f-n-hover btn btn-info btn-raised px-4 py-25 w-75 text-600"
                          >
                            Pay Now
                          </a>
                          {/* <Link to={`/payment`} target="_blank" className="f-n-hover btn btn-info btn-raised px-4 py-25 w-75 text-600">Pay Now</Link> */}
                        </div>
                      </div>
                    </div>

                    <div className="d-style bgc-white btn btn-brc-tp btn-outline-green btn-h-outline-green btn-a-outline-green w-100 shadow-sm border-2">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-8">
                          <h4 className="pt-3 text-170 text-600 text-green-d1 letter-spacing">
                            Yearly Plan
                          </h4>

                          <div className="text-secondary-d1 text-120">
                            {/* <div className="text-danger-m3 text-90 me-1 ms-n4 pos-rel d-inline-block">
                      {this.state.appPrice.currency}<span className="text-150 deleted-text">1499</span>
                      <span>
                        <span className="d-block rotate-45 position-l mt-n475 ms-35 fa-2x text-400 border-l-2 h-5 brc-dark-m1"></span>
                      </span>
                    </div> */}
                            <span className="align-text-bottom">
                              {this.state.appPrice.currency}
                            </span>
                            <span className="text-180">
                              {this.state.appPrice.yearly_subscription_rate}
                            </span>{" "}
                            / year
                          </div>
                        </div>

                        <div className="col-12 col-md-4 text-center">
                          <a
                            onClick={() => this.payNow("YEARLY")}
                            href="#"
                            className="f-n-hover btn btn-success btn-raised px-4 py-25 w-75 text-600"
                          >
                            Pay Now
                          </a>
                          {/* <Link to={`/payment`} target="_blank" className="f-n-hover btn btn-success btn-raised px-4 py-25 w-75 text-600"> Pay Now</Link> */}
                        </div>
                      </div>
                    </div>

                    <div className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-purple btn-h-outline-purple btn-a-outline-purple w-100 shadow-sm">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-8 offset-2">
                          <h4 className="pt-3 text-170 text-600 text-purple-d1 letter-spacing">
                            Lifetime Plan
                          </h4>

                          <div className="text-secondary-d1 text-120">
                            <span className="text-120">
                              Call to our customer service{" "}
                              {this.state.appPrice.life_time_subscription}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.payment_type}
              onHide={() =>
                this.setState({ payment_type: false, selectedPayment: "" })
              }
            >
              <Modal.Header closeButton>
                <h4>Select Payment Method</h4>
              </Modal.Header>
              <Modal.Body>
                <div className="">
                  <label htmlFor="" className=" w-100">
                    <input
                      type="radio"
                      name="payment_method"
                      value="Cashfree"
                      onChange={(e) =>
                        this.setState({ selectedPayment: e.target.value })
                      }
                      disabled
                      className="me-2 h-100"
                    />
                    <span
                      style={{
                        pointerEvents: "none",
                        color: "#aaa",
                        cursor: "default",
                      }}
                    >
                      {" "}
                      Cashfree (Unavailable)
                    </span>
                  </label>

                  <label htmlFor="" className=" w-100">
                    <input
                      type="radio"
                      name="payment_method"
                      value="Instamojo"
                      onChange={(e) =>
                        this.setState({
                          selectedPayment: e.target.value,
                        })
                      }
                      className="me-2"
                    />
                    <span className="ms-1 font-bolder">Instamojo</span>
                  </label>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className=" btn bg-primary border-0 text-white"
                  disabled={!this.state.selectedPayment}
                  onClick={() =>
                    this.handleNextButton(
                      this.state.group_id,
                      this.state.app_name
                    )
                  }
                >
                  Next
                </button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.isCloning}
              onHide={() => this.setState({ isCloning: false })}
              backdrop={this.state.cloneButtonLoader ? "static" : true}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Are you sure want to clone?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    App Name
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter App Name"
                      onChange={(e) => {
                        this.setState({
                          cloneAppName: e.target.value,
                          cloneAppNameValidationError: "",
                        });
                      }}
                      value={this.state.cloneAppName}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.cloneAppNameValidationError !== ""
                        ? this.state.cloneAppNameValidationError
                        : null}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary btn-md"
                  onClick={(event) => {
                    event.preventDefault();
                    var letters = /^[a-zA-Z ]*$/;
                    if (this.state.cloneAppName?.trim() === "") {
                      this.setState({
                        cloneAppNameValidationError: "Please enter app name",
                      });
                    } else {
                      if (letters.test(this.state.cloneAppName.trim())) {
                        this.setState({
                          cloneButtonLoader: true,
                        });
                        this.cloneApp(
                          this.state.cloneAppId,
                          this.state.cloneAppName
                        );
                      } else {
                        this.setState({
                          cloneAppNameValidationError: "Enter only alphabets",
                        });
                      }
                    }
                  }}
                  disabled={this.state.cloneButtonLoader}
                >
                  {this.state.cloneButtonLoader && (
                    <div className="spinner-border spinner-border-sm text-light btnLoader"></div>
                  )}
                  Clone
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default AppDashboard;
