import React, { Component } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  createNewRole,
  getRoleData,
  updateRole,
  deleteRole,
  getPrivilegeData,
  fetchInstallAppData,
} from "../../services/appService";
import Pagination from "react-js-pagination";
import "./Roles.scss";
import Loader from "../dataset/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import CustomModal from "../application/customModal/CustomModal";
export class Roles extends Component {
  constructor() {
    super();
    this.state = {
      getRolesHeader: [
        "Id",
        "Role",
        "privileges",
        "Default Layout",
        "Default Dashboard",
        "Actions",
      ],
      layoutOption: [
        { label: "Admin Layout", value: "adminLayout" },
        { label: "Standard Layout", value: "standardLayout" },
        { label: "Minimal Layout", value: "minimalLayout" },
      ],
      getRolesData: [],
      totalRecordCount: 0,
      dataPerPage: 10,
      getprivilegeName: [],
      currentPage: 1,
      field: {
        rolename: "",
        defaultLayout: "adminLayout",
        defaultDashboard: "",
      },
      editState: false,
      privilege: "",
      selectedOption: [],
      errors: {},
      errorMessage: "",
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      object_id: "",
      loading: false,
      isWrite: false,
      installedAppList: [],
      modalState: false,
      dataTOModal: "",
      isRoleDeleting: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchRolesData();
    this.fetchPrivilegeseData();
    this.getPrivilages();
    this.getAllInstalledApps();
  }

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["Roles"]) {
          this.setState({ isWrite: data["Roles"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true });
    }
  };

  receiveCurrentPageNumber = (langValue) => {
    this.setState({ currentPage: langValue });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  changeValue(event) {
    let fields = this.state.field;

    if (event.target.name === "defaultLayout") {
      fields[event.target.name] = event.target.value;
      fields["defaultDashboard"] = "";
    } else {
      fields[event.target.name] = event.target.value;
    }

    this.setState({
      fields,
    });
  }
  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["rolename"]) {
      formIsValid = false;
      errors["rolename"] = "*Please enter Role Name.";
    }

    if (this.state.selectedOption.length === 0) {
      formIsValid = false;
      errors["privilege"] = "*Please select privilage.";
    }

    if (
      (fields.defaultLayout === "minimalLayout" ||
        fields.defaultLayout === "standardLayout") &&
      fields.defaultDashboard === ""
    ) {
      formIsValid = false;
      errors["defaultDashboard"] = "*Please select default dashboard.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submitRoleDetails(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      let roleData = {
        tenant_id: this.state.tenant_Id,
        is_primary: true,
        fields: {
          role_name: this.state.field.rolename,
          privilege: this.state.selectedOption,
          default_layout: this.state.field.defaultLayout,
          default_dashboard: this.state.field.defaultDashboard,
        },
        object_id: this.state.object_id ? this.state.object_id : "",
      };

      this.setState({
        field: {
          rolename: "",
          defaultLayout: "",
          defaultDashboard: "",
        },
        selectedOption: [],
        editState: false,
        loading: true,
        object_id: "",
      });

      if (this.state.editState) {
        updateRole(roleData)
          .then((response) => {
            if (response.success === true) {
              this.setState({ loading: false });
              toast.success(response.message);
              this.fetchRolesData();
            }
          })
          .catch((err) => {
            if (err.response) {
              this.setState({ loading: false });
              toast.error(err.response.data.message);
            }
          });
      } else {
        this.setState({ loading: true });
        createNewRole(roleData)
          .then((response) => {
            if (response.success === true) {
              this.setState({ loading: false });
              toast.success(response.message);
              this.fetchRolesData();
            }
          })
          .catch((err) => {
            if (err.response) {
              this.setState({ loading: false });
              toast.error(err.response.data.message);
            }
          });
      }
    }
  }

  fetchRolesData() {
    this.setState({ loading: true });
    getRoleData()
      .then((response) => {
        if (response.success === true && this.mounted) {
          this.setState({
            loading: false,
            getRolesData: response.data,
            totalRecordCount: response.total_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorMsg: true });
      });
  }
  fetchPrivilegeseData() {
    getPrivilegeData()
      .then((response) => {
        if (response.success === true && this.mounted) {
          this.setState({
            getprivilegeName: response.data,
          });
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true });
      });
  }

  editUser(data) {
    this.scrollToTop();
    this.setState({
      selectedOption: data.privilege,
      editState: true,
      field: {
        rolename: data.role_name,
        defaultLayout: data.default_layout
          ? data.default_layout
          : "adminLayout",
        defaultDashboard: data.default_dashboard ? data.default_dashboard : "",
      },
      object_id: data._id.$oid,
      errors: {},
    });
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  deleteRole(id) {
    this.setState({
      isRoleDeleting: true,
    });
    let single_delete_id = [id];
    let objDelete = {
      data: {
        data: single_delete_id,
      },
    };
    deleteRole(objDelete)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          this.fetchRolesData();
        }
      })
      .finally(() => {
        this.setState({ modalState: false, isRoleDeleting: false });
      });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  getAllInstalledApps = () => {
    fetchInstallAppData()
      .then((response) => {
        if (response.success === true) {
          this.setState({ installedAppList: response.data });
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  render() {
    const { currentPage, dataPerPage } = this.state;
    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const currentData = this.state.getRolesData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    let privilegeData = [];
    this.state.getprivilegeName.map((data) => {
      let privilegeDataValue = {
        value: data._id.$oid,
        label: data.privilegeName,
      };
      privilegeData.push(privilegeDataValue);
    });

    const setHeaderData = this.state.isWrite
      ? this.state.getRolesHeader.map((header, index) => (
          <th className="text-nowrap" key={index}>
            {header}
          </th>
        ))
      : this.state.getRolesHeader
          .filter((header) => header !== "Actions")
          .map((header, index) => (
            <th className="text-nowrap" key={index}>
              {header}
            </th>
          ));

    const setRolesData = currentData.map((data, index) => (
      <tr key={index}>
        <td>{index + 1 + indexOfFirstRecord}</td>
        <td>{data.role_name}</td>
        <td>
          {data.privilege.length === 0
            ? "--"
            : data.privilege.map((privilegeList, i) => {
                if (i + 1 === data.privilege.length) {
                  return privilegeList.label;
                } else {
                  return privilegeList.label + ", ";
                }
              })}
        </td>
        <td>
          {!data.default_layout
            ? "Admin Layout"
            : this.state.layoutOption
                .filter((layout) => layout.value === data.default_layout)
                .map((layout) => {
                  return layout.label;
                })}
        </td>
        <td>
          {!data.default_dashboard
            ? "--"
            : this.state.installedAppList
                .filter((app) => app._id.$oid === data.default_dashboard)
                .map((app, i) => {
                  return app.app_name;
                })}
        </td>

        {this.state.isWrite ? (
          <td className="text-nowrap">
            <div className="d-flex text-center m-0">
              <div className="action-icons">
                <FontAwesomeIcon
                  icon={"fa fa-pen-to-square"}
                  role="button"
                  size="lg"
                  className="me-2"
                  onClick={(event) => this.editUser(data)}
                />
                <FontAwesomeIcon
                  onClick={(event) =>
                    this.setState({
                      modalState: true,
                      dataTOModal: data?._id?.$oid,
                    })
                  }
                  icon="fa fa-trash"
                  role="button"
                  size="lg"
                  className="me-2"
                />
              </div>
            </div>
          </td>
        ) : null}
      </tr>
    ));
    return (
      <div>
        <div className="d-help py-2">
          <h5> {this.state?.isWrite ? "Create Role " : "Role"}</h5>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0"
            onClick={(e) => {
              e.preventDefault();
              this.props?.history?.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
        {this.state.isWrite ? (
          <div className="border p-3 bg-white">
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Role Name
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Role Name"
                  name="rolename"
                  value={this.state.field.rolename}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.rolename}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Privileges
              </label>
              <div className="col-md-9 col-sm-9 ">
                <Select
                  options={privilegeData}
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  isMulti
                  isObject={false}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.privilege}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Default Layout
              </label>
              <div className="col-md-9 col-sm-9 ">
                <Form.Select
                  type="text"
                  className="form-control"
                  name="defaultLayout"
                  value={this.state.field.defaultLayout}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                >
                  {this.state.layoutOption.map((layout, index) => {
                    return (
                      <option key={index} value={layout.value}>
                        {layout.label}
                      </option>
                    );
                  })}
                </Form.Select>
                <div className="errorMsg text-danger">
                  {this.state.errors.defaultLayout}
                </div>
              </div>
            </div>
            {this.state.field.defaultLayout === "standardLayout" ||
            this.state.field.defaultLayout === "minimalLayout" ? (
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Default Dashboard
                </label>
                <div className="col-md-9 col-sm-9 ">
                  <Form.Select
                    type="text"
                    className="form-control"
                    name="defaultDashboard"
                    value={this.state.field.defaultDashboard}
                    onChange={(e) => {
                      this.changeValue(e);
                    }}
                  >
                    <option value={""}>Select...</option>
                    {this.state.installedAppList.map((app, index) => (
                      <option key={index} value={app._id.$oid}>
                        {app.app_name}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="errorMsg text-danger">
                    {this.state.errors.defaultDashboard}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="d-flex justify-content-end">
              {this.state.editState ? (
                <button
                  onClick={() => {
                    this.setState({
                      field: {
                        rolename: "",
                        defaultLayout: "adminLayout",
                        defaultDashboard: "",
                      },
                      selectedOption: [],
                      editState: false,
                      object_id: "",
                    });
                  }}
                  className="btn btn-danger btn-md m-0 me-2"
                >
                  <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                  <span className="ms-1">Cancel</span>
                </button>
              ) : null}
              <button
                onClick={(event) => this.submitRoleDetails(event)}
                type="submit"
                className="custom-btn m-0"
              >&nbsp;&nbsp;
                {this.state.editState ? (
                  <>
                    <FontAwesomeIcon icon="fa fa-retweet" />
                    <span className="ms-1">&nbsp;&nbsp;Update Role&nbsp;&nbsp;</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon="fa-regular fa-circle-check" />{" "}
                    <span className="ms-1">&nbsp;&nbsp;Save Role&nbsp;&nbsp;</span>
                  </>
                )}
              </button>
            </div>
          </div>
        ) : null}

        <div className="d-help pb-2 pt-3">
          <h5>Saved Roles</h5>
        </div>
        <div>
          <div className="table_ui">
            {this.state.loading ? (
              <Loader />
            ) : (
              <div className="px-0 set_height saved-role">
                <div className="table-responsive">
                  <table
                    
                    className="table  table-bordered mb-0"
                  >
                    <thead className="head-row">
                      <tr>{setHeaderData}</tr>
                    </thead>
                    <tbody>{setRolesData}</tbody>
                  </table>
                </div>
                {this.state.totalRecordCount === 0 ? (
                  <p className="no_table_records">No matching records found</p>
                ) : null}
                {this.state.totalRecordCount > 10 ? (
                  <div className="dynamic_pagination my-2">
                    <Pagination
                      pageRangeDisplayed={3}
                      activePage={this.state.currentPage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.getRolesData.length}
                      onChange={this.receiveCurrentPageNumber}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>

        <CustomModal
          modalState={this.state.modalState}
          modalTitle={"Are you sure?"}
          modalDiscription={"Do you want to delete these record?"}
          hideModal={() => this.setState({ modalState: false })}
          onDeleteClick={() => this.deleteRole(this.state.dataTOModal)}
          isLoading={this.state?.isRoleDeleting}
        />
      </div>
    );
  }
}

export default Roles;
