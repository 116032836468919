import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  fetchInstallAppData,
  fetchSearchAppsFlows,
  getFlowsByAppId,
  getLogicsByAppId,
  getRoleData,
  upsertSingleRecord,
} from "../../services/appService";
import { toast } from "react-toastify";
import { RoutesContext } from "../../RoutesContext";
import { v4 as uuidv4 } from "uuid";
import Loader from "../dataset/Loader/Loader";

const Search = (props) => {
  const { routesData, setRoutesData } = useContext(RoutesContext);
  const [fieldsData, setFieldsData] = useState({
    object_id: "",
    searchConfiguration: [],
  });
  const [namesData, setNamesData] = useState({ label: "", value: "" });
  const [flowNames, setFlowNames] = useState([{ label: "", value: "" }]);
  const [logicNames, setLogicNames] = useState([{ label: "", value: "" }]);
  const [rolesData, setRolesData] = useState({ label: "", value: "" });
  const [loading, setLoading] = useState(true);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = () => {
    fetchSearchAppsFlows()
      .then((response) => {
        setLoading(true);
        let [data] = response?.data || [];
        if (data) {
          setFieldsData({
            object_id: data?._id?.$oid,
            searchConfiguration: data?.searchConfiguration || [],
          });
          data?.searchConfiguration?.map((item, index) => {
            if (item.appName.value) {
              handleLatestFlowNames(item.appName.value, index);
              handleLatestLogicNames(item.appName.value, index);
            }
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    fetchInstallAppData().then((response) => {
      const newNamesData = response?.data?.map((names) => ({
        label: names.app_name,
        value: names._id.$oid,
      }));
      setNamesData(() => [...newNamesData]);
    });
    getRoleData().then((response) => {
      let fetchRolesData = response?.data?.map((item) => ({
        label: item.role_name,
        value: item._id.$oid,
      }));
      setRolesData(() => [...fetchRolesData]);
    });
  };

  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    fieldsData?.searchConfiguration?.forEach((data, index) => {
      const configErrors = {};

      if (!data?.searchName) {
        configErrors.searchName = "Search Type is mandatory.";
        formIsValid = false;
      }

      if (!data?.searchText) {
        configErrors.searchText = "Search Text is mandatory.";
        formIsValid = false;
      }

      if (!data?.appName?.value) {
        configErrors.appName = "App Name is mandatory.";
        formIsValid = false;
      }

      if (!data?.appFlow?.value) {
        configErrors.appFlow = "App Flow is mandatory.";
        formIsValid = false;
      }

      if (!data?.appLogic?.value) {
        configErrors.appLogic = "App Logic is mandatory.";
        formIsValid = false;
      }

      if (!data?.logicVariableName) {
        configErrors.logicVariableName = "Variable Name is mandatory.";
        formIsValid = false;
      }

      errors[index] = configErrors;
    });

    setFieldErrors(errors);
    return formIsValid;
  };

  const handleInputData = (event, index) => {
    const { name, value } = event.target;
    let updatedData = [...fieldsData?.searchConfiguration];
    updatedData[index][name] = value;
    setFieldsData({
      object_id: fieldsData.object_id,
      searchConfiguration: updatedData,
    });
  };
  const addNewConfiguration = () => {
    if (validateForm()) {
      setFieldsData({
        object_id: fieldsData.object_id,
        searchConfiguration: [...fieldsData?.searchConfiguration, {}],
      });
    }
    setFlowNames([...flowNames, { label: "", value: "" }]);
    setLogicNames([...logicNames, { label: "", value: "" }]);
  };
  const removeConfiguration = (indexOfRow) => {
    const filteredConfigData = fieldsData.searchConfiguration.filter(
      (_, index) => index !== indexOfRow
    );
    setFieldsData({
      object_id: fieldsData.object_id,
      searchConfiguration: filteredConfigData,
    });
    setFlowNames((prevFlowNames) =>
      prevFlowNames.filter((_, index) => index !== indexOfRow)
    );
    setLogicNames((prevLogicNames) =>
      prevLogicNames.filter((_, index) => index !== indexOfRow)
    );
  };
  const submitConfigureData = () => {
    if (validateForm()) {
      setSubmitLoader(true);
      let obj = {
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        dataset_name: "sys_search_configuration",
        user_id: JSON.stringify(JSON.parse(localStorage.getItem("userid"))),

        fields: {
          searchConfiguration: fieldsData.searchConfiguration,
        },
      };
      if (fieldsData.object_id) {
        obj.object_id = fieldsData.object_id;
      }

      upsertSingleRecord(obj)
        .then((response) => {
          toast.success("Data Added Successfully");
          setFieldsData({
            object_id: response?.object_id,
            searchConfiguration: response?.data?.searchConfiguration || [],
          });
        })
        .finally(() => setSubmitLoader(false));
      setRoutesData((prevRoutesData) => ({
        ...prevRoutesData,
        searchId: uuidv4(),
      }));
    }
  };

  const handleSelectValues = (option = "", action = "", index) => {
    let updatedData = [...fieldsData?.searchConfiguration];
    updatedData[index][action?.name] = option;
    setFieldsData({
      object_id: fieldsData?.object_id,
      searchConfiguration: updatedData,
    });
  };

  const handleLatestFlowNames = (dataId, index) => {
    {
      dataId &&
        getFlowsByAppId(dataId).then((response) => {
          const newFlowNames = response?.data?.map((item) => ({
            label: item.function_name,
            value: item._id.$oid,
          }));
          setFlowNames((prevFlowNames) => {
            const updatedFlowNames = [...prevFlowNames];
            updatedFlowNames[index] = newFlowNames;
            return updatedFlowNames;
          });
        });
    }
  };

  const handleLatestLogicNames = (dataId, index) => {
    {
      dataId &&
        getLogicsByAppId(dataId).then((response) => {
          const newLogicNames = response?.data?.map((item) => ({
            label: item.function_name,
            value: item._id.$oid,
          }));
          setLogicNames((prevLogicNames) => {
            const updatedLogicNames = [...prevLogicNames];
            updatedLogicNames[index] = newLogicNames;
            return updatedLogicNames;
          });
        });
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="d-help justify-content-end py-2">
        <button
          type="button"
          className="btn btn-secondary btn-sm m-0"
          onClick={(e) => {
            e.preventDefault();
            props?.history?.goBack();
          }}
        >
          <FontAwesomeIcon icon="fa fa-arrow-left" />
          <span className="ms-1">Back</span>
        </button>
      </div>
      <div className="rounded p-2 mb-2 border bg-white">
        <div className="d-help p-2 border-bottom flex-wrap">
          <h5 className="py-1">
            Search configuration{" "}
            {fieldsData?.searchConfiguration?.length > 0 &&
              `(${fieldsData?.searchConfiguration?.length})`}
          </h5>
          {fieldsData?.searchConfiguration?.length > 0 && (
            <FontAwesomeIcon
              icon={"fa fa-plus-circle"}
              title="Add new configuration"
              className="pointer ms-0 me-1 text-success"
              aria-hidden="true"
              onClick={addNewConfiguration}
              size="lg"
            />
          )}
        </div>
        {fieldsData?.searchConfiguration?.length > 0 ? (
          fieldsData?.searchConfiguration?.map((data, index) => {
            return (
              <div className="d-flex justify-content-between mt-2 border p-2 rounded actionButtonContainer">
                <div className="flex-grow-1 ">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">Search Type</label>
                      <div>
                        <input
                          type="text"
                          className="form-control form-control-sm p-2"
                          placeholder="Enter Search By Type Name"
                          name="searchName"
                          onChange={(e) => {
                            handleInputData(e, index);
                          }}
                          value={data.searchName}
                        />
                        {fieldErrors[index]?.searchName && (
                          <div className="text-danger">
                            {fieldErrors[index].searchName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">Search Text</label>
                      <div>
                        <input
                          type="text"
                          className="form-control form-control-sm p-2"
                          placeholder="Enter Text"
                          name="searchText"
                          onChange={(e) => {
                            handleInputData(e, index);
                          }}
                          value={data?.searchText}
                        />
                        {fieldErrors[index]?.searchText && (
                          <div className="text-danger">
                            {fieldErrors[index].searchText}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">App Name</label>
                      <div>
                        <Select
                          name="appName"
                          options={namesData}
                          value={data?.appName}
                          onChange={(option, action) => {
                            handleSelectValues(option, action, index);
                            handleLatestFlowNames(option.value, index);
                            handleLatestLogicNames(option.value, index);
                          }}
                          isClearable
                          menuPosition="fixed"
                        />
                        {fieldErrors[index]?.appName && (
                          <div className="text-danger">
                            {fieldErrors[index].appName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">App Flow</label>
                      <div>
                        <Select
                          name="appFlow"
                          options={flowNames[index]}
                          value={data?.appFlow}
                          onChange={(option, action) =>
                            handleSelectValues(option, action, index)
                          }
                          isClearable
                          menuPosition="fixed"
                        />
                        {fieldErrors[index]?.appFlow && (
                          <div className="text-danger">
                            {fieldErrors[index].appFlow}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">App Logic</label>
                      <div>
                        <Select
                          name="appLogic"
                          options={logicNames[index]}
                          value={data?.appLogic}
                          onChange={(option, action) =>
                            handleSelectValues(option, action, index)
                          }
                          isClearable
                          menuPosition="fixed"
                        />
                        {fieldErrors[index]?.appLogic && (
                          <div className="text-danger">
                            {fieldErrors[index]?.appLogic}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">
                        Store Search value
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control form-control-sm p-2"
                          placeholder="Enter Variable Name"
                          name="logicVariableName"
                          onChange={(e) => {
                            handleInputData(e, index);
                          }}
                          value={data.logicVariableName}
                        />
                        {fieldErrors[index]?.logicVariableName && (
                          <div className="text-danger">
                            {fieldErrors[index]?.logicVariableName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">Select Role</label>
                      <div>
                        <Select
                          name="roleName"
                          options={rolesData}
                          value={data?.roleName}
                          onChange={(option, action) => {
                            handleSelectValues(option, action, index);
                          }}
                          isClearable
                          isMulti
                          menuPosition="fixed"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ms-2 d-flex justify-content-center flex-wrap align-items-center flex-column">
                  <FontAwesomeIcon
                    icon={"fa fa-minus-circle"}
                    title="Remove search"
                    className="pointer text-danger add_remove_size mx-2"
                    aria-hidden="true"
                    onClick={() => removeConfiguration(index)}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="set_height d-flex align-items-center justify-content-center bg-white rounded m-1 border">
            <div className="text-center">
              <div className="mb-2">No search have been configured yet.</div>
              <FontAwesomeIcon
                icon={"fa fa-plus-circle"}
                title="Add new search"
                className="pointer ms-0 me-1 text-success"
                aria-hidden="true"
                onClick={addNewConfiguration}
                size="lg"
              />
            </div>
          </div>
        )}
      </div>
      {fieldsData?.searchConfiguration?.length > 0 && (
        <div className="d-flex justify-content-end py-2">
          <button
            type="button"
            className="custom-btn tensub me-0"
            onClick={() => submitConfigureData()}
          >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
            {submitLoader && (
              <div className="spinner-border text-light btnLoader" />
            )}
            &nbsp;&nbsp;Submit&nbsp;&nbsp;
          </button>
        </div>
      )}
    </div>
  );
};

export default Search;
